import * as React from 'react';

import BrandedStar from './BrandedStar';

import { styled } from '@soluto-home-web/web-theme';

const StarsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  margin-bottom: 1vh;
  margin-top: 1vh;
`;

const StarContainer = styled.span`
  margin: 0;
`;

const StarDescription = styled.span`
  font-size: 16px;
  margin-bottom: 1vh;
  font-style: italic;
`;

const Container = styled.div`
  height: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type Props = { rating: number; onChange: (rating: number) => void };
type State = { hover: number; starDescription?: string };

const starDescriptions = {
  0: '       ',
  1: 'Poorly',
  2: 'Not so well',
  3: 'So-so',
  4: 'Good',
  5: 'Awesome!',
};

class StarRating extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      hover: 0,
    };
  }

  isSelected(starRating: number) {
    return this.props.rating >= starRating || this.state.hover >= starRating;
  }

  isHovered(starRating: number) {
    return this.state.hover >= starRating;
  }

  keyPressed(event: any, rating: number) {
    if (event.key === 'Enter') {
      event.stopPropagation();
      this.setRating(rating);
    }
  }

  setRating(rating) {
    this.setState({
      starDescription: starDescriptions[rating],
      hover: 0,
    });
    this.props.onChange(rating);
  }

  setHover(hover) {
    if (this.props.rating > 0) {
      return;
    }

    return this.setState({ hover, starDescription: starDescriptions[hover] });
  }

  render() {
    return (
      <Container>
        <StarsContainer>
          {[1, 2, 3, 4, 5].map((x) => (
            <StarContainer
              tabIndex={0}
              role="radio"
              aria-label={starDescriptions[x]}
              aria-checked={this.isSelected(x)}
              key={x}
              onClick={() => this.setRating(x)}
              onKeyUp={() => this.keyPressed(event, x)}
              onMouseEnter={() => this.setHover(x)}
              onMouseLeave={() => this.setHover(0)}
            >
              <BrandedStar
                selected={this.isSelected(x)}
                hovered={this.isHovered(x)}
              />
            </StarContainer>
          ))}
        </StarsContainer>
        {this.state.starDescription && (
          <StarDescription>{this.state.starDescription}</StarDescription>
        )}
      </Container>
    );
  }
}

export default StarRating;
