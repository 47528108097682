const MAX_HEIGHT_PERCENTAGE = 40;
const MAX_WIDTH_PERCENTAGE = 60;

type Dimensions = {
  width: number;
  height: number;
};

export default (
  actualWidth: number,
  actualHeight: number,
  windowWidth: number,
  windowHeight: number
): Dimensions => {
  let width = actualWidth,
    height = actualHeight;
  const maxWidth = (windowWidth * MAX_WIDTH_PERCENTAGE) / 100;
  const maxHeight = (windowHeight * MAX_HEIGHT_PERCENTAGE) / 100;

  if (width > maxWidth) {
    const ratio = maxWidth / width;
    width *= ratio;
    height *= ratio;
  }

  if (height > maxHeight) {
    const ratio = maxHeight / height;
    width *= ratio;
    height *= ratio;
  }

  return { width, height };
};
