import React, { SVGProps } from 'react';

const Battery = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.43872 10.8916L5.43872 8.88911L1.64111 8.88911L1.64111 10.8916L5.43872 10.8916Z"
      stroke="#009DDA"
      strokeWidth="0.498862"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.15872 2.4723L0.375 2.4723L0.375 12.2869L6.91532 12.2869L6.91532 2.4723L5.1316 2.4723L5.1316 1.00012L2.15872 1.00012L2.15872 2.4723V2.4723Z"
      stroke="black"
      strokeWidth="0.498862"
      strokeLinejoin="round"
    />
  </svg>
);

export default Battery;
