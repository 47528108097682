import { IValueRedactor } from './IValueRedactor';

const autoTokenMatcher = /Bearer .*?\..*?/;
const cryptoRandomTokenMatcher = /\S{25,}/;
const sensitiveKeys = ['code'];

class TokenRedactor implements IValueRedactor {
  shuoldUseRedactor(key: string, value: string): boolean {
    return (
      value != null &&
      (sensitiveKeys.indexOf(key) > -1 || typeof value === 'string')
    );
  }

  // Unsafe integers will pass the matcher even if they are codes (length > 24)
  isSafeInteger(value: any) {
    return typeof value !== 'number' || Number.isSafeInteger(value);
  }

  redact(key: string, value: any): any {
    if (
      sensitiveKeys.indexOf(key) > -1 &&
      (!this.isSafeInteger(value) ||
        value.toString().match(cryptoRandomTokenMatcher))
    ) {
      return '##Token redacted##';
    }

    return typeof value === 'string'
      ? value.replace(autoTokenMatcher, '##Bearer token redacted##')
      : value;
  }
}

export default TokenRedactor;
