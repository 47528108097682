import Report from 'bugsnag-js/types/report';
import values from 'ramda.values';
import redactors from '../redactors';
import { IValueRedactor } from '../redactors/IValueRedactor';
import ReportRedactor from './ReportRedactor';

export default (report: Report): void => {
  values(redactors).forEach((redactor: IValueRedactor) => {
    ReportRedactor(report, redactor);
  });
};
