declare global {
  interface Window {
    FS: any;
  }
}
const fullstory = window && window.FS;

const dispose = () => {
  fullstory && fullstory.identify(false);
};

const identify = (id: string, userVars: Object) => {
  fullstory && fullstory.identify && fullstory.identify(id, userVars);
};

const setUserVars = (userVars: Object) => {
  fullstory && fullstory.identify && fullstory.setUserVars(userVars);
};

const restart = () => {
  fullstory && fullstory.restart && fullstory.restart();
};

const getCurrentSessionURL = (atTime: boolean) =>
  fullstory &&
  fullstory.getCurrentSessionURL &&
  fullstory.getCurrentSessionURL(atTime);

const initalize = (id: string, userVars: Object) => {
  restart();
  identify(id, userVars);
};

export default {
  initalize,
  setUserVars,
  getCurrentSessionURL: () => getCurrentSessionURL(false),
  getCurrentSessionAtTime: () => getCurrentSessionURL(true),
  dispose,
};
