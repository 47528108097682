import { BreakPoints } from '@soluto-home-web/web-theme';
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

type Props = {
  containerStyle?: any;
};

const waveAnimation = keyframes`
   0% { transform: translateY(0); }
   25% { transform: translateY(-7px); }
   50% { transform: translateY(0); }
`;

const TypingContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: flex-start;
  margin-top: 0.25em;
  margin-left: 52px;
  margin-bottom: 20px;
`;

const DotsContainer = styled.div`
  border-radius: 4px;
  padding: 0.5em 1em;
  background-color: #f5f5f5;
`;

const Dot = styled.span`
  animation: ${waveAnimation} 900ms ease-out infinite;
  display: inline-block;
  color: #757575;
  font-size: 24;
  font-weight: 500;
  @media ${BreakPoints.tablet} {
    font-size: 30;
  }
`;

const TypingIndicator: React.SFC<Props> = ({ containerStyle }) => (
  <div>
    <TypingContainer style={containerStyle || {}} tabIndex={0} role="text">
      <DotsContainer aria-label="expert is typing">
        <Dot>.</Dot>
        <Dot style={{ animationDelay: '100ms' }}>.</Dot>
        <Dot style={{ animationDelay: '200ms' }}>.</Dot>
      </DotsContainer>
    </TypingContainer>
  </div>
);

export default TypingIndicator;
