import { logger, monitorClient } from '@soluto-home-web/platform-logger';
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import {
  performanceTimeline,
  userAgentParser,
} from '@soluto-home-web/platform-utils';
import { withRouter } from '@soluto-home-web/platform-utils';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import { lifecycle } from 'recompose';
import { firstPaintMarkObservable } from './firstPaintMarkObservable';

type OutterProps = {
  pageName: string;
};

type InnerProps = RouteComponentProps<any> & { reportPagePerformance: boolean };

const writePageMeasurementMetrics = async (
  pathname: string,
  pageName: string
): Promise<void> => {
  try {
    const isManualMeasurementStarted = performanceTimeline.isMeasurementStarted(
      pathname
    );

    if (isManualMeasurementStarted) {
      await writeManualNavigationMetrics(pageName, pathname);
    } else {
      writeFirstPaintMetrics(pageName, pathname);
    }
  } catch (ex) {
    logger.warn(
      'withPageMeasurement: exception occured while tring to measure page performance',
      ex
    );
  }
};

const writeMetrics = async (
  pageName: string,
  metricName: string,
  duration: number
): Promise<void> => {
  try {
    const osName: string = userAgentParser.getOs().replace(/ /g, '');
    const browserName: string = userAgentParser
      .getBrowserName()
      .replace(/ /g, '');

    await monitorClient.addTime(
      `${pageName}_${osName}_${browserName}_${metricName}`,
      duration
    );
  } catch (ex) {
    logger.warn(
      'withPageMeasurement: error occured while trying to send performance metrics'
    );
  }
};

const writeFirstPaintMetrics = (pageName: string, pathname: string): void => {
  firstPaintMarkObservable().subscribe(
    async (firstPaintMeasurement: PerformanceEntry) => {
      await writeMetrics(
        pageName,
        'first-paint',
        firstPaintMeasurement.startTime
      );
    },
    () => {}
  );
};

const writeManualNavigationMetrics = async (
  pageName: string,
  pathname: string
): Promise<void> => {
  const currentPageMeasurement: PerformanceEntry | null = performanceTimeline.endMeasurement(
    pathname,
    pageName
  );

  if (currentPageMeasurement === null) {
    logger.warn(
      'withPageMeasurement: could not get the current page measurement',
      { pageName: pageName, pathname: pathname }
    );

    return;
  }

  await writeMetrics(
    pageName,
    'time-to-component-mount',
    currentPageMeasurement.duration
  );
};

export default compose<InnerProps, OutterProps>(
  withRouter,
  withTweekKeys('soluto_home_web/metrics/report_page_performance', {
    propName: 'reportPagePerformance',
  }),
  lifecycle<OutterProps & InnerProps, {}>({
    async componentDidMount() {
      if (!this.props.reportPagePerformance || !window.performance) {
        logger.info('did not write page measurements', {
          isEnabled: this.props.reportPagePerformance,
          isPerformanceObjectExists: !!window.performance,
        });

        return;
      }

      await writePageMeasurementMetrics(
        this.props.location.pathname,
        this.props.pageName
      );
    },
  })
);
