import * as React from 'react';
import styled from 'styled-components';

const ExpertName = styled.span`
  font-size: 0.7em !important;
  line-height: 0.8em !important;
  color: #757575;
  margin-bottom: 2px;
`;
//the min-width is for IE11 compatibility
const Container = styled.span`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  min-width: 10px;
`;

const withExpertNameHeader = (expertName) => (Component) => (props) => {
  return (
    <Container data-test-id={'ExpertNameContainer'}>
      <ExpertName>{expertName}</ExpertName>
      <Component {...props} />
    </Container>
  );
};

export default withExpertNameHeader;
