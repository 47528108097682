import { BeforeSend } from 'bugsnag-js/types/common';
import createPlugin from 'bugsnag-react';
import * as React from 'react';
import BugsnagClient from './bugsnag-client';

type ErrorBoundaryComp = React.SFC<{
  beforeSend?: BeforeSend;
  FallbackComponent: () => JSX.Element;
}>;

const EmptyErrorBoundary: ErrorBoundaryComp = ((Comp: React.ComponentType) => (
  <Comp />
)) as any;
const ErrorBoundaryCreator: () => ErrorBoundaryComp = () =>
  (BugsnagClient.getInstance() &&
    BugsnagClient.getInstance().use(createPlugin(React))) ||
  EmptyErrorBoundary;

export default ErrorBoundaryCreator;
