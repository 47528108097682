import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { TimelineItem } from '@soluto-home-web/timeline-core';
import { withAnalytics } from 'react-shisell';
import { compose, withHandlers, withProps } from 'recompose';
import { AnalyticsDispatcher } from 'shisell';
import withPersistency from './behaviors/withPersistency';
import {
  triggerDynamicFlow,
  triggerSystemMessageFlow,
} from './client/toTriggerFlow';
import TwoStepResolutionTimelineItem from './components/TwoStepResolutionTimelineItem';
import { TwoStepResolutionContent } from './TwoStepResolutionContentType';

type TwoStepResolutionOutter = {
  surveyKey: string;
  triggerVoice: boolean;
  expertApiUrl: string;
  analytics: AnalyticsDispatcher;
};

export default compose(
  withAnalytics,
  withTweekKeys('support/session/feedback/config/expert_branding_name', {
    propName: 'expertBrandingName',
  }),
  withProps<TwoStepResolutionOutter, TimelineItem<TwoStepResolutionContent>>(
    ({
      analytics,
      content: { sessionId, partner, surveyKey, triggerVoice, environment },
    }) => ({
      surveyKey: surveyKey || 'default',
      triggerVoice: triggerVoice || false,
      expertApiUrl: `https://expert-api.${
        environment === 'dev'
          ? 'soluto.npr.aws.asurion.net'
          : 'anywhereexpert.us'
      }`,
      analytics: analytics.dispatcher
        .createScoped('Two_Step_Resolution')
        .withExtras({ sessionId, flowId: 'two_step_resolution' }),
    })
  ),
  withHandlers({
    updateCustomerResolution: ({
      content: { sessionId },
      expertApiUrl,
    }) => async (customerResolutionSelection) => {
      const response = await fetch(
        `${expertApiUrl}/timeline-session/customer-resolution/${sessionId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            customerResolutionSelection,
          }),
        }
      );

      if (!response.ok) {
        throw response;
      }
    },
    triggerStarSurvey: ({
      timelineId,
      content: {
        sessionId,
        expertId,
        partner,
        expertName,
        environment,
        eventId,
        surveyKey,
        triggerVoice,
      },
      messageId,
    }) => () => {
      const content = {
        sessionId,
        expertId,
        deviceId: timelineId,
        partner,
        eventId,
        expertName,
        environment,
        surveyKey,
        triggerVoice,
        chatResolvedAt: Date.now(),
        showExpertLeftMessage: false,
      };

      return triggerDynamicFlow(messageId, content, timelineId);
    },
    reenterQueue: ({
      content: { sessionId, partner },
      timelineId,
      expertApiUrl,
      messageId,
    }) => async () => {
      const content = {
        message:
          'This customer had an unresolved issue and needs help finding a solution.',
        subMessage:
          'Tip: Read the messages above to see what steps they’ve already tried!',
        sessionId,
      };

      triggerSystemMessageFlow(messageId, content, timelineId);
      const response = await fetch(
        `${expertApiUrl}/timeline-session/reenter-queue/${sessionId}`,
        {
          method: 'POST',
          body: JSON.stringify({
            customerId: timelineId,
            partner,
          }),
        }
      );

      if (!response.ok) {
        throw response;
      }
    },
  }),
  withPersistency
)(TwoStepResolutionTimelineItem);
