import {
  itemTypes,
  TimelineItem,
  withDeleteImageMessages,
  withDeleteTextMessages,
  withDeleteVideoMessages,
  withResendMessage,
} from '@soluto-home-web/timeline-core';
import * as React from 'react';
import { branch, compose } from 'recompose';
import styled, { withTheme } from 'styled-components';
import ExpertAvatar from '../components/ExpertAvatar';
import ItemContainer from '../components/ItemContainer';
import ResendButton from '../components/Resend/ResendButton';
import TimestampOrSendingIndicator from '../components/TimestampOrSendingIndicator';
import TrashCan from '../components/TrashCan/TrashCan';
import withExpertNameHeader from './withExpertNameHeader';
import withItemProps from './withItemProps';

const PlaceHolder = styled.div`
  margin-right: 13.5px;
  height: 40px;
  width: 40px;
  min-width: 40px;
`;

const TrashCanPlaceHolder = styled.div`
  width: 50px;
  height: 22px;
`;

const HoverContainer = styled.div`
  display: flex;
  flex-direction: ${(props: { fromExpert: string }) =>
    props.fromExpert ? 'row' : 'row-reverse'};
  data-test-id: 'HoverItem';
  max-width: 95%;
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
`;

const getExpertFirstName = (
  senderName: string,
  senderType: string
): string | undefined => {
  if (senderType === 'Team') {
    return undefined;
  }

  return typeof senderName === 'string' && senderName.length > 0
    ? senderName
    : undefined;
};

const WithTimelineItem = (Component) => (props) => {
  const {
    fromExpert,
    isGrouped,
    deleteMessage,
    timelineId,
    contentId,
    contentType,
    inFlight,
    timestamp,
    handleMouseHover,
    handleMouseOut,
    isHover,
    deleteable,
    isCurrentlyInDelete,
    isFailedSending,
    resendMessage,
    keyPress,
    makeUnfocusable,
  } = props;

  let leftComponent, expertName;
  if (fromExpert) {
    if (isGrouped) {
      leftComponent = <PlaceHolder />;
    } else {
      expertName = getExpertFirstName(props.senderName, props.senderType);
      leftComponent = <ExpertAvatar {...props} expertName={expertName} />;
    }
  }

  let component = expertName ? (
    withExpertNameHeader(expertName)(Component)({
      messageSelected: isHover,
      ...props,
    })
  ) : (
    <Component {...props} messageSelected={isHover} />
  );

  const resendComponent =
    isFailedSending && !fromExpert ? (
      <ResendButton contentId={contentId} resendMessage={resendMessage} />
    ) : null;

  const trashCanComponent =
    deleteable && !fromExpert && !isCurrentlyInDelete ? (
      <TrashCan
        isHover={isHover}
        deleteMessage={deleteMessage}
        timelineId={timelineId}
        contentId={contentId}
        contentType={contentType}
      />
    ) : (
      <TrashCanPlaceHolder />
    );

  return (
    <ItemContainer fromExpert={fromExpert}>
      <HoverContainer
        tabIndex={makeUnfocusable ? undefined : 0}
        role={isHover ? '' : 'text'}
        onClick={isHover ? handleMouseOut : handleMouseHover}
        onKeyUp={keyPress}
        fromExpert={fromExpert}
        onMouseEnter={handleMouseHover}
        onMouseLeave={handleMouseOut}
      >
        {leftComponent}
        {component}
        <SideContainer>
          <div />
          {resendComponent || trashCanComponent}
          <TimestampOrSendingIndicator
            isCurrentlyInFlight={inFlight}
            timestamp={timestamp}
          />
        </SideContainer>
      </HoverContainer>
    </ItemContainer>
  );
};

export default compose<{}, TimelineItem<string>>(
  withTheme,
  withItemProps,
  branch(
    ({ contentType }) => contentType === itemTypes.TextMessage,
    compose(
      withDeleteTextMessages,
      withResendMessage
    ),
    branch(
      ({ contentType }) => contentType === itemTypes.ImageItem,
      withDeleteImageMessages,
      withDeleteVideoMessages
    )
  ),
  WithTimelineItem
);
