import axios from 'axios';
import { compose, withProps } from 'recompose';
import { getMessagingApiToken } from '../messaging-api/getMessagingApiToken';
import { buildMessage } from '../messaging-api/with-send-message';

import config from './config';

export type MessageParams = {
  message: string;
  timelineId: string;
  messagingPartner: string;
  requiredExpertise?: string;
  requiredLanguageExpertise?: string;
  customExpertise?: string;
};

export type RestClient = {
  saw: (roomId: string, messageId: string, contentType: string) => void;
  message: (params: MessageParams) => void;
};

export const withMessagingRestClient = compose(
  withProps(() => ({
    messagingRestClient: getMessagingApiRestClient(),
  }))
);

const getMessagingApiClient = async () => {
  const token = await getMessagingApiToken();

  return axios.create({
    baseURL: config.baseUrl,
    timeout: 5000,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'x-api-key': config.apiKey,
    },
  });
};
const getMessagingApiRestClient = (): RestClient => {
  return {
    saw: async (roomId: string, messageId: string, contentType: string) => {
      if (messageId && roomId && contentType) {
        const messagingApiClient = await getMessagingApiClient();
        await messagingApiClient.post(`/api/v1/message/saw`, {
          messageId,
          contentType,
          roomId,
          seenAt: Date.now(),
        });
      }
    },
    message: async ({
      message,
      timelineId,
      messagingPartner,
      requiredExpertise,
      requiredLanguageExpertise,
      customExpertise,
    }) => {
      if (message && timelineId && messagingPartner) {
        const outgoingMessage = buildMessage({
          message,
          timelineId,
          messagingPartner,
          requiredExpertise,
          requiredLanguageExpertise,
          customExpertise,
        });

        const messagingApiClient = await getMessagingApiClient();
        await messagingApiClient.post(`/api/v1/message`, {
          message: outgoingMessage,
        });
      }
    },
  };
};
