import { branch, renderComponent } from 'recompose';

import UploadMediaItem from '../SharedMediaFiles/UploadMediaItem';
import ImageItem from './ImageItem';

const enhance = branch(
  ({ state }) => state === 'Uploading',
  renderComponent(UploadMediaItem),
  renderComponent(ImageItem)
);

export default enhance(null!);
