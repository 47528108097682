import { identity } from '@soluto-home-web/platform-identity';
import { mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import rxjsconfig from '../utils/rxjsconfig';

export default mapPropsStreamWithConfig(rxjsconfig)(
  (props$: Observable<{ timelineId: string }>) => {
    return props$.combineLatest(
      identity.updated$.distinctUntilChanged().startWith(undefined),
      (props, homeUser) => {
        return {
          ...props,
          flowId: homeUser && homeUser.flowId,
        };
      }
    );
  }
);
