import equals from 'lodash.isequal';
import { Dispatch, useEffect, useReducer } from 'react';

const reducer = <T extends {}>(
  state: T,
  next: Partial<T> | ((state: T) => Partial<T>)
) => {
  if (typeof next === 'function') {
    next = next(state);
  }
  const newState = { ...state, ...next };
  return equals(state, newState) ? state : newState;
};

const getInitialState = <T>([state, defaultValues]: [
  string | undefined,
  T
]): T => {
  let parsedState: Partial<T> | undefined = undefined;
  try {
    parsedState = JSON.parse(state as string);
  } catch (err) {}

  if (!parsedState || typeof parsedState !== 'object') {
    parsedState = {};
  }

  return {
    ...defaultValues,
    ...parsedState,
  };
};

export default <T>(
  initialState: string | undefined,
  defaultValues: T,
  updateItem: (state: string) => void
): [T, Dispatch<Partial<T> | ((state: T) => Partial<T>)>] => {
  const result = useReducer(
    reducer,
    [initialState, defaultValues],
    getInitialState
  );
  const [state] = result;

  useEffect(() => {
    const serializedState = JSON.stringify(state);
    if (serializedState !== initialState) {
      updateItem(serializedState);
    }
  }, [state, updateItem]);

  // @ts-ignore
  return result;
};
