import { BreakPoints } from '@soluto-home-web/web-theme';
import * as React from 'react';
import styled, { withTheme } from 'styled-components';

const TechExpertContainer = styled.div`
  display: flex;
`;

const TechExpertIcon = styled.img`
  width: 27px;
  height: 30px;
`;

const TechExpertText = styled.span`
  display: inline-flex;
  align-items: center;
  margin-left: 7px;
  line-height: 0.7;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.color || '#ffffff'};
  font-weight: normal;
  font-size: 20px;

  @media ${BreakPoints.tablet} {
    font-size: 30px;
  }
`;

export const PartnerIconAndTitle: React.SFC<{
  theme: Theme;
  color?: string;
}> = ({ color, theme }) => {
  return (
    <TechExpertContainer>
      <TechExpertIcon src={theme.images.partner_icon} />
      <TechExpertText color={color}>
        {theme.texts.partner_service_name}
      </TechExpertText>
    </TechExpertContainer>
  );
};

export default withTheme(PartnerIconAndTitle);
