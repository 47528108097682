import * as React from 'react';
const messageError = require('./message-error.svg');
import { styled } from '@soluto-home-web/web-theme';
import { withAnalyticOnEvent } from 'react-shisell';

const ResendImage = styled.img`
  cursor: pointer;
  transition: opacity 200ms ease-in;
`;

const ResendImageWithAnalytic = withAnalyticOnEvent<
  React.ImgHTMLAttributes<HTMLImageElement>
>({
  eventName: 'onClick',
  analyticName: 'Resend_Click',
})(ResendImage);

export type ResendButtonProps = {
  contentId: string;
  resendMessage: (messageId: string) => void;
};

const ResendButton = ({ contentId, resendMessage }: ResendButtonProps) => {
  return (
    <ResendImageWithAnalytic
      src={messageError}
      alt="Alert"
      onClick={() => {
        resendMessage(contentId);
      }}
    />
  );
};

export default ResendButton;
