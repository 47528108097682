import { Subject } from 'rxjs/Subject';
import Cookies, { CookieChangeOptions } from 'universal-cookie';

const subject = new Subject<CookieChangeOptions>();
// TODO: Change back to new Cookies()
// as soon as we upgrade to the new typescript and fix the tests
const cookies = Cookies ? new Cookies() : null;

const onUpdate = (cookie: CookieChangeOptions) => {
  subject.next(cookie);
};

if (cookies) {
  cookies.addChangeListener(onUpdate);
}

export default cookies;
export { subject as onCookieChangeSubject };
