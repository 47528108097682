import { Text } from '@soluto-home-web/text-item/TextItem';
import {
  SendMessage,
  updateItem,
  withSendMessage,
} from '@soluto-home-web/timeline-core';
import { BreakPoints } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { compose } from 'recompose';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 95%;
  padding-bottom: 1px;

  @media ${BreakPoints.desktop} {
    max-width: 72%;
  }
`;

const ExpertText = styled(Text)`
  background-color: #e7e7e7;
  margin-bottom: 5px;
`;

const ListPickerItemStyle = styled(Text)`
  background-color: #e7e7e7;
  margin-bottom: 2px;
  cursor: pointer;
`;

type ListPickerContent = {
  listHeader: string;
  options: string[];
};

type ListPickerProps = {
  content: ListPickerContent;
  timelineId: string;
  contentId: string;
  contentType: string;
  sendMessage: SendMessage;
  selected: boolean;
};

type ListPickerItemProps = {
  timelineId: string;
  contentId: string;
  contentType: string;
  sendMessage: SendMessage;
  text: string;
};

class ListPickerItem extends React.Component<ListPickerItemProps> {
  handleClick = async () => {
    const {
      sendMessage,
      text,
      timelineId,
      contentType,
      contentId,
    } = this.props;
    await Promise.all([
      sendMessage(text),
      updateItem(timelineId, contentType, contentId, {
        selected: true,
      }),
    ]);
  }

  render() {
    return (
      <ListPickerItemStyle
        onClick={this.handleClick}
        className="list-picker-item"
      >
        {this.props.text}
      </ListPickerItemStyle>
    );
  }
}

class ListPicker extends React.Component<ListPickerProps> {
  render() {
    const {
      content,
      selected,
      sendMessage,
      timelineId,
      contentId,
      contentType,
    } = this.props;

    return (
      <Container>
        <ExpertText>{content.listHeader}</ExpertText>

        {selected
          ? null
          : content.options.map((text, key) => (
              <ListPickerItem
                {...{
                  text,
                  contentType,
                  sendMessage,
                  timelineId,
                  contentId,
                  key,
                }}
              />
            ))}
      </Container>
    );
  }
}

export default compose(withSendMessage)(ListPicker);
