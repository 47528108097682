import { Context } from 'tweek-client';
import { queryParams } from './query-params-provider';

export function getContext(): Context {
  return {
    user_browser: {
      Intent: queryParams.intent.value!,
      FlowId: queryParams.flowId.value!,
    },
  };
}
