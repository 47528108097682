import * as uuid from 'uuid';

const LOCAL_STORAGE_KEY_NAME = 'web-session-identifier';
export default class UserBrowser {
  public static get Id(): string {
    try {
      let sessionId: string | null = localStorage.getItem(
        LOCAL_STORAGE_KEY_NAME
      );

      if (!sessionId) {
        sessionId = uuid.v4() as string;
        localStorage.setItem(LOCAL_STORAGE_KEY_NAME, sessionId);
      }

      return sessionId;
    } catch (exception) {
      return uuid.v4();
    }
  }
}
