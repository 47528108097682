import { AnalyticsEventModel } from 'shisell';
import { IAnalyticsWriter } from './IAnalyticsWriter';

class AnalyticsWriter implements IAnalyticsWriter {
  isEnabled(): Promise<boolean> {
    return Promise.resolve(
      globals.NODE_ENV !== 'production' || globals.REACT_APP_IS_TEST
    );
  }

  async write(eventModel: AnalyticsEventModel): Promise<void> {
    console.log('Analytic sent: ', eventModel);
  }
}

export default new AnalyticsWriter();
