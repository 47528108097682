import { cookies } from '@soluto-home-web/platform-identity';
import { queryString } from '@soluto-home-web/platform-utils';

export interface IQueryParam {
  queryParamName: string;
  readonly value?: string;
  useCookie: boolean;
}

class QueryParam implements IQueryParam {
  constructor(
    public queryParamName: string,
    public useCookie: boolean = true,
    public value?: string
  ) {}
}

export type QueryParams = {
  flowId: IQueryParam;
  homeUserId: IQueryParam;
  messageId: IQueryParam;
  intent: IQueryParam;
  ctaIdentifier: IQueryParam;
};

export let queryParams: QueryParams = {
  flowId: new QueryParam('f'),
  homeUserId: new QueryParam('ap_hid'),
  messageId: new QueryParam('ap_mid'),
  intent: new QueryParam('intent'),
  ctaIdentifier: new QueryParam('extraData_CtaIdentifier'),
};

export function initialize(): void {
  const parsedQueryString = queryString.get();

  Object.keys(queryParams).forEach((queryParam) =>
    initializeQueryParam(queryParams[queryParam], parsedQueryString)
  );
}

function initializeQueryParam(
  queryParam: QueryParam,
  parsedQueryParam: object
): void {
  const queryParamValue = parsedQueryParam[queryParam.queryParamName];
  queryParam.value = queryParamValue;

  if (queryParam.useCookie && cookies) {
    if (queryParamValue) {
      cookies.set(queryParam.queryParamName, queryParamValue);
    } else {
      queryParam.value = cookies.get(queryParam.queryParamName);
    }
  }
}
