import tinyColor from 'tinycolor2';
import { css } from './styled';

const transitioned = css`
  transition: all 0.2s ease-in-out;
`;

const themedState = css`
  background-color: ${(props) => props.theme.colors.primary};
  &:hover {
    background-color: ${(props) =>
      tinyColor(props.theme.colors.primary)
        .darken(5)
        .toString()};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.primary_active};
  }
`;

const styledInput = css`
  background-color: transparent;
  color: #454545;
  box-shadow: none;
  border: 0;
  border-bottom: 0.1em dashed #454545;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  padding: 0;
  box-sizing: border-box;
  &::placeholder {
    color: rgba(69, 69, 69, 0.6);
  }
  &[disabled='true'] {
    color: #dedede;
    border-bottom-color: #dedede;
    &::placeholder {
      color: #dedede;
    }
  }
  &[data-has-error='true'] {
    border-bottom: 0.1em dashed #ff6302;
  }
`;

const withOutsideHoverEffect = css`
  display: inline-block;
  position: relative;
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
  border: #fff solid 0.2em;
  background-clip: padding-box;
  &::after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: -0.2em;
    left: -0.2em;
    padding: 0.2em;
    box-shadow: 0 0 0 0.2em ${(props) => props.theme.colors.primary};
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(0.8);
    opacity: 0;
  }

  &:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  &:hover:active {
    &::after {
      opacity: 0;
    }
  }
`;

const linked = css`
  text-decoration: underline;
  cursor: pointer;
`;

export { css };
export default {
  transitioned,
  themedState,
  styledInput,
  withOutsideHoverEffect,
  linked,
};
