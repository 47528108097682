import { monitorClient } from '@soluto-home-web/platform-logger';
import { getFromTweek } from '@soluto-home-web/platform-tweek';
import { userAgentParser } from '@soluto-home-web/platform-utils';
import { LogLevel } from '../logger';

type PathTransformer = {
  match: string;
  replace: string;
};
const ConstPathTransformers: PathTransformer[] = [
  { match: '[\\_.]', replace: '-' },
  { match: '(^/)|(/$)', replace: '' },
  { match: '/', replace: '_' }, //this must be the last transformer - to split the path into graphite hierarchy
];

//transformers in case we couldn't get the tweek key
const FallbacksTransformers: PathTransformer[] = [
  { match: '[\\_.]', replace: '-' },
  { match: '(^/)|(/$)', replace: '' },
  { match: '/', replace: ' ' },
  { match: '^', replace: 'unknown-path_' },
];

let _urlPathTransformers: PathTransformer[] | undefined;

async function _transformPath(path: string) {
  if (!_urlPathTransformers) {
    try {
      _urlPathTransformers = await getFromTweek(
        'soluto_home_web/metrics/log_counts/url_path_transformers'
      );
      _urlPathTransformers = _urlPathTransformers!.concat(
        ConstPathTransformers
      );
    } catch {
      _urlPathTransformers = undefined;
    }
  }

  let transformedPath = path;
  (_urlPathTransformers || FallbacksTransformers).forEach((transformer) => {
    transformedPath = transformedPath.replace(
      new RegExp(transformer.match, 'gi'),
      transformer.replace
    );
  });

  return transformedPath;
}

export default async (
  level: LogLevel,
  message: any,
  data: any,
  extraData?: any,
  options?: { [key: string]: any }
) => {
  const transformedPathName = await _transformPath(window.location.pathname);
  const osName = userAgentParser.getOs();
  const browserName =
    userAgentParser
      .getBrowserName()
      .replace(/ /g, '')
      .replace(/[_.]/, '-') || 'Unknown';
  const metricName = `logs_${transformedPathName}_${osName}_${browserName}_${level}`;

  await monitorClient.increment(metricName);
};
