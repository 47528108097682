import { BreakPoints } from '@soluto-home-web/web-theme';
import * as React from 'react';
import styled from 'styled-components';
import BrandedIconAndTitle from './BrandedIconAndTitle';
import Page from './Page';

const StyledPage = styled(Page)`
  min-height: 400px;
`;
const BlockOnImage = styled.div`
  display: block;
  position: absolute;
  padding-left: 11%;
  padding-right: 22%;
  @media ${BreakPoints.mobile} {
    padding-left: 7%;
    padding-right: 7%;
  }
`;

const BrandedIconAndTitleContainer = styled(BlockOnImage)`
  top: 4.2vh;
`;

type Props = {
  name: string;
};

export class ErrorPage extends React.Component<Props> {
  render() {
    const { name, children } = this.props;

    return (
      <StyledPage pageName={name}>
        <BrandedIconAndTitleContainer>
          <BrandedIconAndTitle color="#999999" />
        </BrandedIconAndTitleContainer>
        {children}
      </StyledPage>
    );
  }
}
