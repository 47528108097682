import * as React from 'react';
import { isClassComponent, toClass } from 'recompose';
import { isStyledComponent } from 'styled-components';

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export type Predicate<T> = (props: T) => boolean;

let _requestsPerComponent: Map<any, boolean> = new Map();

const removeRequestsToDisableScroll = (targetElement: any) => {
  const isDisabled = _requestsPerComponent.delete(targetElement);
  if (isDisabled) {
    enableBodyScroll(targetElement);
  }
};

const changeRequestsToDisableScroll = (
  targetElement: any,
  predicateResult: boolean
) => {
  const prevValue = _requestsPerComponent.get(targetElement);
  if (prevValue === predicateResult) {
    return;
  }

  _requestsPerComponent.set(targetElement, predicateResult);

  predicateResult
    ? disableBodyScroll(targetElement)
    : prevValue !== undefined && enableBodyScroll(targetElement);
};

export function disableScroll<T>(predicate?: Predicate<T>) {
  return (WrappedComponent: React.ComponentType) =>
    class DisableScroll extends React.Component<T> {
      private _targetRef;

      constructor(props) {
        super(props);
        this._targetRef = React.createRef();

        if (
          !isClassComponent(WrappedComponent) &&
          !isStyledComponent(WrappedComponent)
        ) {
          WrappedComponent = toClass(WrappedComponent);
        }
      }

      componentDidMount() {
        changeRequestsToDisableScroll(
          this._targetRef.current,
          typeof predicate === 'undefined' || predicate(this.props)
        );
      }

      componentDidUpdate() {
        if (!predicate) {
          return;
        }

        changeRequestsToDisableScroll(
          this._targetRef.current,
          predicate(this.props)
        );
      }

      componentWillUnmount() {
        removeRequestsToDisableScroll(this._targetRef.current);
      }

      render() {
        return <WrappedComponent {...this.props} ref={this._targetRef} />;
      }
    };
}

export function resetScrollLock() {
  _requestsPerComponent.forEach((value, key) => {
    if (value) {
      enableBodyScroll(key);
    }
  });
  _requestsPerComponent.clear();
}
