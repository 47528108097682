import * as React from 'react';
import styled from 'styled-components';
import { ErrorDescription } from './ErrorDescription';
import { ErrorPage } from './ErrorPage';
const somethingWentWrongDog = require('./something-went-wrong-dog.svg');

const ErrorImage = styled.img`
  width: 70vw;
  max-width: 426px;
  min-width: 225px;
`;

class SomethingWentWrongPage extends React.Component {
  render() {
    return (
      <ErrorPage name="SomethingWentWrong">
        <ErrorDescription
          title="Something went wrong..."
          description="Please try again later"
        >
          <ErrorImage src={somethingWentWrongDog} />
        </ErrorDescription>
      </ErrorPage>
    );
  }
}

export default SomethingWentWrongPage;
