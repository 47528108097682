import TextItem from '@soluto-home-web/text-item';
import { itemTypes, TimelineItem } from '@soluto-home-web/timeline-core';
import React from 'react';
import styled from 'styled-components';
import { TwoStepResolutionContent } from '../TwoStepResolutionContentType';

type UserMessageProps = {
  message: string;
};

const UserMessageContainer = styled.div`
  margin: 24px 0vh;
  display: flex;
  flex-direction: row-reverse;
`;

const UserMessage = (
  props: UserMessageProps & TimelineItem<TwoStepResolutionContent>
) => (
  <UserMessageContainer tabIndex={0}>
    <TextItem
      {...props}
      fromExpert={false}
      isGrouped={false}
      type={itemTypes.TextMessage}
      messageSelected={false}
      deleteable={false}
      content={props.message}
    />
  </UserMessageContainer>
);

export default UserMessage;
