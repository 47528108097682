import { getFromTweek } from '@soluto-home-web/platform-tweek';
import { queryString } from '@soluto-home-web/platform-utils';
import { Base64 } from 'js-base64';
import { compose, lifecycle, withState } from 'recompose';
import { getStoreItem, setStoreItem, TimelineStoreValues } from '../core/store';
import withFlowId from './withFlowId';
import withIsSignedInAndNotInSession from './withIsSignedInAndNotInSession';
import withSendMessage, { SendMessage } from './withSendMessage';
import withSessionId from './withSessionId';

type Props = {
  customFirstMessage: string;
  flowId: string;
  isSignedInAndNotInSession: boolean;
  sendMessage: SendMessage;
  sessionId: string;
  setCustomFirstMessage: (string) => void;
  timelineId: string;
  requiredExpertise: string;
};

const setAutomaticMessageSent = (flowId: string) => {
  let autoFirstMessageSent = getStoreItem(
    TimelineStoreValues.automaticFirstMessageSentByFlow
  );
  if (!autoFirstMessageSent) {
    autoFirstMessageSent = [];
  }
  autoFirstMessageSent.push(flowId);
  setStoreItem(
    TimelineStoreValues.automaticFirstMessageSentByFlow,
    autoFirstMessageSent
  );
};

const shouldSendAutoMessage = async (flowId: string) => {
  if (!flowId) {
    return false;
  }
  const isExclusionFlow: boolean = await getFromTweek(
    'soluto_home_web/messaging/automatic_first_user_message_exclusion'
  );
  if (isExclusionFlow) {
    return true;
  }
  const autoFirstMessageSent = getStoreItem(
    TimelineStoreValues.automaticFirstMessageSentByFlow
  );
  return !(autoFirstMessageSent && autoFirstMessageSent.includes(flowId));
};

const sendFirstMessage = async (props: Props) => {
  let firstMessageText = props.customFirstMessage;
  if (firstMessageText) {
    props.setCustomFirstMessage('');
    setAutomaticMessageSent(props.flowId);
  }

  if (
    !firstMessageText &&
    props.isSignedInAndNotInSession &&
    (await shouldSendAutoMessage(props.flowId))
  ) {
    firstMessageText = await getFromTweek(
      'soluto_home_web/messaging/automatic_first_user_message'
    );
    setAutomaticMessageSent(props.flowId);
  }

  if (!firstMessageText) {
    return;
  }

  props.sendMessage(firstMessageText, props.requiredExpertise);
};

const handleCustomFirstMessage = (props) => {
  const queryParamFirstMessage = queryString.get().cfm
    ? Base64.decode(decodeURIComponent(queryString.get().cfm))
    : '';

  if (
    queryParamFirstMessage &&
    queryParamFirstMessage !== props.customFirstMessage
  ) {
    props.setCustomFirstMessage(queryParamFirstMessage);
  }
};

export default compose(
  withSendMessage,
  withFlowId,
  withIsSignedInAndNotInSession,
  withSessionId,
  withState('customFirstMessage', 'setCustomFirstMessage', ''),
  lifecycle<Props, Props>({
    componentWillUpdate(nextProps) {
      const isNotInSession = nextProps.isSignedInAndNotInSession;
      const isCustomMessageWaiting = nextProps.customFirstMessage;
      const didSessionIdJustArrive =
        nextProps.sessionId && nextProps.sessionId !== this.props.sessionId;
      const didSessionJustEnded =
        !nextProps.sessionId && nextProps.sessionId !== this.props.sessionId;
      const wasCustomMessageJustSent =
        !nextProps.customFirstMessage &&
        nextProps.customFirstMessage !== this.props.customFirstMessage;

      if (
        (didSessionIdJustArrive && isCustomMessageWaiting) ||
        (isNotInSession && !didSessionJustEnded && !wasCustomMessageJustSent)
      ) {
        sendFirstMessage(nextProps);
      }
    },
    componentDidMount() {
      if (this.props.flowId && this.props.timelineId) {
        handleCustomFirstMessage(this.props);
      }
    },
  })
);
