import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import DashedLine from './DashedLine';

const LeftChatContainer = styled.div`
    marginTop: 18,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  `;

type ExpertLeftConversationProps = {
  content: {
    expertName: string;
  };
  expertBrandingName: string;
};

const ExpertLeftConversationMessage = ({
  content: { expertName },
  expertBrandingName,
}: ExpertLeftConversationProps) => (
  <LeftChatContainer tabIndex={0}>
    <DashedLine text={`${expertName || expertBrandingName} has left\n`} />
  </LeftChatContainer>
);

export default ExpertLeftConversationMessage;
