import React from 'react';

export type NeedleProps = {
  className?: string;
};

const Needle = ({ className }: NeedleProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="84"
    height="289"
    viewBox="0 0 84 289"
    fill="none"
    className={className}
  >
    <circle cx="42.2228" cy="247" r="33.5" stroke="black" strokeWidth="16" />
    <path
      d="M28.087 217.864C25.3367 169.75 26.7828 89.3974 37.1296 8.40412C38.5552 -2.75471 47.6955 -2.82523 49.1098 8.33633C59.3149 88.873 58.2712 169.5 56.2712 217.864C54.7666 224.401 29.8577 224.272 28.087 217.864Z"
      fill="black"
    />
  </svg>
);

export default Needle;
