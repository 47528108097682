import * as React from 'react';
const trashCanIcon = require('./trash_can.svg') as string;
import { itemTypes } from '@soluto-home-web/timeline-core';
import { styled } from '@soluto-home-web/web-theme';
import { withAnalyticOnEvent } from 'react-shisell';
import { compose, withState } from 'recompose';

const contentTypes = {
  [itemTypes.TextMessage]: 'Text',
  [itemTypes.ImageItem]: 'Image',
};

interface TrashCanImageProps {
  show: boolean;
  tabIndex: number;
}

const TrashCanImage = styled.img`
  cursor: ${(props: TrashCanImageProps) => (props.show ? `pointer` : `auto`)};
  transition: opacity 200ms ease-in;
  opacity: ${(props: TrashCanImageProps) => +props.show};
  margin-right: 16px;
  padding: 3px 7px;
  height: 22px;
  width: 15px;
  align-self: flex-end;
`;

const TrashCanImageWithAnalytic = withAnalyticOnEvent<
  TrashCanImageProps & React.ImgHTMLAttributes<HTMLImageElement>
>({
  eventName: 'onClick',
  analyticName: 'Delete_Click',
})(TrashCanImage);

interface TrashCanProps {
  isHover: boolean;
  deleteMessage: (
    timelineId: string,
    contentId: string,
    contentType: string
  ) => void;
  timelineId: string;
  contentId: string;
  contentType: string;
}

type ClickableTrashCanProps = TrashCanProps & {
  hideAndDisableDeleteIcon: boolean;
  setHideAndDisableDeleteIcon: (_: boolean) => void;
};

const ClickableTrashCan = ({
  isHover,
  deleteMessage,
  timelineId,
  contentId,
  contentType,
  hideAndDisableDeleteIcon,
  setHideAndDisableDeleteIcon,
}: ClickableTrashCanProps) => (
  <TrashCanImageWithAnalytic
    show={isHover && !hideAndDisableDeleteIcon}
    onClick={() => {
      if (isHover) {
        setHideAndDisableDeleteIcon(true);
        deleteMessage(timelineId, contentId, contentType);
      }
    }}
    tabIndex={isHover && !hideAndDisableDeleteIcon ? 0 : -1}
    src={trashCanIcon}
    alt={
      isHover && !hideAndDisableDeleteIcon
        ? 'Click to delete'
        : 'Click to show more options'
    }
    role="button"
    analyticsExtras={{ MessageType: contentTypes[contentType] }}
    onKeyUp={(event) => {
      if (isHover && event && event.key === 'Enter') {
        setHideAndDisableDeleteIcon(true);
        deleteMessage(timelineId, contentId, contentType);
      }
    }}
  />
);

export default compose<ClickableTrashCanProps, TrashCanProps>(
  withState('hideAndDisableDeleteIcon', 'setHideAndDisableDeleteIcon', false)
)(ClickableTrashCan);
