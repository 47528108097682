import React from 'react';
import styled, { withTheme } from 'styled-components';

const PermissionResultText = styled.div`
  font-style: italic;
  text-align: right;
`;

PermissionResultText.displayName = 'PermissionResultText';

export const PermissionNotAllowed = () => (
  <PermissionResultText tabIndex={0} role="text">
    You have declined the battery
    <br />
    max capacity test.
  </PermissionResultText>
);

export const PermissionAllowed = withTheme(({ theme }) => (
  <PermissionResultText tabIndex={0} role="text">
    You allowed your {theme.texts.partner_expert_name} to
    <br />
    run a battery max capacity test.
  </PermissionResultText>
));
