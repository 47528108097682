import { TimelineItem } from '@npmsoluto/messaging-contracts';
import { logger } from '@soluto-home-web/platform-logger';
import { updateItem as updateTimelineItem } from '@soluto-home-web/timeline-core';
import React, { useCallback, useEffect } from 'react';
import MaxCapacityTest from './MaxCapacityTest';
import OpenInApp from './OpenInApp';
import PermissionRequest from './PermissionRequest';
import { PermissionNotAllowed } from './PermissionResultText';
import TestResults from './TestResults';
import { CapacityState } from './types';
import usePersistentState from './usePersistentState';

declare const BatteryPerformanceRetriever: {
  getBatteryCapacity: () => number;
  getDesignedBatteryCapacity: () => number;
  getBatteryCharge: () => number;
};

function safe<T>(callback: () => T): T | undefined {
  try {
    return callback();
  } catch (e) {
    return undefined;
  }
}

const isInApp =
  typeof BatteryPerformanceRetriever !== 'undefined' &&
  typeof BatteryPerformanceRetriever.getBatteryCharge === 'function' &&
  typeof BatteryPerformanceRetriever.getDesignedBatteryCapacity === 'function';

enum BatteryCapacityCheckStatus {
  requestPermission = 'requestPermission',
  notAllowed = 'notAllowed',
  waitingForResult = 'waitingForResult',
  capacityResult = 'capacityResult',
}

type State = {
  status: BatteryCapacityCheckStatus;
  capacityState?: CapacityState;
  maxCapacity?: number;
  designCapacity?: number;
  batteryCharge?: number;
  viewed?: boolean;
  expired?: boolean;
};

const defaultState: State = {
  status: BatteryCapacityCheckStatus.requestPermission,
};

export type BatteryCapacityContent = {
  env?: string;
  sessionId?: string;
};

export type BatteryCapacityProps = TimelineItem<BatteryCapacityContent> & {
  timelineId: string;
  state?: string;
  triggerScrollDown: () => void;
};

const sendTestResult = async (
  baseUrl: string,
  sessionId: string | undefined,
  testResult: State
) => {
  if (!sessionId) {
    return;
  }
  const response = await fetch(`${baseUrl}/sessionMetaData-update`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({ sessionId, sessionMetaData: { testResult } }),
    headers: { 'Content-Type': 'application/json' },
  });

  if (!response.ok) {
    const message = await response.text();
    logger.warning('failed sending max capacity results', {
      status: response.status,
      statusText: response.statusText,
      message,
    });
  }
};

const BatteryCapacityTimelineItem = ({
  timelineId,
  contentType,
  contentId,
  content: { env, sessionId } = {},
  state: initialState,
  timestamp,
  triggerScrollDown,
}: BatteryCapacityProps) => {
  const updateItem = useCallback(
    (state: string) =>
      updateTimelineItem(timelineId, contentType, contentId, { state }),
    [timelineId, contentType, contentId]
  );

  const [state, setState] = usePersistentState(
    initialState,
    defaultState,
    updateItem
  );

  const { status, capacityState, viewed, expired } = state;
  const isDev = env && env !== 'prod' && env !== 'production';

  const refreshApiBaseUrl = isDev
    ? 'https://device-refresh-api.soluto.npr.aws.asurion.net'
    : 'https://api.techcheckup.co';

  useEffect(triggerScrollDown, [status]);

  switch (status) {
    case BatteryCapacityCheckStatus.notAllowed:
      return <PermissionNotAllowed />;
    case BatteryCapacityCheckStatus.capacityResult:
      return (
        <TestResults
          capacityState={capacityState!}
          viewed={viewed}
          setViewed={() => setState({ viewed: true })}
        />
      );
  }

  if (expired || Date.now() - timestamp > 2 * 60 * 60 * 1000) {
    if (!expired) {
      setState({ expired: true });
    }
    return null;
  }

  if (!isInApp) {
    return <OpenInApp />;
  }

  switch (status) {
    case BatteryCapacityCheckStatus.requestPermission:
      const onPermissionResponse = (allowed: boolean) => {
        if (!allowed) {
          const state = { status: BatteryCapacityCheckStatus.notAllowed };
          setState(state);
          sendTestResult(refreshApiBaseUrl, sessionId, state);
          return;
        }

        if (!isInApp) {
          setState({
            status: BatteryCapacityCheckStatus.waitingForResult,
            capacityState: CapacityState.inconclusive,
          });
          sendTestResult(refreshApiBaseUrl, sessionId, {
            status: BatteryCapacityCheckStatus.capacityResult,
            capacityState: CapacityState.inconclusive,
          });
          return;
        }

        const maxCapacity =
          safe(() => BatteryPerformanceRetriever.getBatteryCapacity()) || 0;
        const designCapacity =
          safe(() =>
            BatteryPerformanceRetriever.getDesignedBatteryCapacity()
          ) || 0;
        const batteryCharge = safe(() =>
          BatteryPerformanceRetriever.getBatteryCharge()
        );

        const percent = maxCapacity / designCapacity;
        const capacityState =
          maxCapacity < 1 || designCapacity < 1
            ? CapacityState.inconclusive
            : percent < 0.8
            ? CapacityState.diminished
            : CapacityState.optimal;

        setState({
          status: BatteryCapacityCheckStatus.waitingForResult,
          capacityState,
          maxCapacity,
          designCapacity,
          batteryCharge,
        });

        sendTestResult(refreshApiBaseUrl, sessionId, {
          status: BatteryCapacityCheckStatus.capacityResult,
          capacityState,
          maxCapacity,
          designCapacity,
          batteryCharge,
        });
      };

      return <PermissionRequest onResult={onPermissionResponse} />;
    case BatteryCapacityCheckStatus.waitingForResult:
      return (
        <MaxCapacityTest
          onFinished={() =>
            setState({ status: BatteryCapacityCheckStatus.capacityResult })
          }
          capacityState={capacityState!}
        />
      );
    default:
      return null;
  }
};

export default (props: BatteryCapacityProps) => (
  <div aria-live="polite" aria-atomic={true} aria-relevant="additions">
    <BatteryCapacityTimelineItem {...props} />
  </div>
);
