import { TimelineItem } from '@soluto-home-web/timeline-core';
import * as React from 'react';
import { TwoStepResolutionContent } from '../../TwoStepResolutionContentType';
import UserMessage from '../UserMessage';

type ResolutionAnswerProps = {
  resolutionAnswer: string;
};

const ResolutionAnswer = (
  props: TimelineItem<TwoStepResolutionContent> & ResolutionAnswerProps
) => {
  const { resolutionAnswer } = props;

  return <UserMessage {...props} message={resolutionAnswer} />;
};

export default ResolutionAnswer;
