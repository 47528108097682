import queryString from 'query-string';

function get(): { [k: string]: string } {
  return queryString.parse(location.search);
}

function stringify(obj: object): string {
  return Object.keys(obj).length === 0 ? '' : `?${queryString.stringify(obj)}`;
}

function remove(k: string): string {
  const queryStringWithoutKey = (({ [k]: undefined, ...rest }) => rest)(get());
  return stringify(queryStringWithoutKey);
}

function add(k: string): string {
  return addWithValue(k, null);
}

function addWithValue(
  k: string,
  val: string | number | boolean | null
): string {
  const queryStringWithKey = { ...get(), ...{ [k]: val } };
  return stringify(queryStringWithKey);
}

export default {
  get,
  remove,
  add,
  addWithValue,
};
