import { TimelineItem } from '@soluto-home-web/timeline-core';
import React from 'react';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';
import { compose } from 'recompose';
import Tags from '../shared/MultipleSelection';
import TeamMessage from '../shared/TeamMessage';
import { SecondScreenSurveyContent } from './SecondScreenSurveyContentType';

const SurveyTeamMessage = (props) =>
  TeamMessage<SecondScreenSurveyContent>(props);

const SecondScreenSurveyTimelineItem = (props) => (
  <div>
    <SurveyTeamMessage
      {...props}
      isGrouped={true}
      message={props.content.surveyText}
    />
    <Tags options={props.content.options} shouldSendAnalyticsOnClick={true} />
  </div>
);

export default compose<
  TimelineItem<SecondScreenSurveyContent>,
  TimelineItem<SecondScreenSurveyContent>
>(
  enrichAnalytics((_) => _.createScoped('AvailableDevicesSurvey')),
  withAnalyticOnView({
    analyticName: 'View',
  })
)(SecondScreenSurveyTimelineItem);
