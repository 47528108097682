import { generateMarkName, MarkName } from '../performanceMarkNameGenerator';
import { IPerformanceTimeline } from './IPerformanceTimeline';

export class PerformanceTimeline implements IPerformanceTimeline {
  startMeasurement(pathname: string): void {
    window.performance.mark(generateMarkName(pathname).startMark);
  }

  isMeasurementStarted(pathname: string): boolean {
    return (
      window.performance.getEntriesByName(generateMarkName(pathname).startMark)
        .length > 0
    );
  }

  endMeasurement(pathname: string, pageName): PerformanceEntry | null {
    const markName: MarkName | null = generateMarkName(pathname);

    if (!this.getLastMeasurement(markName.startMark)) {
      return null;
    }

    window.performance.mark(markName.endMark);
    window.performance.measure(pageName, markName.startMark, markName.endMark);

    return this.getLastMeasurement(pageName);
  }

  getLastMeasurement(entryName: string): PerformanceEntry | null {
    const pageMeasurements: PerformanceEntry[] = window.performance.getEntriesByName(
      entryName
    );

    return pageMeasurements.length > 0
      ? pageMeasurements[pageMeasurements.length - 1]
      : null;
  }
}
