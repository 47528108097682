import { AnalyticsDispatcher } from 'shisell';
import TimelineItem from '../models/TimelineItem';

export default (
  analyticsDispatcher: AnalyticsDispatcher,
  items: TimelineItem<any>[],
  sessionId: string,
  relevantContentTypes: string[]
) => {
  const filteredMessages = items
    .slice()
    .reverse()
    .filter(
      (item) =>
        relevantContentTypes.indexOf(item.contentType) >= 0 &&
        item.senderType !== 'Team'
    );
  const relevantMessages = filteredMessages.filter(
    (message, i) =>
      i === 0 || message.senderType !== filteredMessages[i - 1].senderType
  );

  let analyticsDispatcherWithSessionId: AnalyticsDispatcher = analyticsDispatcher
    .withExtra('CustomerPlatform', 'Web')
    .withExtra('MessagingPageSource', 'TepExtraInStoreOnboarding');

  if (!sessionId) {
    analyticsDispatcherWithSessionId = analyticsDispatcherWithSessionId.withExtra(
      'IsFirstMessage',
      'Yes'
    );
  } else {
    analyticsDispatcherWithSessionId = analyticsDispatcherWithSessionId
      .withIdentity('SessionId', sessionId)
      .withExtra('MessageDuringVoice', false)
      .withExtra('IsFirstMessage', 'No')
      .withExtra(
        'LastSender',
        relevantMessages[0]
          ? relevantMessages[0].senderType === 'Device'
            ? 'Customer'
            : 'Technician'
          : 'none'
      );

    const lastTechMessage = relevantMessages[0]
      ? relevantMessages[0].senderType === 'Technician'
        ? relevantMessages[0]
        : relevantMessages.length > 1
        ? relevantMessages[1]
        : null
      : null;

    if (lastTechMessage) {
      const now = Date.now();
      const toSecond = (time) => time / 1000;
      const toMinute = (time) => toSecond(time) / 60;

      analyticsDispatcherWithSessionId = analyticsDispatcherWithSessionId
        .withExtra(
          'TimeFromLastTechMessageInMins',
          Math.round(toMinute(now - lastTechMessage.timestamp))
        )
        .withExtra(
          'TimeFromLastTechMessageInSecs',
          Math.round(toSecond(now - lastTechMessage.timestamp))
        )
        .withExtra('ContentType', relevantMessages[0].contentType);
    }
  }

  return analyticsDispatcherWithSessionId as AnalyticsDispatcher;
};
