import { branch, compose } from 'recompose';
import withDeleteMessages, {
  withDeleteMessageDynamicComponents,
} from './withDeleteMessages';

export default compose(
  withDeleteMessages,
  branch<{ deleteable: boolean }>(
    ({ deleteable }) => deleteable,
    withDeleteMessageDynamicComponents(({ deleteMessage }) => ({
      deleteMessage,
    }))
  )
);
