import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { TimelineItem, withSessionId } from '@soluto-home-web/timeline-core';
import withSendMessage, {
  SendMessage,
} from '@soluto-home-web/timeline-core/hoc/withSendMessage';
import equals from 'ramda.equals';
import pick from 'ramda.pick';
import * as React from 'react';
import { AnalyticsContext, enrichAnalytics } from 'react-shisell';
import { compose } from 'recompose';
import { SecondScreenContent } from './SecondScreenContentType';
import SecondScreenQuestion from './SecondScreenQuestion/SecondScreenQuestion';

type OuterProps = TimelineItem<SecondScreenContent> & {
  timelineId: string;
  sessionId: string;
};

type CreatedProps = AnalyticsContext;

export type SecondScreenTimelineProps = OuterProps &
  CreatedProps & {
    triggerScrollDown: () => void;
    userResponses: Array<string>;
    sendMessage: SendMessage;
  };

class SecondScreenTimelineItem extends React.Component<
  SecondScreenTimelineProps
> {
  componentDidCatch(error, info) {
    this.props.analytics.dispatcher
      .withExtras({ error, info })
      .dispatch('UnknownError');
  }

  componentDidUpdate(prevProps: SecondScreenTimelineProps): void {
    const contentImpactingFields = ['addDeviceAnswer', 'messagesToShow'];
    if (
      !equals(
        pick(contentImpactingFields, this.props),
        pick(contentImpactingFields, prevProps)
      )
    ) {
      this.props.triggerScrollDown();
    }
  }

  componentDidMount(): void {
    this.props.analytics.dispatcher.dispatch('View');
  }

  render() {
    const { props } = this;
    return <SecondScreenQuestion {...props} />;
  }
}

export default compose<SecondScreenTimelineProps, OuterProps>(
  enrichAnalytics<OuterProps>((dispatcher, props) =>
    dispatcher
      .createScoped('AddADevice')
      .withExtras({ sessionId: props.sessionId, flowId: 'AddADevice' })
  ),
  withTweekKeys('support/session/add_a_device/customer_options', {
    propName: 'userResponses',
  }),
  withSendMessage,
  withSessionId
)(SecondScreenTimelineItem);
