import { Page } from '@soluto-home-web/platform-common-ui';
import {
  setTimelineContext,
  withFirebaseLogin,
  withFirstUserMessage,
  withMessagingApi,
  withMessagingFallback,
  withParamsStore,
} from '@soluto-home-web/timeline-core';
import TimelineTheme from '@soluto-home-web/timeline-theme';
import { BreakPoints, styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { enrichAnalytics } from 'react-shisell';
import { compose, setDisplayName, withState } from 'recompose';
import { withTheme } from 'styled-components';
import TimelineView from '../TimelineView';

const StyledPage = styled(Page)`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .spacer {
    height: 8vh !important;
  }

  @media ${BreakPoints.desktop} {
    height: 94%;
    width: 50%;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto 1.8vh auto;
    border-radius: 4px;
  }
`;

const Spacer = styled.div`
  height: 0;
  min-height: 0;

  @media ${BreakPoints.desktop} {
    height: 2.8vh;
    min-height: 18px;
  }
`;

const DesktopPageBackground = styled.div`
  width: 100%;
  height: 100%;
`;

const PageHeader = styled.div<{ color: string }>`
  @media ${BreakPoints.desktop} {
    position: absolute;
    height: 12.1vh;
    min-height: 88px;
    width: 100%;
    background: ${(props) => props.color};
  }
`;

type Props = {};

type InnerProps = {
  theme: TimelineTheme;
  timelineId: string;
  setTimelineId: (string) => void;
};

const LandingPage: React.FC<InnerProps> = ({ theme, timelineId }) => {
  return (
    <DesktopPageBackground>
      <PageHeader
        color={theme.colors.primary ? theme.colors.primary : 'white'}
      />
      <Spacer />
      <StyledPage pageName={'Timeline'}>
        <TimelineView timelineId={timelineId} showHistory={true} />
      </StyledPage>
    </DesktopPageBackground>
  );
};

export default compose<InnerProps, Props>(
  setTimelineContext,
  enrichAnalytics((_) => _.createScoped('Messaging')),
  withState('timelineId', 'setTimelineId', ''),
  withParamsStore,
  withTheme,
  withFirebaseLogin,
  withMessagingApi,
  withMessagingFallback,
  withFirstUserMessage,
  setDisplayName('LandingPage')
)(LandingPage);
