import { LogLevel } from '../logger';
const isErrorObject = (x: any) => x instanceof Error;

const appendMessageToError = (message: string, e: any) => {
  if (isErrorObject(e)) {
    if (e.message !== message) {
      e.message = `${message}\n${e.message}`;
    }

    return e;
  }
  if (typeof e === 'string') {
    e = `${message}\n${e}`;

    return e;
  }

  return message;
};

export default async (
  level: LogLevel,
  message: any,
  data: any,
  extraData?: any,
  options?: { [key: string]: any }
) => {
  switch (level) {
    case 'error': {
      const error = appendMessageToError(message, data);
      console.error(error, data || '', extraData);
      break;
    }
    case 'warning': {
      if (isErrorObject(data)) {
        const error = appendMessageToError(message, data);
        console.warn(error, extraData);

        return;
      }
      console.warn(message, data || '');
      break;
    }
    default: {
      if (console[level]) {
        console[level](message, data || '', extraData);
      }
    }
  }
};
