import { compose, getContext, mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { withCachedTimeline } from '../timeline-cache';
import rxjsconfig from '../utils/rxjsconfig';
import getMessagesWithTimelineItems from './messages-with-timeline-items-provider';
import { MessagingClientProps, messagingContextTypes } from './types';

interface OuterProps {
  timelineId: string;
  showHistory: boolean;
  messagingSupportedTypes?: string[];
}
export const withMessagesAsTimelineItems = compose(
  getContext(messagingContextTypes),
  mapPropsStreamWithConfig(rxjsconfig)(
    (props$: Observable<OuterProps & MessagingClientProps>) => {
      const timelineItems$ = props$
        .map(
          ({
            timelineId,
            messagingClient,
            showHistory,
            messagingSupportedTypes,
          }) => ({
            timelineId,
            messagingClient,
            showHistory,
            messagingSupportedTypes,
          })
        )
        .filter(({ messagingClient }) => !!messagingClient)
        .distinctUntilKeyChanged('timelineId')
        .switchMap(
          ({
            messagingClient,
            timelineId,
            showHistory,
            messagingSupportedTypes,
          }) =>
            getMessagesWithTimelineItems(
              messagingClient,
              timelineId,
              showHistory,
              messagingSupportedTypes
            ).startWith([])
        )
        .startWith([]);

      return props$.combineLatest(
        timelineItems$,
        (props, timelineItems: any[]) => ({
          ...props,
          timelineItems,
        })
      );
    }
  ),
  withCachedTimeline
);
