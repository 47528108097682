import {
  getTimelineItemKey,
  SendMessage,
  TimelineItem,
} from '@soluto-home-web/timeline-core';
import TypingIndicator from '@soluto-home-web/typing-indicator/TypingIndicator';
import { BreakPoints } from '@soluto-home-web/web-theme';
import React, { lazy } from 'react';
import { compose, setDisplayName, withState } from 'recompose';
import styled, { keyframes } from 'styled-components';
import { isGrouped as _isGrouped, prev } from '../utils/grouping-utils';
import timelineItemTypeToComponent from '../utils/timelineItemTypeToComponent';
const MessageBox = lazy(() =>
  import(/* webpackChunkName: "message-box" */ './MessageBox')
);

const Container = styled.div`
  margin: auto;
  background-color: white;
  height: 100%;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
`;

const ItemContainer = styled.div`
  margin: 3vh 0vh 0.39vh 0vh;
`;

const GroupedContainer = styled.div`
  margin: 0.37vh 0vh;
`;

// Using this div instead of padding because of IE/FF bug
// https://bugzilla.mozilla.org/show_bug.cgi?id=748518
const BottomPadding = styled.div`
  height: 2.46vh;
`;

const fadeOutDown = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0.06;
    transform: translateY(60px);
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0.06;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MessagesContainer = styled.div`
  position: relative;
  flex-grow: 1;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 10px;
`;

const MessagesScroller = styled.div`
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  transform: translate3d(0, 0, 0);
  padding: 0 4.3vw 0 4.3vw;
  @media ${BreakPoints.tablet} {
    padding: 0px 30px 20px 30px;
  }

  &.in {
    animation: ${fadeInUp} 0.25s;
    animation-fill-mode: both;
    animation-delay: 0.2s;
  }

  &.out {
    animation: ${fadeOutDown} 0.25s;
    animation-fill-mode: forwards;
  }

  &.hidden {
    opacity: 0.06;
  }
`;

const MessageBoxContainer = styled.div`
  @media ${BreakPoints.tablet} {
    padding: 0 20px 0 20px;
  }
  padding: 0 10px 0 10px;
  flex-shrink: 0;
`;

type OutProps = {
  timelineItems: TimelineItem<any>[];
  timelineId: string;
  onSendMessage: SendMessage;
  shouldImageActAsAnchor: boolean;
  showTypingIndicator: boolean;
};
type Props = OutProps & {
  isAriaLiveEnabled: boolean;
  setIsAriaLiveEnabled: (string) => void;
};

const isLastItemOnline = (timelineItems) => {
  return (
    timelineItems &&
    timelineItems.length > 0 &&
    timelineItems[timelineItems.length - 1].isOnlineMessage
  );
};

class Timeline extends React.Component<Props> {
  messageSectionDiv: HTMLDivElement;

  componentDidUpdate(prevProps) {
    if (
      (this.props.timelineItems &&
        prevProps.timelineItems.length !== this.props.timelineItems!.length) ||
      this.props.showTypingIndicator !== prevProps.showTypingIndicator
    ) {
      if (
        !this.props.isAriaLiveEnabled &&
        isLastItemOnline(this.props.timelineItems)
      ) {
        setTimeout(() => {
          this.props.setIsAriaLiveEnabled(true);
        }, 1000);
      }
      this.messageSectionDiv.scrollTop = this.messageSectionDiv.scrollHeight;
    }
  }

  renderItem(item: TimelineItem<any>, props, index) {
    const { timelineItems } = this.props;

    const isGrouped = _isGrouped(prev(timelineItems, index), item);

    const Component: React.ComponentType<
      any
    > | null = timelineItemTypeToComponent(item);
    const Container = isGrouped ? GroupedContainer : ItemContainer;

    return Component ? (
      <Container key={getTimelineItemKey(item)}>
        <Component
          {...props}
          {...item}
          testID={item.contentId}
          isGrouped={isGrouped}
        />
      </Container>
    ) : null;
  }

  render() {
    const {
      timelineItems,
      timelineId,
      onSendMessage,
      shouldImageActAsAnchor,
      showTypingIndicator,
      children,
      isAriaLiveEnabled,
    } = this.props;
    const triggerScrollDown = () => {
      if (this.messageSectionDiv) {
        this.messageSectionDiv.scrollTop = this.messageSectionDiv.scrollHeight;
      }
    };

    return (
      <Container id="timelineContainer">
        <MessagesContainer id="messagesContainer" aria-label="timeline">
          <MessagesScroller
            aria-live={isAriaLiveEnabled ? 'polite' : 'off'}
            role="main"
            aria-label="chat transcript"
            ref={(messageSectionDiv) =>
              (this.messageSectionDiv = messageSectionDiv as HTMLDivElement)
            }
          >
            {timelineItems!.map((item, i) => {
              return this.renderItem(
                item,
                { shouldImageActAsAnchor, timelineId, triggerScrollDown },
                i
              );
            })}
            {children}
            {showTypingIndicator && <TypingIndicator />}
            <BottomPadding />
          </MessagesScroller>
        </MessagesContainer>
        <MessageBoxContainer
          id="messageBoxContainer"
          role="form"
          aria-label="interactions panel"
        >
          <MessageBox timelineId={timelineId} onSendMessage={onSendMessage} />
        </MessageBoxContainer>
      </Container>
    );
  }
}

export default compose<Props, OutProps>(
  withState('isAriaLiveEnabled', 'setIsAriaLiveEnabled', false),
  setDisplayName('Timeline')
)(Timeline);
