type BreakPoints<T = string> = {
  desktop: T;
  tablet: T;
  tabletBig: T;
  mobile: T;
  mobileBig: T;
};

export const BREAK_POINTS_DIMENSIONS_VALUES_IN_PX: BreakPoints<number> = {
  desktop: 1024,
  tabletBig: 900,
  tablet: 768,
  mobileBig: 481,
  mobile: 0,
};

// @ts-ignore
const BREAK_POINTS: BreakPoints = Object.entries(
  BREAK_POINTS_DIMENSIONS_VALUES_IN_PX
).reduce((a, [key, value]) => {
  a[key] = `(min-width: ${value}px)`;
  return a;
}, {});

export default BREAK_POINTS;
