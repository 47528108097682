import omit from 'ramda.omit';
import {
  branch,
  ComponentEnhancer,
  compose,
  lifecycle,
  mapProps,
  renderNothing,
  withState,
} from 'recompose';

export function withDelay<TProps>(): ComponentEnhancer<TProps, TProps> {
  type InnerProps = TProps & {
    visible: boolean;
    setVisibility: (visibility: boolean) => void;
  };

  return compose<TProps, TProps>(
    withState('visible', 'setVisibility', true),
    lifecycle<
      InnerProps,
      {},
      {
        _delayTimerRef: number | undefined;
      }
    >({
      _delayTimerRef: undefined,
      async componentDidMount() {
        const { setVisibility } = this.props;
        const hasSeenSurveyMessage = false;
        setVisibility(true);
        if (!hasSeenSurveyMessage) {
          this._delayTimerRef = setTimeout(() => {
            setVisibility(true);
          }, 500);
        } else {
          setVisibility(true);
        }
      },
      componentWillUnmount() {
        clearTimeout(this._delayTimerRef);
      },
    }),
    branch(({ visible }: InnerProps) => !visible, renderNothing),
    mapProps((props) => omit(['visible', 'setVisibility'], props))
  );
}
