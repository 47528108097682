import { performanceTimeline } from '@soluto-home-web/platform-utils';

import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/repeat';
import 'rxjs/add/operator/timeout';
import { Observable } from 'rxjs/Observable';
import { defer } from 'rxjs/observable/defer';
import { of } from 'rxjs/observable/of';

export const firstPaintMarkObservable = (): Observable<PerformanceEntry> => {
  return defer(() => of(performanceTimeline.getLastMeasurement('first-paint')))
    .delay(300)
    .repeat()
    .filter((x) => x !== null)
    .map<PerformanceEntry | null, PerformanceEntry>((x) => x!)
    .timeout(4500)
    .take(1);
};
