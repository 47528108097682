import { identity } from '@soluto-home-web/platform-identity';
import HomeUser from '@soluto-home-web/platform-identity/identity/HomeUser';
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { branch, compose, mapProps, withHandlers } from 'recompose';
import { sendItem } from '../firebase/timelineApi';
import withMessagingApiSendMessage from '../messaging-api/with-send-message';
import withMessagingFallbackSendMessage from '../messaging-fallback/with-send-message';
import itemFromText from '../utils/createTimelineItemFromText';
import MessagingProviderType from '../utils/MessagingProviderType';
import withSessionId from './withSessionId';
import { getTimelineContext } from './withTimelineContext';

export type SendMessage = (
  message: string,
  customExpertise?: string
) => Promise<void>;

type InputProps = {
  timelineId: string;
  requiredExpertise?: string;
  requiredLanguageExpertise?: string;
  messagingPartner: string;
};

type OutputProps = {
  sendMessage: SendMessage;
};

export default compose<InputProps, OutputProps>(
  getTimelineContext,
  withSessionId,
  withTweekKeys('support/session/messaging/provider', {
    propName: 'messagingProvider',
  }),
  withTweekKeys('soluto_home_web/messaging/expertise', {
    propName: 'requiredExpertise',
    initialValue: '',
  }),
  withTweekKeys('soluto_home_web/messaging/language_expertise', {
    propName: 'requiredLanguageExpertise',
    initialValue: '',
  }),
  branch<{ messagingProvider: string }>(
    ({ messagingProvider }) =>
      messagingProvider === MessagingProviderType.MessagingApi,
    compose(withMessagingApiSendMessage),
    branch<{ messagingProvider: string }>(
      ({ messagingProvider }) =>
        messagingProvider === MessagingProviderType.Fallback,
      compose(withMessagingFallbackSendMessage),
      withHandlers<any, OutputProps>({
        sendMessage: ({
          timelineId,
          messagingPartner,
          requiredExpertise,
          requiredLanguageExpertise,
        }) => async (text, customExpertise) => {
          const homeUser: HomeUser = identity.get();
          const item = itemFromText(
            text,
            homeUser.id,
            messagingPartner,
            customExpertise || requiredExpertise,
            requiredLanguageExpertise
          );
          await sendItem(timelineId, item);
        },
      })
    )
  ),
  mapProps(({ sessionId, ...other }) => other)
);
