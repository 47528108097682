import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import {
  ButtonWithAnalyticProps,
  ButtonWithClickAnalytic,
} from './ButtonWithClickAnalytic';

const Button = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  height: 55px;
  width: 310px;
  border-radius: 28px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.foreground};
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }
`;

export const PartnerButton: React.FunctionComponent<
  ButtonWithAnalyticProps
> = ButtonWithClickAnalytic(Button);
