import { logger } from '@soluto-home-web/platform-logger';
import * as decodeJwt from 'jwt-decode';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/startWith';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { get, IS_LOGIN_KEY, remove, update, updated$ } from './crud';
import HomeUser from './HomeUser';
import homeUserApiClient from './homeUserApiClient';
import { clear as clearTokens, getToken, setToken } from './tokenManager';

const HOME_USER_TOKEN_KEY = 'home_user_token';

export const isTokenValid = (token: any): boolean => {
  if (!token) {
    return false;
  }

  const payload = decodeJwt(token);
  const tokenExpDate = new Date(0);
  tokenExpDate.setUTCSeconds(payload.exp);

  return tokenExpDate.getTime() > Date.now();
};

export async function logIn(token: string, mdn?: string): Promise<void> {
  const payload = decodeJwt(token);

  try {
    setToken(HOME_USER_TOKEN_KEY, token);

    const homeUserId =
      payload.claims && payload.claims.HomeUserId
        ? payload.claims.HomeUserId
        : payload.sub;
    const currentHomeUser = get();
    if (currentHomeUser && currentHomeUser.id !== homeUserId) {
      const homeUser: HomeUser | null = await homeUserApiClient.fetchByHomeUserId(
        token,
        homeUserId
      );
      if (!homeUser) {
        throw 'Failed to get home user - user have a token but he is not logged in';
      }
      if (mdn) {
        homeUser.mdn = mdn;
      }
      update(homeUser);
    } else {
      update(currentHomeUser);
    }
  } catch (e) {
    const errorText = e.response ? await e.response.text() : '';
    logger.error('Failed on LoginSession.logIn', e, {
      HomeUserId:
        payload &&
        (payload.HomeUserId || (payload.claims && payload.claims.HomeUserId)),
      statusCode: e.response && e.response.status,
      responseText: errorText,
    });
  }
}

export function isLoggedIn(): boolean {
  if (!isTokenValid(getToken(HOME_USER_TOKEN_KEY))) {
    if (getToken(HOME_USER_TOKEN_KEY)) {
      //token expired
      logOut();
    }

    return false;
  }

  return true;
}

export function logOut(): void {
  const current = get();
  clearTokens();
  remove();
  loggedOut$.next(current);
}

export const loggedOut$ = new Subject<HomeUser>();
export const getHomeUserAccessToken = () => {
  if (isLoggedIn()) {
    return getToken(HOME_USER_TOKEN_KEY);
  }

  return undefined;
};

const isLoggedInStorageUpdate$ = Observable.create((o) => {
  const handler = (e) => {
    if (
      e.key &&
      typeof e.key === 'string' &&
      e.key.includes(IS_LOGIN_KEY) &&
      e.newValue
    ) {
      const value = e.newValue === 'true';
      o.next(value);
    }
  };

  window.addEventListener('storage', handler);
  return () => window.removeEventListener('storage', handler);
});

export const isLoggedIn$ = isLoggedInStorageUpdate$
  .merge(updated$.map(() => true))
  .merge(loggedOut$.map(() => false))
  .startWith(isLoggedIn());
