import { logger } from '@soluto-home-web/platform-logger';
import { TimelineItem } from '@soluto-home-web/timeline-core';
import * as React from 'react';
import {
  branch,
  compose,
  lifecycle,
  renderNothing,
  withHandlers,
  withState,
} from 'recompose';
import { AnalyticsDispatcher } from 'shisell';
import { TwoStepResolutionContent } from '../../TwoStepResolutionContentType';
import TeamMessage from '../TeamMessage';
import UserMessage from '../UserMessage';
import AssistanceButtons from './AssistanceButtons';

type IssueNotResolvedProps = TimelineItem<TwoStepResolutionContent> & {
  retry: boolean;
  issueNotResolvedMessagesToShow: number;
  expertBrandingName: string;
  assistanceNeededAnswer: string;
  setIssueNotResolvedMessagesToShow: (newMessages: number) => void;
  isPending: boolean;
};

type InnerProps = IssueNotResolvedProps & {
  onNoClick: () => void;
  onYesClick: () => void;
  retry: boolean;
  setRetry(retry: boolean): { retry: boolean };
  analytics: AnalyticsDispatcher;
  setAssistanceNeededAnswer(
    assistanceNeededAnswer: string
  ): { assistanceNeededAnswer: string };
  setIsPending(isPending: boolean): { withPersistencyProps: boolean };
  reenterQueue(): void;
};

const IssueNotResolved = (props: InnerProps) => {
  const {
    retry,
    assistanceNeededAnswer,
    onNoClick,
    onYesClick,
    isPending,
    issueNotResolvedMessagesToShow,
  } = props;

  return (
    <div>
      {issueNotResolvedMessagesToShow > 0 && (
        <TeamMessage
          {...props}
          isGrouped={true}
          message={
            'Sorry to hear... we’d like to get your issue resolved as soon as possible. Do you want another expert to take it from here?'
          }
        />
      )}
      {issueNotResolvedMessagesToShow === 2 && (
        <TeamMessage
          {...props}
          isGrouped={true}
          message={'Do you want another expert to take it from here?'}
        />
      )}

      {retry && (
        <TeamMessage
          {...props}
          isGrouped={true}
          message={
            'It looks like something has gone wrong. If you still need help from one of our experts, please send us a message.'
          }
        />
      )}

      {!assistanceNeededAnswer ? (
        issueNotResolvedMessagesToShow === 1 && (
          <AssistanceButtons
            retry={retry}
            onNoClick={onNoClick}
            onYesClick={onYesClick}
            isPending={isPending}
          />
        )
      ) : (
        <UserMessage
          {...props}
          message={
            assistanceNeededAnswer === 'No' ? 'No thanks' : 'Yes, please'
          }
        />
      )}
    </div>
  );
};

const enhance = compose<InnerProps, IssueNotResolvedProps>(
  withState('retry', 'setRetry', false),
  withHandlers({
    onYesClick: ({
      analytics,
      setRetry,
      setAssistanceNeededAnswer,
      reenterQueue,
      setIsPending,
    }: InnerProps) => async () => {
      try {
        setIsPending(true);
        await reenterQueue();
        setAssistanceNeededAnswer('Yes');
        analytics.dispatch('Accept_Enter_Queue_Click');
        setIsPending(false);
      } catch (error) {
        logger.error('failed reentering queue', error);
        setRetry(true);
        analytics.withExtras({ error }).dispatch('Error_Entering_Queue_View');
      }
    },
    onNoClick: ({
      triggerStarSurvey,
      analytics,
      setAssistanceNeededAnswer,
      setFlowEndedMessagesToShow,
    }) => async () => {
      setFlowEndedMessagesToShow(0);
      setAssistanceNeededAnswer('No');
      analytics.dispatch('Decline_Enter_Queue_Click');

      try {
        analytics.dispatch('Send_Star_Rating_From_Two_Step_Res_No');
        setTimeout(async () => await triggerStarSurvey(), 1100);
      } catch (error) {
        logger.error('failed sending star survey', error);
        analytics
          .withExtras({ error })
          .dispatch('Error_Send_Star_Rating_From_Two_Step_Res_No');
      }
    },
  }),
  lifecycle<
    IssueNotResolvedProps,
    {},
    { _issueNotResolvedTimerRef: number | undefined }
  >({
    _issueNotResolvedTimerRef: undefined,
    componentDidMount() {
      this._issueNotResolvedTimerRef = setTimeoutForNextMessage(this.props);
    },
    componentDidUpdate() {
      this._issueNotResolvedTimerRef = setTimeoutForNextMessage(this.props);
    },
    componentWillUnmount() {
      clearTimeout(this._issueNotResolvedTimerRef);
    },
  })
);

const withExpiration = branch(
  ({ isExpired, resolutionAnswer, assistanceNeededAnswer }) =>
    resolutionAnswer !== 'No' || (isExpired && !assistanceNeededAnswer),
  renderNothing,
  enhance
);

export default withExpiration(IssueNotResolved);

function setTimeoutForNextMessage(props: IssueNotResolvedProps) {
  const {
    issueNotResolvedMessagesToShow,
    setIssueNotResolvedMessagesToShow,
  } = props;
  if (issueNotResolvedMessagesToShow < 1) {
    setIssueNotResolvedMessagesToShow(issueNotResolvedMessagesToShow + 1);
  }
  return undefined;
}
