import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import {
  branch,
  compose,
  getContext,
  mapPropsStreamWithConfig,
  withProps,
} from 'recompose';
import { messagingContextTypes } from '../messaging-api/types';
import rxjsconfig from '../utils/rxjsconfig';

type WithTypingProps = {
  onInputCompleted: () => void;
  onInputChanged: () => void;
};

const mapPropsStream = mapPropsStreamWithConfig(rxjsconfig);

export const withTypingBehavior = compose(
  withTweekKeys('support/session/messaging/is_typing/is_enabled', {
    propName: 'isTypingEnabled',
  }),
  branch(
    ({ isTypingEnabled }) => isTypingEnabled,
    compose(
      getContext(messagingContextTypes),
      branch(
        ({ messagingClient }) => messagingClient,
        withProps<any, any>(({ messagingClient, timelineId }) => {
          return {
            onInputCompleted: () => null,
            onInputChanged: (_) => messagingClient.typing(timelineId),
          };
        })
      )
    ),
    withProps<WithTypingProps, any>(() => ({
      onInputCompleted: () => null,
      onInputChanged: () => null,
    }))
  )
);

export const withShowTypingIndicator = compose<any, any>(
  withTweekKeys('support/session/messaging/is_typing/display_indicator', {
    propName: 'displayTypingIndicatorEnabled',
  }),
  branch(
    ({ displayTypingIndicatorEnabled }) => displayTypingIndicatorEnabled,
    compose(
      getContext(messagingContextTypes),
      branch(
        ({ messagingClient }) => messagingClient,
        mapPropsStream((props$: any) => {
          const isTyping$ = props$
            .switchMap(({ messagingClient, timelineId }) =>
              messagingClient.getTypingForRoom(timelineId)
            )
            .startWith(false);

          return props$.combineLatest(isTyping$, (props, isTyping) => ({
            ...props,
            showTypingIndicator: isTyping,
          }));
        })
      )
    )
  )
);
