import { IValueRedactor } from './IValueRedactor';

const MDNMatcher = /\+?[0-9]{10,}/;

class MdnRedactor implements IValueRedactor {
  shuoldUseRedactor(key: string, value: string): boolean {
    return typeof value === 'string';
  }

  redact(key: string, value: any): any {
    return typeof value === 'string'
      ? value.replace(MDNMatcher, '##MDN redacted##')
      : value;
  }
}

export default MdnRedactor;
