import axios, { AxiosInstance } from 'axios';
import { IMonitorWriter } from './monitorWriter';

const PLATFORM_NAME = 'SolutoHomeWeb';

export class SolutoMonitorWriter implements IMonitorWriter {
  client: AxiosInstance;
  constructor(baseUrl: string) {
    this.client = axios.create({
      timeout: 5000,
      baseURL: baseUrl,
    });
  }

  async updateValue(metricName: string, value: number) {
    try {
      await this.client.post(
        `api/v1/metrics/updatevalue/${PLATFORM_NAME}/${
          globals.REACT_APP_PARTNER
        }_${metricName}?value=${value}`
      );
    } catch {}
  }

  async increment(metricName: string) {
    try {
      await this.client.post(
        `api/v1/metrics/increment/${PLATFORM_NAME}/${
          globals.REACT_APP_PARTNER
        }_${metricName}`
      );
    } catch {}
  }

  async addTime(metricName: string, value: number) {
    try {
      await this.client.post(
        `api/v1/metrics/addtime/${PLATFORM_NAME}/${
          globals.REACT_APP_PARTNER
        }_${metricName}?value=${value}`
      );
    } catch {}
  }
}
