import * as forEachObjIndexed from 'ramda.foreachobjindexed';
import { IValueRedactor } from './IValueRedactor';

export const redactArrayValues = <T>(
  obj: Array<T>,
  redactor: IValueRedactor
): Array<T> => {
  return <Array<T>> redactObjectValues<T>(obj, redactor);
};

export const redactObjectValues = <T>(
  obj: object | Array<T>,
  redactor: IValueRedactor
): object | Array<T> => {
  const objectIterator = (value, key, obj) => {
    if (typeof value === 'object') {
      obj[key] = forEachObjIndexed(objectIterator, value);
    }
    if (redactor.shuoldUseRedactor(key, value)) {
      obj[key] = redactor.redact(key, value);
    }

    return obj;
  };

  return forEachObjIndexed(objectIterator, obj);
};
