// logic for this class is described in docs/messaging/token-resolution-diagram.png
import { authenticationApiClient } from '@soluto-home-web/platform-auth';
import { identity } from '@soluto-home-web/platform-identity';
import { logger } from '@soluto-home-web/platform-logger';
import { queryString } from '@soluto-home-web/platform-utils';
import { withRouter } from '@soluto-home-web/platform-utils';
import * as decodeJwt from 'jwt-decode';
import { RouteComponentProps } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';
import {
  getStoreItem,
  removeStoreItem,
  TimelineStoreValues,
} from '../core/store';
import provider from '../firebase/firebaseProvider';

type InputProps = RouteComponentProps<any> & {
  setTimelineId: (string) => void;
  timelineId: string;
  token: string;
};

function redirectToPage(
  reason: string,
  history: any,
  path: string,
  replace: boolean = false,
  redirectTo?: string
): void {
  const hideHeader = redirectTo
    ? redirectTo.indexOf('hide_header') > -1
    : false;

  history[replace ? 'replace' : 'push']({
    pathname: path,
    state: { reason, redirectTo, hideHeader },
  });
}

const getTokenFromState = (location: any): string | null =>
  location && location.state && location.state.token
    ? location.state.token
    : null;

const updateHomeUserFlow = () => {
  const flow = getStoreItem(TimelineStoreValues.flowId);
  if (flow) {
    const homeUser = identity.get();
    homeUser.flowId = flow;
    identity.update(homeUser);
    removeStoreItem(TimelineStoreValues.flowId);
  }
};

function sleep(ms): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const verifyAndLogin = async (
  jwt: string,
  setTimelineId: any
): Promise<boolean> => {
  try {
    const payload = decodeJwt(jwt);
    if (payload.exp && Date.now() > payload.exp * 1000) {
      return false;
    }
    if (!payload.uid) {
      return false;
    }
    await provider.signIn(jwt!, payload.uid);
    setTimelineId(payload.uid);
    performance.mark('signed in to firebase successfully');
    updateHomeUserFlow();

    return true;
  } catch (err) {
    throw {
      message: 'error occurred in verifyAndLogin',
      innerException: err,
      extraData: { component: 'withFirebaseLogin' },
    };
  }
};

export default compose(
  withRouter,
  lifecycle<InputProps, InputProps>({
    componentWillUnmount() {
      provider.signOut(this.props.timelineId);
    },
    async componentWillMount() {
      let jwt: string | null = null;
      const temporaryCode = queryString.get().code;

      if (temporaryCode) {
        try {
          jwt = await authenticationApiClient.homeUserMessagingTokenFromTemporaryCodeGrant(
            temporaryCode
          );
          if (await verifyAndLogin(jwt, this.props.setTimelineId)) {
            identity.setToken('messaging-token', jwt);
            return;
          } else {
            logger.info('homeUserMessagingTokenFromTemporaryCodeGrant failed');
          }
        } catch (error) {
          logger.info('Login verification failed - temporaryCode', {
            component: 'withFirebaseLogin',
            error,
          });
        } finally {
          window.history.replaceState(
            null,
            '',
            `${window.location.pathname}${queryString.remove('code')}`
          );
        }

        redirectToPage(
          'UnknownError',
          this.props.history,
          '/askmyexpert/messaging-error',
          true,
          this.props.location.pathname + this.props.location.search
        );

        return;
      }

      jwt = getTokenFromState(this.props.location);
      try {
        if (jwt && (await verifyAndLogin(jwt, this.props.setTimelineId))) {
          return;
        }
      } catch (error) {
        logger.info('Login verification failed - getTokenFromState', {
          component: 'withFirebaseLogin',
          error,
        });
      }

      jwt = identity.getToken(provider.TOKEN_NAME);
      try {
        if (jwt && (await verifyAndLogin(jwt, this.props.setTimelineId))) {
          return;
        }
      } catch (error) {
        logger.info('Login verification failed - getTokenFromIdentity', {
          component: 'withFirebaseLogin',
          error,
        });
      }

      if (identity.isLoggedIn()) {
        try {
          const jwt = await authenticationApiClient.messagingTokenFromHomeUserAccessToken(
            identity.getHomeUserAccessToken()! //token must exist if user is logged in
          );
          if (await verifyAndLogin(jwt, this.props.setTimelineId)) {
            identity.setToken('messaging-token', jwt);
            return;
          } else {
            logger.warn('messagingTokenFromHomeUserAccessToken failed');
          }
        } catch (err) {
          logger.info(
            'Login verification failed - messagingTokenFromHomeUserAccessToken',
            {
              component: 'withFirebaseLogin',
              err,
            }
          );

          redirectToPage(
            'UnknownLoginError',
            this.props.history,
            '/askmyexpert/messaging-error'
          );

          return;
        }
      }

      redirectToPage(
        'NoCode',
        this.props.history,
        '/askmyexpert/messaging-error',
        true,
        this.props.location.pathname + this.props.location.search
      );
    },
  })
);
