import {
  isTimelineItemNotSupportedOnMessagingApi,
  Message,
  transformMessageToTimelineItem,
} from '@npmsoluto/messaging-contracts';
import SocketClient, {
  InFlightMessage,
  MessageSendingStatus,
} from '@npmsoluto/soluto-messaging-client';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { of } from 'rxjs/observable/of';
import { ReplaySubject } from 'rxjs/ReplaySubject';

import { logger } from '@soluto-home-web/platform-logger';

import { forTimeline, getTimeline } from '../firebase/timelineApi';
import { default as TimelineItem } from '../models/TimelineItem';
import { mergeExistingTimelineItems } from '../utils/mergeExistingTimelineItems';
import MessagingProviderType from '../utils/MessagingProviderType';

export const incomingMessages$: ReplaySubject<Message> = new ReplaySubject<
  Message
>();

const outgoingMessageToTimelineItem = (message): TimelineItem<any> => ({
  ...transformMessageToTimelineItem(message),
  inFlight: message.status === MessageSendingStatus.PendingSend,
  isCurrentlyInDelete: message.status === MessageSendingStatus.PendingDelete,
  isFailedSending: message.status === MessageSendingStatus.Error,
  isCanceled: message.status === MessageSendingStatus.Canceled,
  isOnlineMessage: false,
});

const getOutgoingTimelineItems = (client: SocketClient) =>
  ((client.inFlightMessages as any) as Observable<InFlightMessage>)
    .map(outgoingMessageToTimelineItem)
    .filter(Boolean);

const getCurrentTime = (client: SocketClient): number => {
  try {
    return client.getServerTimestamp();
  } catch (e) {
    logger.warn("Could not get server timestamp, using browser's time");
    return new Date().getTime();
  }
};

const getUnsupportedTimelineItems = (
  timelineId: string,
  showHistory: boolean,
  messagingSupportedTypes?: string[]
): Observable<TimelineItem<any>> =>
  getTimeline(forTimeline(timelineId)!, showHistory)
    .flatMap((m) => m)
    .filter((i) =>
      isTimelineItemNotSupportedOnMessagingApi(
        i as any,
        messagingSupportedTypes
      )
    );

const getSupportedTimelineItems = (
  client: SocketClient,
  roomId: string,
  showHistory: boolean
) => {
  const incomingTimelineItems = client
    .getMessagesForRoom(roomId)
    .do((message) => {
      if (message.senderId !== roomId) {
        incomingMessages$.next(message);
      }
    })
    .share()
    .map(transformMessageToTimelineItem);

  if (showHistory) {
    client
      .requestHistory(roomId)
      .catch((err) =>
        logger.error('Error while requesting room history', err, { roomId })
      );
  }

  return merge<TimelineItem<any>>(
    incomingTimelineItems,
    getOutgoingTimelineItems(client)
  );
};

const getTimelineItems = (
  client: SocketClient,
  timelineId: string,
  showHistory: boolean,
  messagingSupportedTypes?: string[]
) => {
  const timelineMessages = getSupportedTimelineItems(
    client,
    timelineId,
    showHistory
  );
  const currentTime = getCurrentTime(client);
  const timelineItems = timelineMessages
    .merge(
      getUnsupportedTimelineItems(
        timelineId,
        showHistory,
        messagingSupportedTypes
      )
    )
    .map((timelineItem) => markOnlineMessages(timelineItem, currentTime));

  return mergeExistingTimelineItems(timelineItems);
};

const markOnlineMessages = (
  timelineItem: TimelineItem<any>,
  currentTime: number
) => {
  timelineItem.isOnlineMessage =
    (timelineItem.sendTimestamp || timelineItem.timestamp) > currentTime;
  return timelineItem;
};

export default (
  messagingClient,
  timelineId,
  showHistory,
  messagingSupportedTypes?: string[]
) => {
  return getTimelineItems(
    messagingClient,
    timelineId,
    showHistory,
    messagingSupportedTypes
  ).catch((err) => {
    logger.error('Could not fetch customer timeline', err, {
      provider: MessagingProviderType.MessagingApi,
    });

    return of([]);
  });
};
