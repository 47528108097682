import { authenticationApiClient } from '@soluto-home-web/platform-auth';
import { identity } from '@soluto-home-web/platform-identity';
import * as decodeJwt from 'jwt-decode';
import { withHandlers } from 'recompose';

const getTokenFromState = (location: any): string | null =>
  location && location.state && location.state.token
    ? location.state.token
    : null;

const verifyToken = (token: any): boolean => {
  if (!token) {
    return false;
  }

  const payload = decodeJwt(token);

  if (payload.exp && Date.now() > payload.exp * 1000) {
    return false;
  }
  if (!payload.uid) {
    return false;
  }

  return true;
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export default withHandlers({
  getBearerToken: ({ location, timelineId }) => async () => {
    const getToken = async () => {
      let jwt = getTokenFromState(location);

      if (!verifyToken(jwt)) {
        jwt = identity.getToken('messaging-token');
        if (!verifyToken(jwt) && identity.isLoggedIn()) {
          const homeUserAccessToken = identity.getHomeUserAccessToken()!; //token must exist if user is logged in
          jwt = await authenticationApiClient.messagingTokenFromHomeUserAccessToken(
            homeUserAccessToken
          );
        }
      }
      return jwt;
    };

    const tryGet = async () => {
      const token = await getToken();
      if (!token || decodeJwt(token).uid !== timelineId) {
        await sleep(500);

        return await tryGet();
      }

      return token;
    };

    return await tryGet();
  },
});
