import { OutgoingMessage } from '@npmsoluto/soluto-messaging-client';
import uuid from 'uuid';
import { MessageType } from '../messaging-api/types';
import getArrayOfExpertise from './getArrayOfExpertise';

export interface ImagePayload {
  imageUrl?: string;
  thumbnailUrl?: string;
  text?: string;
  imageType?: string;
  imageDimensions?: any;
  state?: string;
}

export const buildOutgoingTextMessage = (
  message: string,
  timelineId: string,
  messagingPartner: string,
  requiredExpertise,
  requiredLanguageExpertise,
  customExpertise
) =>
  ({
    payload: {
      type: MessageType.Text,
      value: message,
    },
    createdAt: Date.now(),
    senderId: timelineId,
    recipientId: timelineId,
    messageId: uuid.v4(),
    metadata: {
      clientName: 'ProTech App',
      partner: messagingPartner,
      expertise: getArrayOfExpertise(
        customExpertise || requiredExpertise,
        requiredLanguageExpertise
      ),
      senderType: 'Device',
    },
  } as OutgoingMessage);

export const buildOutgoingImageItem = (
  payload: ImagePayload,
  timelineId: string,
  messagingPartner: string,
  messageId?: string
) => {
  if (!payload.text) {
    payload.text = '📷 photo';
  }

  return {
    payload: {
      type: MessageType.Image,
      value: payload,
    },
    createdAt: Date.now(),
    senderId: timelineId,
    recipientId: timelineId,
    messageId: messageId || uuid.v4(),
    metadata: {
      partner: messagingPartner,
      senderType: 'Device',
    },
  };
};

export const buildOutgoingVideoItem = (
  timelineId: string,
  contentId: string,
  fullSizeMediaDimensions,
  url,
  state
) => {
  return {
    content: '📹 video',
    updatedAt: Date.now(),
    senderType: 'Device',
    timelineId,
    contentId,
    contentType: 'DynamicComponent',
    url: url,
    imageDimensions: fullSizeMediaDimensions,
    state: state,
  };
};

export const buildTimelineVideoItem = (
  timelineId: string,
  contentId: string,
  fullSizeMediaDimensions,
  mediaUrl,
  thumbnailUrl,
  url,
  state
) => {
  return {
    content: '📹 video',
    updatedAt: Date.now(),
    timelineId,
    contentId,
    senderType: 'Device',
    videoUrl: mediaUrl,
    contentType: 'DynamicComponent',
    thumbnailUrl,
    imageDimensions: fullSizeMediaDimensions,
    state: state,
    url: url,
  };
};
