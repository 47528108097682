import { branch, compose, getContext, withHandlers } from 'recompose';
import { MessageType } from '../messaging-api/types';
import { buildMessage } from '../messaging-api/with-send-message';
import { ImagePayload } from '../utils';
import { messagingFallbackContextTypes } from './types';

interface SendMessageFallbackProps {
  messagingFallbackClient: any;
  message: string | ImagePayload;
  timelineId: string;
  messagingPartner: string;
  requiredExpertise?;
  requiredLanguageExpertise?;
  customExpertise?;
  messageType?: string;
}

const withSendMessage = compose(
  getContext(messagingFallbackContextTypes),
  branch(
    ({ messagingFallbackClient }) => messagingFallbackClient,
    withHandlers<any, any>({
      sendMessage: ({
        messagingFallbackClient,
        messageType = MessageType.Text,
        ...props
      }: SendMessageFallbackProps) => async (message, customExpertise) => {
        const outgoingMessage = buildMessage({
          ...props,
          message,
          customExpertise,
        });

        await messagingFallbackClient.send(outgoingMessage);
      },
    })
  )
);

export default withSendMessage;
