import { styled } from '@soluto-home-web/web-theme';
import React from 'react';
import PrimaryButton from './PrimaryButton';

type RetryButtonProps = { onYesClick: () => void };
export const RetryButton = ({ onYesClick }: RetryButtonProps) => (
  <PrimaryButton title="Try again" onPress={onYesClick} />
);

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 24px 0px;
`;

const Seperator = styled.div`
  margin-right: 10px;
`;

type ButtonsContainerProps = {
  yesButtonTitle: string;
  yesButtonChild?: any;
  onYesClick: () => void;
  noButtonTitle: string;
  noButtonChild?: any;
  onNoClick: () => void;
  isPending?: boolean;
};

export const DecisionButtons: React.FunctionComponent<
  ButtonsContainerProps
> = ({
  yesButtonTitle,
  yesButtonChild,
  onYesClick,
  noButtonTitle,
  noButtonChild,
  onNoClick,
  isPending,
}: ButtonsContainerProps) => (
  <div>
    {!isPending && (
      <ButtonsWrapper>
        <PrimaryButton title={yesButtonTitle} onPress={onYesClick}>
          <span>{yesButtonChild}</span>
        </PrimaryButton>
        <Seperator />
        <PrimaryButton title={noButtonTitle} onPress={onNoClick}>
          <span>{noButtonChild}</span>
        </PrimaryButton>
      </ButtonsWrapper>
    )}
  </div>
);
