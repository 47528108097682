import { disableScroll } from '@soluto-home-web/platform-common-ui';
import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { compose, withHandlers } from 'recompose';

export type OverlayProps = {
  onBackgroundClick?: () => void;
};

type EnhancedProps = OverlayProps & {
  clicked: (any) => void;
};

const Container = styled.div`
  position: fixed;
  z-index: 2001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: ${(props) => props.theme.colors.page_overlay};
`;

const CenterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  cursor: auto;
`;

const PageOverlay: React.FunctionComponent<EnhancedProps> = ({
  children,
  clicked,
}) => (
  <Container>
    <CenterContainer onClick={clicked}>{children}</CenterContainer>
  </Container>
);

export default compose<EnhancedProps, OverlayProps>(
  withHandlers({
    clicked: ({ onBackgroundClick }) => (e) => {
      if (e.currentTarget === e.target && onBackgroundClick) {
        onBackgroundClick();
      }
    },
  }),
  disableScroll<OverlayProps>()
)(PageOverlay);
