import { TimelineItem } from '@soluto-home-web/timeline-core';
import asTimelineItem from '@soluto-home-web/timeline/hoc/asTimelineItem';
import React, { lazy, Suspense } from 'react';
import { useEffect, useState } from 'react';
import { enrichAnalytics } from 'react-shisell';
import { compose } from 'recompose';
import { AnalyticsDispatcher } from 'shisell';
import styled from 'styled-components';

const LottieAnimationViewer = lazy(() =>
  import(/* webpackChunkName: "lottie-viewer" */ '@soluto-home-web/lottie-animation-viewer')
);

const axios = require('axios');

type LottieContent = {
  lottieAnimationUrl: string;
};

type OutterProps = TimelineItem<LottieContent> & {
  sessionId: string;
};

type CreatedProps = AnalyticsDispatcher;

type InnerProps = OutterProps & CreatedProps;

const getAnimationOptions = (json) => ({
  animationData: json,
  renderer: 'svg',
  loop: true,
  autoplay: true,
  rendererSettings: {
    scaleMode: 'noScale',
  },
});

export const MessageContainer = styled.span`
  padding: 0.3em;
  border-radius: 4px;
  border: solid 8px #f4f4f4;
  color: #333333;
  display: inline-block;
  min-width: 10px;
  line-height: 15px;
  max-width: 100%;
`;

const Loading = () => <>LOADING</>;

const LottieViewer = (props: InnerProps) => {
  const [lottieJson, setLottieJson] = useState<{ data: object }>({ data: {} });

  useEffect(() => {
    axios.get(props.content.lottieAnimationUrl).then((json) => {
      setLottieJson(json);
    });
  }, []);

  if (lottieJson.data['layers']) {
    return (
      <MessageContainer>
        <Suspense fallback={Loading}>
          <LottieAnimationViewer
            animationOptions={getAnimationOptions(lottieJson.data)}
            style={{}}
            onAnimationLoaded={() => {}}
          />
        </Suspense>
      </MessageContainer>
    );
  }

  return null;
};

const enhance = compose<InnerProps, OutterProps>(
  enrichAnalytics<OutterProps>((dispatcher, props) =>
    dispatcher
      .createScoped('Second_Screen')
      .withExtras({ sessionId: props.sessionId, flowId: 'second_screen' })
  )
)(LottieViewer);

export default asTimelineItem(enhance);
