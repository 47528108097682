import { logger } from '@soluto-home-web/platform-logger';
import { userAgentParser } from '@soluto-home-web/platform-utils';
import monitoringWriter from './writers/monitorWriter';

export type MeasureTimeCallback = {
  monitor: (message: string) => Promise<void>;
};

const getElapsedTime = (startMeasuringTime: number) =>
  new Date().getTime() - startMeasuringTime;

const normalizeMetricName = (metricName: string) =>
  metricName.replace(/\./g, '_');

const updateValue = async (metricName: string, value: number) => {
  metricName = normalizeMetricName(metricName);
  await monitoringWriter.updateValue(metricName, value);
};

const addTime = async (metricName: string, value: number) => {
  metricName = normalizeMetricName(metricName);
  logger.info('timer sent to logging-api-new', {
    metricName: metricName,
    value: value,
  });
  await monitoringWriter.addTime(metricName, value);
};

function startMeasureTime(): MeasureTimeCallback {
  var startMeasuringTime = new Date().getTime();

  return {
    monitor: async (message: string) =>
      await updateValue(message, getElapsedTime(startMeasuringTime)),
  };
}

async function increment(metricName: string): Promise<void> {
  metricName = normalizeMetricName(metricName);
  await monitoringWriter.increment(metricName);
}

const incrementByOs = async (metricName: string) => {
  metricName = `${metricName}'_'${userAgentParser.getOs()}`;
  await increment(metricName);
};

const incrementByBrowserVersion = async (metricName: string) => {
  metricName = `${metricName}_${userAgentParser.getBrowserName()}_${userAgentParser.getBrowserVersion()}`;
  await increment(metricName);
};

export default {
  startMeasureTime,
  updateValue,
  increment,
  incrementByOs,
  incrementByBrowserVersion,
  addTime,
};
