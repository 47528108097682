import React from 'react';
import { Route } from 'react-router-dom';

const withRouter = (ConnectedComponent) => {
  const withRouterComponent = (props) => (
    <Route
      render={(routeProps) => <ConnectedComponent {...routeProps} {...props} />}
    />
  );
  return withRouterComponent;
};

export default withRouter;
