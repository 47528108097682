export default function (url: string): string {
  try {
    const urlParts: string[] = url.split('/');

    const baseUrl: string = (url.startsWith('http')
      ? urlParts.slice(0, 3).pop()
      : urlParts[0]) as string;

    return baseUrl.split('www.').pop() as string;
  } catch (exp) {
    return !!url ? url : '';
  }
}
