import { withAnalytics } from 'react-shisell';
import { compose, lifecycle, mapProps } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { merge } from 'rxjs/observable/merge';
import { AnalyticsDispatcher } from 'shisell';
import {
  chatSessionResolved$,
  chatSessionStarted$,
} from '../firebase/chatSessionApi';

type InputProps = {
  timelineId: string;
};

type Props = InputProps & {
  analytics: any;
};

let subscriptionPerTimelineId = {};

function unsubscribe(timelineId) {
  if (subscriptionPerTimelineId[timelineId]) {
    subscriptionPerTimelineId[timelineId].unsubscribe();
    subscriptionPerTimelineId[timelineId] = undefined;
  }
}

export default compose<InputProps, Props>(
  withAnalytics,
  lifecycle<Props, {}>({
    componentDidUpdate(prevProps: InputProps) {
      const { timelineId, analytics } = this.props;
      if (!timelineId) {
        return;
      }
      if (subscriptionPerTimelineId[timelineId]) {
        return;
      }

      if (prevProps.timelineId) {
        unsubscribe(prevProps.timelineId);
      }

      subscriptionPerTimelineId[timelineId] = merge(
        chatSessionResolved$(timelineId).map((sessionId) => ({
          dispatcher: analytics.dispatcher.withIdentity('SessionId', sessionId),
          eventName: 'ConversationResolved',
        })) as Observable<{
          dispatcher: AnalyticsDispatcher;
          eventName: string;
        }>,
        chatSessionStarted$(timelineId).map((sessionId) => ({
          dispatcher: analytics.dispatcher.withIdentity('SessionId', sessionId),
          eventName: 'ConversationCreated',
        })) as Observable<{
          dispatcher: AnalyticsDispatcher;
          eventName: string;
        }>
      ).subscribe(({ dispatcher, eventName }) =>
        dispatcher.dispatch(eventName)
      );
    },
    componentWillUnmount() {
      unsubscribe(this.props.timelineId);
    },
  }),
  mapProps(({ analytics, ...other }) => other)
);
