import { withAnalyticOnEvent } from 'react-shisell';

export default <T>(element: string, extra?: { [key: string]: any }) =>
  withAnalyticOnEvent<T>({
    eventName: 'onClick',
    analyticName: 'Click',
    extras: Object.assign({}, extra, {
      ActionId: element,
    }),
  });
