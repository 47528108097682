import { logger } from '@soluto-home-web/platform-logger';
import { AnalyticsEventModel } from 'shisell';
import { IAnalyticsWriter } from './IAnalyticsWriter';

class AnalyticsWriter implements IAnalyticsWriter {
  async write(eventModel: AnalyticsEventModel): Promise<void> {
    await fetch(
      'https://prodhome1analyticslistener.mysoluto.com' + '/api/v1/Events/',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventModel),
      }
    ).catch((error) =>
      logger.warning('Could not write analytic to backend', {
        error,
        eventModel,
      })
    );
  }

  isEnabled(): Promise<boolean> {
    return Promise.resolve(true);
  }
}

export default new AnalyticsWriter();
