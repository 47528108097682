import * as uuid from 'uuid';
import getArrayOfExpertise from './getArrayOfExpertise';

type TextItem = {
  senderType: string;
  content: string;
  contentType: 'TextMessage';
  contentId: string;
  selfIdentity: string;
  senderId: string;
  metadata: {
    partner?: string;
    expertise?: string[];
  };
};

export default (
  text: string,
  senderId: string,
  partnerId?: string,
  expertise?: string,
  languageExpertise?: string
): TextItem => {
  const textItem: TextItem = {
    senderType: 'Device',
    content: text,
    contentType: 'TextMessage',
    contentId: uuid.v4(),
    selfIdentity: 'Device',
    senderId,
    metadata: {},
  };

  if (partnerId) {
    textItem.metadata.partner = partnerId;
  }
  if (expertise || languageExpertise) {
    textItem.metadata.expertise = getArrayOfExpertise(
      expertise,
      languageExpertise
    );
  }

  return textItem;
};
