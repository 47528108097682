export default () => {
  const loaderElement: HTMLElement | null = document.querySelector(
    '#outside-loader-container'
  );
  if (loaderElement) {
    loaderElement.style.transition = 'opacity 0.3s linear';
    loaderElement.style.opacity = '0';
    setTimeout(() => {
      loaderElement.parentNode!.removeChild(loaderElement);
      performance.mark('Loader removed');

      // Moves focus to the textInput
      const textInput: HTMLElement | null = document.querySelector(
        '#messageBoxTextArea'
      );
      if (textInput) {
        textInput.focus();
      }
    }, 300);
  }
};
