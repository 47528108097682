/// <reference path="../../../node_modules/@npmsoluto/types.soluto-tweek.theme/Theme.d.ts" />
import * as React from 'react';
import { ThemeProvider as StyledThemeProvider } from '../themed-styled-components';

export type ThemeProps = {
  theme: Theme;
};

const partnerTheme: Theme = require('../theme.json');

type ThemeProviderProps = {
  customTheme?: any;
};

const ThemeProvider: React.FC<ThemeProviderProps> = (props) => (
  <StyledThemeProvider
    theme={{
      ...partnerTheme,
      ...props.customTheme,
    }}
  >
    <>{props.children}</>
  </StyledThemeProvider>
);

export { ThemeProvider };
