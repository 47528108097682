import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';

const Container = styled.div`
  background-color: transparent;
  border-radius: 4px;
  border: 3px solid
    ${(props: { checked: boolean; primaryColor: string }) =>
      props.checked ? props.primaryColor : '#D0D0D0'};
  margin: 6px 10px;
  font-size: 14px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
`;

const Label = styled.span`
  color: #343434;
  user-select: none;
`;

const Selection = ({ checked, primaryColor, label, ...props }) => (
  <Container checked={checked} primaryColor={primaryColor} {...props}>
    <Label>{label}</Label>
  </Container>
);

export default Selection;
