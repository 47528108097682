import { itemTypes } from '@soluto-home-web/timeline-core';
import TextItem from '../../text-item/TextItem';
import DeletedItem from '../components/items/DeletedItem';
import DynamicComponentItem from '../components/items/DynamicComponentItem';
import ImageItem from '../components/items/ImageItem/index';
import ListPickerItem from '../components/items/ListPickerItem';
import TimestampItem from '../components/items/TimestampItem';
import asTimelineItem from '../hoc/asTimelineItem';

const TimelineItemsComponents = {
  [itemTypes.TextMessage]: asTimelineItem(TextItem),
  [itemTypes.ImageItem]: asTimelineItem(ImageItem),
  [itemTypes.Timestamp]: TimestampItem,
  [itemTypes.ListPicker]: asTimelineItem(ListPickerItem),
  [itemTypes.DynamicComponent]: DynamicComponentItem,
};

const DeletedTimelineItem = asTimelineItem(DeletedItem);

const timelineItemTypeToComponent = (item) => {
  if (item.isCanceled) {
    return null;
  }

  switch (item.lastState) {
    case 'deleted':
      return DeletedTimelineItem;
    case 'hidden':
      return null;
    default:
      return TimelineItemsComponents[item.contentType];
  }
};

export default timelineItemTypeToComponent;
