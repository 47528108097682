import { MessageSendingStatus } from '@npmsoluto/soluto-messaging-client';
import {
  buildOutgoingImageItem,
  cancelUpload,
  getTimelineContext,
} from '@soluto-home-web/timeline-core';
import { cancelVideoItem } from '@soluto-home-web/timeline-core/firebase/videoApi';
import { messagingContextTypes } from '@soluto-home-web/timeline-core/messaging-api/types';
import { withAnalytics } from 'react-shisell';
import { compose, getContext, withHandlers } from 'recompose';

export default compose(
  withAnalytics,
  getTimelineContext,
  getContext(messagingContextTypes),
  withHandlers({
    cancelMediaUpload: ({
      contentId,
      timelineId,
      timestamp,
      contentType,
      analytics,
      messagingClient,
      messagingPartner,
      url,
    }) => () => {
      try {
        cancelUpload(contentId);
        if (contentType === 'DynamicComponent' && url === 'VideoItem') {
          cancelVideoItem(timelineId, contentId);
        } else {
          const cancelItem = buildOutgoingImageItem(
            {},
            timelineId,
            messagingPartner,
            contentId
          );
          messagingClient.send(cancelItem, MessageSendingStatus.Canceled);
        }
        const timePassedUntilCancel = Date.now() - timestamp;

        analytics.dispatcher
          .createScoped('Media')
          .withExtra('secondsUntilClicked', timePassedUntilCancel)
          .dispatch('UploadCancel_Click');
      } finally {
        localStorage.removeItem(contentId);
      }
    },
  }),
  withHandlers({
    onKeyUp: ({ cancelMediaUpload }) => (event) => {
      if (event && event.keyCode === 13) {
        cancelMediaUpload();
      }
    },
  })
);
