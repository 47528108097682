import { logger } from '@soluto-home-web/platform-logger';
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import axios from 'axios';
import {
  branch,
  compose,
  getContext,
  withHandlers,
  withProps,
  withState,
} from 'recompose';
import { updateItem } from '../firebase';
import config from '../messaging-api/config';
import { getMessagingApiToken } from '../messaging-api/getMessagingApiToken';
import {
  MessagingClientProps,
  messagingContextTypes,
} from '../messaging-api/types';

type MessageDeleter = (
  timelineId: string,
  messageId: string,
  contentType: string
) => void;

type MessageDeleterProps = {
  deleteMessage: MessageDeleter;
};

export const withDeleteMessageRestApi = <TOutputProps, TInputProps>(
  outputPropsMapper: (outputProps: MessageDeleterProps) => TOutputProps
) =>
  compose<TOutputProps, TInputProps>(
    withProps<TOutputProps, TInputProps>(() =>
      outputPropsMapper({
        deleteMessage: async (timelineId, messageId, contentType) => {
          try {
            const token = await getMessagingApiToken();
            const deleteMessageRequest = axios.create({
              baseURL: config.baseUrl,
              timeout: 8000,
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
                'x-api-key': config.apiKey,
              },
            });
            logger.info('rest api delete message');
            await deleteMessageRequest.post(`/api/v1/message/delete`, {
              roomId: timelineId,
              messageId,
              contentType,
            });
          } catch (error) {
            logger.warn(`Falied delete message ${error.message}`);
          }
        },
      })
    )
  );

export const withDeleteMessageDynamicComponents = <TOutputProps, TInputProps>(
  outputPropsMapper: (outputProps: MessageDeleterProps) => TOutputProps
) =>
  compose<TOutputProps, TInputProps>(
    withProps<TOutputProps, TInputProps>(() =>
      outputPropsMapper({
        deleteMessage: async (timelineId, messageId) => {
          try {
            updateItem(timelineId, 'DynamicComponent', messageId, {
              lastState: 'deleted',
            });
            logger.info('dynamic component delete message');
          } catch (error) {
            logger.warn(`Falied delete message ${error.message}`);
          }
        },
      })
    )
  );

export const withDeleteMessageMessagingApi = <TOutputProps, TInputProps>(
  outputPropsMapper: (outputProps: MessageDeleterProps) => TOutputProps
) =>
  compose<TOutputProps, TInputProps>(
    getContext(messagingContextTypes),
    withProps<TOutputProps, TInputProps & MessagingClientProps>(
      ({ messagingClient }) =>
        outputPropsMapper({
          deleteMessage: (timelineId, messageId) => {
            logger.info('socket delete message');
            messagingClient.delete(timelineId, messageId);
          },
        })
    )
  );

export default compose(
  withTweekKeys('support/session/during/home_web_deletion/is_enabled', {
    propName: 'isDeletionEnabled',
  }),
  withProps(
    (props: {
      fromExpert: boolean;
      isDeletionEnabled: boolean;
      lastState: string;
      state: string;
    }) => ({
      deleteable:
        props.isDeletionEnabled &&
        !props.fromExpert &&
        props.lastState !== 'deleted' &&
        props.state !== 'Uploading',
    })
  ),
  branch(
    ({ deleteable }) => deleteable,
    compose(
      withState('isHover', 'setIsHover', false),
      withState('hoverRef', 'setHoverRef', null),
      withHandlers({
        handleMouseHover: ({ deleteable, setIsHover, setHoverRef }) => () => {
          if (deleteable) {
            const ref = setTimeout(() => setIsHover(true), 300);
            setHoverRef(ref);
          }
        },
        handleMouseOut: ({ setHoverRef, hoverRef, setIsHover }) => () => {
          clearTimeout(hoverRef);
          setIsHover(false);
          setHoverRef(null);
        },
        keyPress: ({ deleteable, setIsHover, isHover }) => (event) => {
          if (deleteable && event && event.key === 'Enter') {
            setIsHover(!isHover);
          }
        },
      })
    )
  )
);
