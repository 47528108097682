import PropTypes from 'prop-types';
import { getContext, withContext } from 'recompose';

// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/28015
const TimelineContextProps = {
  messagingPartner: PropTypes.string.isRequired,
};

type TimelineContextProps = {
  messagingPartner: string;
};

export const setTimelineContext = withContext<
  TimelineContextProps,
  TimelineContextProps
>(TimelineContextProps, () => ({
  messagingPartner:
    globals.REACT_APP_MESSAGING_PARTNER || globals.REACT_APP_PARTNER,
}));

export const getTimelineContext = getContext<TimelineContextProps>(
  TimelineContextProps
);
