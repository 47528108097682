import React from 'react';
import { DecisionButtons } from '../DecisionButtons';

export type AssistanceButtonsProps = {
  retry: boolean;
  onYesClick: () => void;
  onNoClick: () => void;
  isPending: boolean;
};

export default ({
  retry,
  onYesClick,
  onNoClick,
  isPending,
}: AssistanceButtonsProps) =>
  retry ? (
    <></>
  ) : (
    <DecisionButtons
      yesButtonTitle={'Yes, please'}
      noButtonTitle={'No thanks'}
      onYesClick={onYesClick}
      onNoClick={onNoClick}
      isPending={isPending}
    />
  );
