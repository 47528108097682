import { logger } from '@soluto-home-web/platform-logger';
import { getFromTweek } from '@soluto-home-web/platform-tweek';
import ReactPixel from 'react-facebook-pixel';
import { AnalyticsEventModel } from 'shisell';
import { IAnalyticsWriter } from './IAnalyticsWriter';

class ReactPixelWriter implements IAnalyticsWriter {
  private _isEnabled: boolean = false;
  private _isInitialized: boolean = false;

  async isWriterEnabled(): Promise<boolean> {
    const isEnabled = await getFromTweek(
      'soluto_home_web/analytics/facebook_pixel/is_enabled'
    );

    let isWriterEnabled = !!isEnabled === true;
    if (!isWriterEnabled) {
      return false;
    }

    return true;
  }

  async initialize(): Promise<boolean> {
    try {
      const pixelId = await getFromTweek(
        'soluto_home_web/analytics/facebook_pixel/pixel_id'
      );

      if (!pixelId) {
        logger.error(
          'Could not get facebook pixel id for partner',
          new Error()
        );

        return false;
      }

      ReactPixel.init(pixelId);
    } catch {
      return false;
    } finally {
      this._isInitialized = true;
    }

    return true;
  }

  async isEnabled(): Promise<boolean> {
    if (!this._isInitialized) {
      let isInitializationSucceeded = false;
      const isEnabled = await this.isWriterEnabled();

      if (isEnabled) {
        isInitializationSucceeded = await this.initialize();
      }

      this._isEnabled = isEnabled && isInitializationSucceeded;
    }

    return this._isEnabled;
  }

  isPageViewAnalytics(eventModel: AnalyticsEventModel): boolean {
    const extraDataKeys =
      eventModel.ExtraData && Object.keys(eventModel.ExtraData);

    return (
      eventModel.Name === 'PageView' &&
      extraDataKeys &&
      extraDataKeys.indexOf('Page') > -1
    );
  }

  async write(eventModel: AnalyticsEventModel): Promise<void> {
    if (this.isPageViewAnalytics(eventModel)) {
      ReactPixel.pageView();

      return;
    }
  }
}

export default new ReactPixelWriter();
