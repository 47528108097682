import { LogLevel } from '../logger';
import logBugsnag from './logBugsnag';
import logConsole from './logConsole';
import logCountMetricsReporter from './logCountMetricsReporter';
import logLoggingApi from './logLoggingApi';

/**
 * support values are: error, warning, info, debug
 * @memberof logging
 * @enum {number}
 */
export const LOG_LEVELS: { [key in LogLevel]: number } = {
  error: 1,
  warning: 2,
  info: 3,
  debug: 4,
};

let _loggers = !!globals.REACT_APP_IS_E2E
  ? [logConsole]
  : [logCountMetricsReporter, logConsole, logLoggingApi, logBugsnag];

let _maxLogLevel: LogLevel = 'info';

export default (
  levelName: LogLevel,
  message: string,
  error: any,
  extraData: any,
  options?: { [key: string]: any }
) => {
  try {
    if (LOG_LEVELS[_maxLogLevel] < LOG_LEVELS[levelName]) {
      return Promise.resolve();
    }

    return Promise.all(
      _loggers.map((log) => log(levelName, message, error, extraData, options))
    );
  } catch (err) {
    console.warn('failed to log', err.message);

    return;
  }
};
