import withStopTimelineLoader from '@soluto-home-web/timeline-core/hoc/withStopTimelineLoader';
import { BreakPoints, styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { enrichAnalytics } from 'react-shisell';
import { compose, pure } from 'recompose';
import MessagingDisabledAlert from '../components/MessagingDisabledAlert';
import TextBoxImage from '../components/TextBoxImage';

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: space-between;
  .spacer {
    height: 8vh !important;
  }
  
  @media ${BreakPoints.desktop} {
    width: 50%;
    height: 90%;
    margin-left: 25%;
    display: flex;
    align-items: center;
  }
}
`;

const Footer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-content: center;
  width: 100%;
  bottom: 0;
`;

const MessagingDisabled = () => (
  <Container className="no-chat-container">
    <MessagingDisabledAlert />
    <Footer>
      <TextBoxImage />
    </Footer>
  </Container>
);

export default compose(
  enrichAnalytics((dispatcher) => dispatcher.createScoped('Messaging')),
  pure,
  withStopTimelineLoader
)(MessagingDisabled);
