import removeLoader from '@soluto-home-web/web-app/globals/removeLoader';
import { compose, lifecycle } from 'recompose';

let updated = false;

function setRemoveLoaderWithDelay(delay, isTimelineItems = false) {
  if (!updated) {
    updated = isTimelineItems;
    setTimeout(() => {
      removeLoader();
    }, delay);
  }
}

export default compose(
  lifecycle({
    componentDidUpdate(prevProps: any) {
      if (
        prevProps.timelineItems &&
        prevProps.timelineItems.length < this.props.timelineItems.length
      ) {
        setRemoveLoaderWithDelay(300, true);
      } else {
        setRemoveLoaderWithDelay(3000, true);
      }
    },
    componentDidMount() {
      setRemoveLoaderWithDelay(10000);
    },
  })
);
