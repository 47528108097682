performance.mark('TimelineRoute - imported');

import { logger } from '@soluto-home-web/platform-logger';
import { withRouter } from '@soluto-home-web/platform-utils';
import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { match, Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { ThemeProvider } from 'styled-components';
import TimelineTheme from '../timeline-theme/TimelineTheme';
import withThemeFont from './hoc/withThemeFont';
import withThemeToTimelineTheme from './hoc/withThemeToTimelineTheme';
import TimelineLandingPage from './pages/LandingPage';
import MessagingDisabled from './pages/MessagingDisabled';

type OuterProps = {
  match: match<any>;
  theme: TimelineTheme;
  appTheme: Theme;
  history: any;
};

type InnerProps = {
  fontFamily: string;
} & OuterProps;

const Container = styled.div<{ fontFamily: string }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  * {
    font: -apple-system-body;
    font-family: ${(props) => props.fontFamily};
  }
`;

class TimelineRoute extends React.Component<InnerProps, any> {
  componentDidCatch(error, info) {
    logger.error('Error page reached in timeline route', error, { info });
    const { history, match } = this.props;
    history.push({
      pathname: `${match.url}/messaging-error`,
      state: 'UnknownMessagingError',
    });
  }

  render() {
    const { theme, match, fontFamily } = this.props;
    performance.mark('TimelineRoute - render');

    return (
      <Container fontFamily={fontFamily}>
        <ThemeProvider theme={theme}>
          <Switch>
            <Route
              exact={true}
              path={match.url}
              component={TimelineLandingPage}
            />
            <Route
              path={`${match.url}/messaging-error`}
              component={MessagingDisabled}
            />
            <Route path="*" component={MessagingDisabled} />
          </Switch>
        </ThemeProvider>
      </Container>
    );
  }
}

export default compose<InnerProps, OuterProps>(
  withRouter,
  withThemeToTimelineTheme,
  withThemeFont
)(TimelineRoute);
