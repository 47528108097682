import { identity, Store } from '@soluto-home-web/platform-identity';
const store = Store.forScope('timeline');

export const enum TimelineStoreValues {
  automaticFirstMessageSentByFlow = 'automaticFirstMessageSentByFlow',
  flowId = 'flowId',
  temporaryCode = 'temporaryCode',
  messagesData = 'messagesData',
  customFirstMessage = 'customFirstMessage',
  timelineCache = 'timelineCache',
}

export const getStoreItem = (key: TimelineStoreValues): any => {
  return store.get(key);
};

export const setStoreItem = (key: TimelineStoreValues, value: any): void => {
  store.set(key, value);
};

export const removeStoreItem = (key: TimelineStoreValues): void => {
  store.remove(key);
};

export const clear = (): void => {
  store.clear();
};

identity.loggedOut$.subscribe(() => {
  clear();
});
