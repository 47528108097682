export const prev = (arr, index) => (index === 0 ? {} : arr[index - 1]);
export const next = (arr, index) =>
  index >= arr.length - 1 ? {} : arr[index + 1];

const _defaultGroupedTypes = [
  'TextMessage',
  'TypingMessage',
  'OnBoardingIllustrationImage',
  'ImageItem',
  'LottieTimelineItem',
  'AppLink',
];
const _groupedTypes = {
  TextMessage: _defaultGroupedTypes,
  ImageItem: _defaultGroupedTypes,
  AppLink: _defaultGroupedTypes,
  LottieTimelineItem: _defaultGroupedTypes,
  WelcomeMessage: ['OnBoardingEasterEggChatMessage'],
  OnBoardingEasterEggChatMessage: ['TextMessage'],
  OnBoardingTechnicianChatMessage: [
    'OnBoardingTechnicianChatMessage',
    'TextMessage',
  ],
  OnBoardingIllustrationImage: ['OnBoardingEasterEggChatMessage'],
};

const _sameSender = (prev, current) =>
  `${prev.senderType}_${prev.senderName}` ===
  `${current.senderType}_${current.senderName}`;

const _sameGroupType = (prev, current, groupedTypes) => {
  const currentContentType =
    current.contentType === 'DynamicComponent'
      ? current.url
      : current.contentType;
  const prevContentType =
    prev.contentType === 'DynamicComponent' ? prev.url : prev.contentType;

  const groupedItem = groupedTypes[prevContentType];
  if (groupedItem) {
    return groupedItem.includes(currentContentType);
  }

  return false;
};

export const isGrouped = (prevItem, currentItem) =>
  _sameSender(prevItem, currentItem) &&
  _sameGroupType(prevItem, currentItem, _groupedTypes);
