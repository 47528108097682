import equals from 'ramda.equals';
import omit from 'ramda.omit';
import pickBy from 'ramda.pickby';

export default class HomeUser {
  public id: string;
  public partner: string;
  public timelineId: string;
  public firstName: string;
  public lastName: string;
  public mdn: string;
  public flowId: string;
  public enrolledDevices: { Make?: string; Model?: string }[];

  private _lastCopy: string;

  public isDirty(): boolean {
    const copy = JSON.stringify(omit(['_lastCopy'], this));
    const dirty = copy !== this._lastCopy;

    return dirty;
  }

  public isEqual(user: HomeUser): boolean {
    const a = pickBy(
      (v, k) => k !== '_lastCopy' && !((v as any) instanceof Function),
      this
    );
    const b = pickBy(
      (v, k) => k !== '_lastCopy' && !((v as any) instanceof Function),
      user
    );
    const res = equals(a as any, b as any);

    return res;
  }

  public flush = () => (this._lastCopy = JSON.stringify(this));

  public static deserialize(jsonStr: string): HomeUser {
    const user = new HomeUser();

    if (jsonStr) {
      const json = JSON.parse(jsonStr);
      Object.keys(json).forEach((k) => {
        user[k] = json[k];
      });
    }
    user.flush();

    return user;
  }

  public serialize(): string {
    const lastCopy = this._lastCopy;
    delete this._lastCopy;
    const str = JSON.stringify(this);
    this._lastCopy = lastCopy;

    return str;
  }
}
