const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
};

const cache = {};
const json = {
  v: '5.1.1',
  fr: 32,
  ip: 0,
  op: 64,
  w: 48,
  h: 48,
  nm: 'Spinner',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Shape Layer 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [20.775, 21.525, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              d: 3,
              ty: 'el',
              s: { a: 0, k: [34.45, 34.45], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: 'Ellipse Path 1',
              mn: 'ADBE Vector Shape - Ellipse',
              hd: false,
            },
            {
              ty: 'tm',
              s: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ['0p667_1_0p333_0'],
                    t: 0,
                    s: [100],
                    e: [0],
                  },
                  { t: 33.06640625 },
                ],
                ix: 1,
              },
              e: {
                a: 1,
                k: [
                  {
                    i: { x: [0.667], y: [1] },
                    o: { x: [0.333], y: [0] },
                    n: ['0p667_1_0p333_0'],
                    t: 29.866,
                    s: [100],
                    e: [0],
                  },
                  { t: 62.93359375 },
                ],
                ix: 2,
              },
              o: { a: 0, k: 19440, ix: 3 },
              m: 2,
              ix: 2,
              nm: 'Trim Paths 1',
              mn: 'ADBE Vector Filter - Trim',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0.682352941176, 0.937254901961, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 3, ix: 5 },
              lc: 2,
              lj: 2,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
              o: { a: 0, k: 0, ix: 5 },
              r: 1,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [3.225, 2.475], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Ellipse 1',
          np: 4,
          cix: 2,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 64,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

const get = (hexColor) => {
  if (cache[hexColor]) {
    return cache[hexColor];
  }

  const brandedJson = Object.assign({}, json);
  const { r, g, b } = hexToRgb(hexColor);
  brandedJson.layers[0].shapes[0].it[2].c!.k[0] = r / 255;
  brandedJson.layers[0].shapes[0].it[2].c!.k[1] = g / 255;
  brandedJson.layers[0].shapes[0].it[2].c!.k[2] = b / 255;

  cache[hexColor] = brandedJson;

  return brandedJson;
};

export default get;
