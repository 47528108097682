import { TimelineItem } from '@soluto-home-web/timeline-core';
import equals from 'ramda.equals';
import pick from 'ramda.pick';
import * as React from 'react';
import { AnalyticsDispatcher } from 'shisell';
import { TwoStepResolutionContent } from '../TwoStepResolutionContentType';
import ExpertLeftConversationMessage from './ExpertLeftConversationMessage';
import FlowEndedMessage from './FlowEndedMessage';
import IssueNotResolved from './IssueNotResolved';
import ResolutionQuestion from './ResolutionQuestion';

export type TwoStepResolutionState = {
  resolutionAnswer: string;
  assistanceNeededAnswer: string;
  isExpired: boolean;
  isPending: boolean;
  issueNotResolvedMessagesToShow: number;
  flowEndedMessagesToShow: number;
};

export type TwoStepResolutionTimelineProps = TwoStepResolutionState &
  TimelineItem<TwoStepResolutionContent> & {
    analytics: AnalyticsDispatcher;
    expertBrandingName: string;
    triggerScrollDown: () => void;
    setFlowEndedMessagesToShow: (flowEndedMessagesToShow: number) => void;
  };

const pickContentImpactingFields = pick([
  'resolutionAnswer',
  'assistanceNeededAnswer',
  'isExpired',
  'isPending',
  'issueNotResolvedMessagesToShow',
  'flowEndedMessagesToShow',
]);

export default class TwoStepResolutionTimelineItem extends React.Component<
  TwoStepResolutionTimelineProps
> {
  componentDidCatch(error, info) {
    const { analytics } = this.props;
    analytics.withExtras({ error, info }).dispatch('UnknownError');
  }

  componentDidUpdate(prevProps: TwoStepResolutionTimelineProps): void {
    if (
      !equals(
        pickContentImpactingFields(this.props),
        pickContentImpactingFields(prevProps)
      )
    ) {
      this.props.triggerScrollDown();
    }
  }

  componentDidMount(): void {
    setTimeout(() => {
      const twoStepContainer = document.querySelectorAll('.twoStep');
      twoStepContainer!.forEach((x) => x.removeAttribute('role'));
    }, 800);
  }

  render() {
    const { props } = this;

    return (
      <div className="twoStep" role="text">
        <ExpertLeftConversationMessage {...props} />
        <ResolutionQuestion {...props} />
        <IssueNotResolved {...props} />
        <FlowEndedMessage {...props} />
      </div>
    );
  }
}
