import { monitorClient } from '@soluto-home-web/platform-logger';
import {
  SendMessage,
  TimelineItem,
  withMarkItemAsSeen,
  withSendMessage,
  withSessionLifecycleAnalytics,
  withShowTypingIndicator,
  withTimelineItems,
} from '@soluto-home-web/timeline-core';
import withStopTimelineLoader from '@soluto-home-web/timeline-core/hoc/withStopTimelineLoader';
import { styled } from '@soluto-home-web/web-theme';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  AnalyticsContext,
  enrichAnalytics,
  withAnalytics,
} from 'react-shisell';
import { compose, defaultProps, setDisplayName } from 'recompose';
import Timeline from './components/Timeline';
//TODO error handling
//TODO caching in local storage
import withMessageAnalytics from './hoc/withMessageAnalytics';
import withTimestampTimelineItems from './hoc/withTimestampTimelineItems';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; /* Quick fix for chrome 72 scrolling issue */
  flex-grow: 1; /* Quick fix for chrome 72 scrolling issue */
`;

type OutProps = {
  timelineId: string;
  showHistory: boolean;
  firebaseToken?: string;
  shouldImageActAsAnchor?: boolean;
};

type Props = OutProps &
  AnalyticsContext & {
    firebaseInitialized: boolean;
    setFirebaseInitialized: (boolean) => void;
    showTypingIndicator: boolean;
    sendMessage: SendMessage;
    timelineItems: TimelineItem<any>[];
    fontFamily: string;
  };

class TimelineView extends React.Component<Props & RouteComponentProps> {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    performance.mark(`TimelineView will mount`);
    monitorClient.incrementByBrowserVersion('Messaging.View');
  }

  componentDidMount() {
    this.props.analytics.dispatcher
      .withIdentity('TimelineId', this.props.timelineId)
      .createScoped('TimelineView')
      .dispatch('View');
  }

  render() {
    const {
      timelineId,
      showHistory,
      sendMessage,
      timelineItems,
      shouldImageActAsAnchor,
      showTypingIndicator = false,
      children,
    } = this.props;

    return (
      <Container id="timelineViewContainer">
        <Timeline
          timelineId={timelineId}
          onSendMessage={sendMessage}
          timelineItems={timelineItems}
          shouldImageActAsAnchor={shouldImageActAsAnchor!}
          showTypingIndicator={showTypingIndicator}
        >
          {children}
        </Timeline>
      </Container>
    );
  }
}

export default compose<Props, OutProps>(
  enrichAnalytics((_) => _),
  defaultProps({
    showHistory: true,
    shouldImageActAsAnchor: true,
  }),
  withSessionLifecycleAnalytics,
  withSendMessage,
  withShowTypingIndicator,
  withTimelineItems,
  withTimestampTimelineItems,
  withMessageAnalytics,
  withMarkItemAsSeen,
  withAnalytics,
  withStopTimelineLoader,
  setDisplayName('TimelineView')
)(TimelineView);
