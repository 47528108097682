import TimelineTheme from '@soluto-home-web/timeline-theme/TimelineTheme';
import { compose, mapProps } from 'recompose';
import { withTheme } from 'styled-components';

const convertAppThemeToTimelineTheme = (appTheme: Theme): TimelineTheme => {
  return {
    colors: {
      foreground: appTheme.colors.foreground,
      primary: appTheme.colors.primary,
      primary_active: appTheme.colors.primary_active,
      alternative:
        appTheme.colors.primary === '#FFCF0A'
          ? 'black'
          : appTheme.colors.primary,
    },
    images: {
      service: appTheme.images.partner_icon,
      end_of_session_experts: appTheme.images.end_of_session_experts,
      team: appTheme.images.team,
      expired_button: appTheme.images.team,
    },
    texts: {
      partner_name: appTheme.texts.partner_name,
      service_name: appTheme.texts.partner_service_name,
      partner_expert_name: appTheme.texts.partner_expert_name,
    },
    fonts: appTheme.fonts,
  };
};

export default compose(
  withTheme,
  mapProps(({ theme, ...props }) => ({
    ...props,
    appTheme: theme,
    theme: convertAppThemeToTimelineTheme(theme),
  }))
);
