import {
  BugsnagLoggerOptions,
  ErrorBoundaryCreator,
  logger,
  SensitiveDataRedactor,
} from '@soluto-home-web/platform-logger';
import { getTweekRepository } from '@soluto-home-web/platform-tweek';
import { styled, ThemeProps, ThemeProvider } from '@soluto-home-web/web-theme';
import Report from 'bugsnag-js/types/report';
import * as ReactDOM from 'preact';
import * as React from 'react';
import Helmet from 'react-helmet';
import { TweekProvider, withTweekValues } from 'react-tweek';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import ResponsiveIndicator from './components/ResponsiveIndicator';
import SomethingWentWrongPage from './components/SomethingWentWrongPage';
import './globals';
import './initializations/dependencies';
import { showA11yLogs } from './services/a11y/react-axe-logs';
import { SessionTracking } from './services/session-tracking';

type AppType = React.ComponentClass<any> | React.StatelessComponent<any>;

const PageLoadedIndicator = styled.div`
  display: none;
`;

interface HeadConfigurableProps {
  metaDescription: string;
}

type HelmetProps = HeadConfigurableProps & ThemeProps;
const CustomHelmet: React.SFC<HelmetProps> = (props) => (
  <Helmet>
    <meta name="description" content={props.metaDescription} />
    <meta content="IsAlive" />
    <link id="favicon" rel="icon" href={props.theme.images.partner_icon} />
    <title>{props.theme.texts.partner_service_name}</title>
  </Helmet>
);

const EnhancedHelmet = compose(
  withTweekValues<HeadConfigurableProps>({
    metaDescription: 'soluto_home_web/build/meta_description',
  }),
  withTheme
)(CustomHelmet);

const WithProviders = (Comp: AppType) => (props) => (
  <ThemeProvider>
    <TweekProvider value={getTweekRepository()}>
      <>
        <EnhancedHelmet />
        <Comp {...props} />
        <PageLoadedIndicator className="page-loaded-indicator" />
        {globals.NODE_ENV === 'development' && <ResponsiveIndicator />}
        <SessionTracking />
      </>
    </TweekProvider>
  </ThemeProvider>
);

const ErrorBoundaryFallBackComponent = () => (
  <ThemeProvider>
    <SomethingWentWrongPage />
  </ThemeProvider>
);

const beforeSendUnhandledError = (report: Report) => {
  SensitiveDataRedactor(report);
  const reportError = {
    ...report,
    name: report.errorClass,
    message: report.errorMessage,
  };
  const loggerOptions: BugsnagLoggerOptions = { skipBugsnagReport: true };
  logger.error(report.errorMessage, reportError, {}, loggerOptions);
};

const ErrorBoundary = ErrorBoundaryCreator();
const WithErrorBoundary = (Comp: AppType) => (props) => (
  <ErrorBoundary
    FallbackComponent={ErrorBoundaryFallBackComponent}
    beforeSend={beforeSendUnhandledError}
  >
    <Comp {...props} />
  </ErrorBoundary>
);

export default function RenderApp(App: AppType): void {
  let CompositeApp = WithProviders(App);
  if (globals.NODE_ENV === 'production') {
    CompositeApp = WithErrorBoundary(CompositeApp);
  } else {
    showA11yLogs(globals.REACT_APP_AXE_FOR_A11Y_LOGS);
  }

  ReactDOM.render(<CompositeApp />, document.getElementById(
    'root'
  ) as HTMLElement);
}
