class HttpError extends Error {
  response: Response;
  constructor(response: Response) {
    super(`${response.status} ${response.statusText}`);
    this.response = response;
  }

  static ensureSuccessStatusCode(response: Response) {
    if (!response.ok) {
      throw new HttpError(response);
    }
  }
}

export default HttpError;
