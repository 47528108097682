import {
  branch,
  compose,
  mapProps,
  mapPropsStreamWithConfig,
  withProps,
} from 'recompose';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { merge } from 'rxjs/observable/merge';
import { timer } from 'rxjs/observable/timer';
import { Subject } from 'rxjs/Subject';
import rxjsconfig from '../utils/rxjsconfig';

import {
  getTypingTechnicians$,
  postUserIsTyping,
} from '../firebase/chatSessionApi';
const mapPropsStream = mapPropsStreamWithConfig(rxjsconfig);
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import {
  withShowTypingIndicator as withMessagingApiShowTypingIndicator,
  withTypingBehavior as withMessagingApiTypingBehavior,
} from '../messaging-api/with-typing';
import MessagingProviderType from '../utils/MessagingProviderType';

const TypingTimeout = 3000;

type WithIsTypingProps = {
  onInputCompleted: () => void;
  onInputChanged: () => void;
};

export const withTypingBehavior = compose(
  withTweekKeys('support/session/messaging/is_typing/is_enabled', {
    propName: 'isTypingEnabled',
  }),
  branch(
    ({ isTypingEnabled }) => isTypingEnabled,
    compose(
      withTweekKeys('support/session/messaging/is_typing/provider', {
        propName: 'typingProvider',
      }),
      branch(
        ({ typingProvider }) =>
          typingProvider === MessagingProviderType.MessagingApi,
        withMessagingApiTypingBehavior,
        mapPropsStream((props$: any) => {
          const textUpdate$ = new Subject();
          const notTypingOverTime$ = textUpdate$
            .switchMap(() => timer(TypingTimeout))
            .mapTo(false);
          const isUserTyping$ = merge(
            textUpdate$,
            notTypingOverTime$
          ).distinctUntilChanged();
          const timelineId$ = props$.pluck('timelineId').distinctUntilChanged();

          const userIsTyping$ = combineLatest(
            isUserTyping$,
            timelineId$,
            (isTyping, timelineId) => ({
              isTyping,
              timelineId,
            })
          ).do(({ isTyping, timelineId }: any) =>
            postUserIsTyping(timelineId, isTyping)
          );

          const updateFalse = () => textUpdate$.next(false);
          const updateTrue = (text) => {
            if (text && text !== '' && !text.startsWith('/')) {
              textUpdate$.next(true);
            }
          };

          return props$
            .combineLatest(userIsTyping$.startWith(false), (props) => ({
              ...props,
              onInputCompleted: updateFalse,
              onInputChanged: updateTrue,
            }))
            .finally(() => textUpdate$.complete());
        })
      )
    ),
    withProps<WithIsTypingProps, any>(() => ({
      onInputCompleted: () => null,
      onInputChanged: () => null,
    }))
  )
);

export const withShowTypingIndicator = compose<any, any>(
  withTweekKeys('support/session/messaging/is_typing/display_indicator', {
    propName: 'displayTypingIndicatorEnabled',
  }),
  branch(
    ({ displayTypingIndicatorEnabled }) => displayTypingIndicatorEnabled,
    compose(
      withTweekKeys('support/session/messaging/is_typing/provider', {
        propName: 'typingProvider',
      }),
      branch(
        ({ typingProvider }) =>
          typingProvider === MessagingProviderType.MessagingApi,
        withMessagingApiShowTypingIndicator,
        mapPropsStream((props$: any) => {
          const isTechnicianTyping$ = props$
            .pluck('timelineId')
            .switchMap((timelineId) => getTypingTechnicians$(timelineId))
            .distinctUntilChanged()
            .map((typingTechnicians) => !!typingTechnicians)
            .distinctUntilChanged()
            .startWith(false);

          return props$.combineLatest(
            isTechnicianTyping$,
            (props, isTechnicianTyping) => ({
              ...props,
              showTypingIndicator: isTechnicianTyping,
            })
          );
        })
      )
    )
  ),
  mapProps(
    ({ typingProvider, displayTypingIndicatorEnabled, ...other }) => other
  )
);
