import HomeUser from './HomeUser';

export default function (dispatcher, user: HomeUser) {
  if (!user) {
    return dispatcher;
  }

  let d = dispatcher;
  if (user.id) {
    d = d.withIdentity('HomeUserId', user.id);
  }
  if (user.partner) {
    d = d.withExtra('HomeUserPartner', user.partner);
  }
  if (user.timelineId) {
    d = d.withExtra('TimelineId', user.timelineId);
  }

  return d;
}
