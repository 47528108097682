import bugsnag, { Bugsnag } from 'bugsnag-js';

export const WEB_TEAM_BUGSNAG_CLIENT_API_KEY =
  'f357427b157337a6c613b296dba73fc5';

export type BugsnagClientSingleton = {
  getInstance: () => Bugsnag.Client;
};

class BugsnagClient {
  private static _bugsnagClient: Bugsnag.Client;

  public static getInstance(): Bugsnag.Client {
    if (!!bugsnag && !this._bugsnagClient) {
      this.initializeClient();
    }

    return this._bugsnagClient;
  }

  private static initializeClient() {
    this._bugsnagClient = bugsnag({
      apiKey: WEB_TEAM_BUGSNAG_CLIENT_API_KEY,
      releaseStage: process.env.NODE_ENV,
      appVersion: globals.WEBSITE_VERSION,
      collectUserIp: false,
    });

    this._bugsnagClient.metaData = {
      custom: {
        partner: globals.REACT_APP_PARTNER,
        isTest: globals.REACT_APP_IS_TEST,
        isLocalNumber: globals.REACT_APP_IS_LOCAL_NUMBER,
      },
    };
  }
}

export default BugsnagClient;
