import TimelineItem from '@soluto-home-web/timeline-core/models/TimelineItem';
import * as React from 'react';

const modules = (require as any).context(
  './components',
  true,
  /index\.(js|ts|tsx)/
);

const possibleComponents = {};

modules.keys().forEach((m) => {
  const componentName = m.split('/')[1].toLowerCase();
  possibleComponents[componentName] = modules(m).default;
});

export const getComponent = (
  componentUrl: string
): React.ComponentType<TimelineItem<{}>> | undefined => {
  const componentName = componentUrl.toLowerCase();
  if (!possibleComponents[componentName]) {
    return undefined;
  }

  return possibleComponents[componentName] as React.ComponentType<
    TimelineItem<{}>
  >;
};
