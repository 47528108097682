import * as React from 'react';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';
import { compose } from 'recompose';

export type PageProps = React.HTMLAttributes<HTMLDivElement> & {
  pageName: string;
  mapPropsToExtras?: (props: any) => object;
};

const Page: React.SFC<PageProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
);

export default compose<{}, PageProps>(
  enrichAnalytics<PageProps>((dispatcher, { pageName }) =>
    dispatcher.withExtra('Page', pageName)
  ),
  withAnalyticOnView<PageProps>({
    analyticName: 'PageView',
    mapPropsToExtras: ({ mapPropsToExtras, ...props }) =>
      mapPropsToExtras ? mapPropsToExtras(props) : {},
  })
)(Page);
