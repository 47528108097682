import * as React from 'react';

type props = {
  color: string;
};

const CheckMark = ({ color }: props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M5.008 8.002l5.497-5.5a.7.7 0 1 1 .99.99l-6 6.003a.7.7 0 0 1-.998-.008L1.533 6.428a.7.7 0 1 1 1.005-.974l2.47 2.548z"
    />
  </svg>
);
export default CheckMark;
