import authenticationApiClient from './authenticationApiClient';
import oneTimeAuthCodeSender from './oneTimeAuthCodeSender';

export async function validateAuthenticationCode(
  partnerBrandId: string,
  oneTimeAuthCode: string,
  countryCode: string,
  mdn: string
): Promise<string> {
  try {
    return await authenticationApiClient.homeUserAccessTokenFromOneTimeAuthCodeGrant(
      partnerBrandId,
      oneTimeAuthCode,
      countryCode,
      mdn
    );
  } catch (err) {
    if (err.status === 400) {
      err.authError = OneTimecodeValidationErrors.VALIDATION_FAILED_ERROR;
    } else {
      err.authError = OneTimecodeValidationErrors.UNKNOWN_ERROR;
    }
    throw err;
  }
}

export async function sendOneTimeAuthenticationCode(
  countryCode: string,
  mdn: string,
  partner: string
): Promise<any> {
  return await oneTimeAuthCodeSender.send(countryCode, mdn, partner);
}

export enum OneTimecodeValidationErrors {
  VALIDATION_FAILED_ERROR,
  UNKNOWN_ERROR,
}
