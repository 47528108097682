import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { withRouter } from '@soluto-home-web/platform-utils';
import { branch, compose, lifecycle, mapProps, withProps } from 'recompose';
import * as store from '../core/store';
import { provideMessagingClient } from '../messaging-api/messaging-client-provider';
import MessagingProviderType from '../utils/MessagingProviderType';
import withGetBearerTokenHandler from './withGetBearerTokenHandler';

const loadState = async () =>
  store.getStoreItem(store.TimelineStoreValues.messagesData);
const saveState = async (state: string) =>
  store.setStoreItem(store.TimelineStoreValues.messagesData, state);

export default compose(
  withTweekKeys('support/session/messaging/provider', {
    propName: 'messagingProvider',
  }),
  branch(
    ({ messagingProvider, timelineId }) =>
      messagingProvider === MessagingProviderType.MessagingApi && timelineId,
    compose(
      withRouter,
      withGetBearerTokenHandler,
      provideMessagingClient(({ getBearerToken, timelineId }) => ({
        senderId: timelineId,
        getBearerToken,
        loadState,
        saveState,
      })),
      mapProps(({ getBearerToken, ...other }) => other)
    )
  ),
  mapProps(({ messagingProvider, ...other }) => other)
);
