import { compose, getContext, withHandlers } from 'recompose';
import {
  MessagingClientProps,
  messagingContextTypes,
} from '../messaging-api/types';

type MessageResender = (messageId: string) => void;

type MessageResenderProps = {
  resendMessage: MessageResender;
};

export const withResendMessage = compose(
  getContext(messagingContextTypes),
  withHandlers<MessagingClientProps, MessageResenderProps>({
    resendMessage: ({ messagingClient }) => (messageId) => {
      messagingClient && messagingClient.resend(messageId);
    },
  })
);
