import { queryString } from '@soluto-home-web/platform-utils';
import { withRouter } from '@soluto-home-web/platform-utils';
import { compose, withHandlers } from 'recompose';

export const withOpenChat = compose<any, any>(
  withRouter,
  withHandlers({
    openChat: ({ location, history }) => () => {
      history.push(`${location.pathname}${queryString.add('chat')}`);
    },
  })
);
