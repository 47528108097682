import {
  getEnrichedDispatcher,
  itemTypes,
  TimelineItem,
  withSessionId,
} from '@soluto-home-web/timeline-core';
import { analytics, AnalyticsContext, withAnalytics } from 'react-shisell';
import { compose, lifecycle, mapProps } from 'recompose';

const contentTypes = [itemTypes.TextMessage, itemTypes.ImageItem];

enum MessageEventNames {
  customer = 'CustomerMessageSent',
  tech = 'TechMessageReceived',
}

const getTextMessages = (senderType, timelineItems) =>
  timelineItems
    .filter(
      (item) =>
        contentTypes.indexOf(item.contentType) >= 0 &&
        item.senderType === senderType
    )
    .reverse();

function sendEventIfNeeded(
  dispatcher,
  senderType,
  eventName,
  oldItems,
  newItems,
  sessionId
) {
  const oldMessages = getTextMessages(senderType, oldItems);
  const nextMessages = getTextMessages(senderType, newItems);

  if (nextMessages.length - oldMessages.length === 1) {
    const item = nextMessages[0];
    let newDispatcher = item.senderId
      ? dispatcher.withExtra('senderId', item.senderId)
      : dispatcher;

    if (item.inFlight || eventName === MessageEventNames.tech) {
      getEnrichedDispatcher(
        newDispatcher,
        newItems,
        sessionId,
        contentTypes
      ).dispatch(eventName);
    }
  }
}

export default compose(
  withSessionId,
  lifecycle({
    componentDidUpdate(prevProps: {
      timelineItems: TimelineItem<any>[];
      sessionId: string;
    }) {
      if (!prevProps.timelineItems || prevProps.timelineItems.length === 0) {
        return;
      }

      sendEventIfNeeded(
        analytics.dispatcher.createScoped('Support').createScoped('Session'),
        'Device',
        MessageEventNames.customer,
        prevProps.timelineItems,
        this.props.timelineItems,
        this.props.sessionId
      );

      sendEventIfNeeded(
        analytics.dispatcher
          .createScoped('Support')
          .createScoped('Session')
          .createScoped('Messaging'),
        'Technician',
        MessageEventNames.tech,
        prevProps.timelineItems,
        this.props.timelineItems,
        this.props.sessionId
      );
    },
  }),
  mapProps(({ sessionId, ...other }) => other)
);
