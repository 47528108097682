import { logger } from '@soluto-home-web/platform-logger';
import { updateItem } from '@soluto-home-web/timeline-core';
import { BreakPoints, commonCss, styled } from '@soluto-home-web/web-theme';
import equals from 'ramda.equals';
import * as React from 'react';
import { AnalyticsContext, withAnalytics } from 'react-shisell';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';
import EndOfSession from './EndOfSession';
import Rating from './StarRating';

const FeedbackPane = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.1vh;
  width: 80%;
  margin: 0 auto;
  margin-top: 24px;
`;

const Title = styled.span`
  font-weight: bold;
  color: #343434;
  text-align: center;
  font-size: 18px;
  margin-bottom: 1vh;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2.1vh;
  width: 256px;
`;

const Submit = styled.button`
  background-color: ${(props) => props.theme.colors.primary};
  height: 2.8em;
  width: 310px;
  border-radius: 1.4em;
  font-size: 18px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.foreground};
  border: none;
  cursor: pointer;
  margin: 0.7em 1.6em 0.4em 1.6em;

  &:focus {
    outline: none;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  ${commonCss.withOutsideHoverEffect};
  &::after {
    border-radius: 1.4em;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary_active};
  }

  font-size: 0.9em;
  @media ${BreakPoints.tablet} {
    font-size: 0.8em;
  }
`;

type OuterProps = {
  timelineId: string;
  contentId: string;
  contentType: string;
  content: {
    expertName: string;
    sessionId: string;
    surveyKey?: string;
    environment?: string;
    chatResolvedAt: number;
  };
  state?: string;
  triggerScrollDown: () => void;
};

type InnerProps = OuterProps & AnalyticsContext;

type State = {
  rating: number;
  feedbackReasons: string[];
  tellUsMoreText?: string;
  ratingSent: boolean;
  isExpired: boolean;
};

class ChatFeedback extends React.Component<InnerProps, State> {
  state: State = {
    rating: 0,
    feedbackReasons: [],
    tellUsMoreText: '',
    ratingSent: false,
    isExpired: false,
  };

  expirationTimer: any;

  constructor(props) {
    super(props);

    const { state = '{}' } = this.props;

    let parsedState;
    try {
      parsedState = JSON.parse(state);
    } catch (err) {
      console.warn('failed parsing StarSurveyTimelineItem state', state, err);
    }

    if (!parsedState || typeof parsedState !== 'object') {
      parsedState = {};
    }

    let { isExpired } = parsedState;

    if (!isExpired) {
      const timeout = this.timeToExpire();

      if (timeout <= 0) {
        isExpired = true;
      } else {
        this.expirationTimer = setTimeout(() => {
          this.setState({ isExpired: true });
          if (!this.state.ratingSent) {
            this.submit(false);
          }
        }, timeout);
      }
    }

    this.state = {
      rating: 0,
      feedbackReasons: [],
      tellUsMoreText: '',
      ratingSent: false,
      ...parsedState,
      isExpired,
    };
  }

  componentDidUpdate(_, prevState) {
    if (!equals(prevState, this.state)) {
      this.uploadState();
    }

    if (prevState.rating === 0 && this.state.rating > 0) {
      this.props.triggerScrollDown();
    }
  }

  componentDidMount() {
    this.props.analytics.dispatcher.createScoped('StarSurvey').dispatch('View');

    setTimeout(() => {
      const feedbackContainer = document.querySelectorAll('.feedback');
      feedbackContainer!.forEach((x) => x.removeAttribute('role'));
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.expirationTimer);
  }

  async submit(isCompleted = true) {
    const {
      analytics,
      content: { sessionId, surveyKey, environment },
    } = this.props;
    const { rating, feedbackReasons, tellUsMoreText } = this.state;
    const extras = {
      Rating: rating,
      Comment: tellUsMoreText,
    };
    feedbackReasons.forEach(
      (reason) =>
        (extras[reason.charAt(0).toUpperCase() + reason.slice(1)] = true)
    );
    analytics.dispatcher
      .createScoped('StarSurvey_Submit')
      .withExtras(extras)
      .dispatch('Click');

    const survey = {
      sessionId,
      rating,
      comments: tellUsMoreText,
      surveyTags: { tags: feedbackReasons },
      isCompleted,
    };

    const config = {
      method: 'POST',
      body: JSON.stringify(survey),
    };

    if (surveyKey !== 'voice') {
      const endpoint =
        environment === 'dev'
          ? 'https://expert-api.soluto.npr.aws.asurion.net/surveys'
          : 'https://expert-api.anywhereexpert.us/surveys';

      try {
        const response = await fetch(endpoint, config);

        if (!response.ok) {
          const responseText = await response.text();
          const error = new Error(responseText);
          (error as any).response = response;
          throw error;
        }
      } catch (error) {
        logger.error('failed updating star survey response', error);
      }
    }
  }

  async uploadState() {
    const { timelineId, contentType, contentId } = this.props;
    await updateItem(timelineId, contentType, contentId, {
      state: JSON.stringify(this.state),
    });
  }

  timeToExpire() {
    return this.props.content.chatResolvedAt + 28800000 - Date.now();
  }

  render() {
    const { rating, ratingSent, isExpired } = this.state;

    if (ratingSent) {
      return (
        <FeedbackPane tabIndex={0}>
          <EndOfSession message={'Thanks for your feedback!'} />
        </FeedbackPane>
      );
    }

    if (isExpired) {
      return null;
    }

    const {
      content: { expertName },
    } = this.props;

    return (
      <FeedbackPane className="feedback" role="text">
        {/*<EndOfSession message={'Thanks for reaching out!'} />*/}
        <Title tabIndex={0} role="text">
          How did {expertName} do?
        </Title>
        <Rating
          rating={rating}
          onChange={(rating) => this.setState({ rating })}
        />
        {rating > 0 && (
          <>
            <Buttons>
              <Submit
                role="button"
                onClick={() => {
                  this.submit();
                  this.setState({ ratingSent: true });
                }}
              >
                Submit
              </Submit>
            </Buttons>
          </>
        )}
      </FeedbackPane>
    );
  }
}

const enhance = compose<InnerProps, OuterProps>(
  withTheme,
  withAnalytics
);

export default enhance(ChatFeedback);
