import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { branch, compose, lifecycle, mapProps } from 'recompose';
import { setStoreItem, TimelineStoreValues } from '../core/store';
import { default as TimelineItem } from '../models/TimelineItem';
import getMessagesFromCache from './getMessagesFromCache';

const getMessagesToCache = (
  cachedItems: TimelineItem<any>[],
  timelineItems: TimelineItem<any>[],
  messageLimit: number
) => {
  const combinedItems = cachedItems
    .concat(timelineItems || [])
    .filter((x) => !x.inFlight && !x.isFailedSending)
    .reduce((reducer, timelineItem) => {
      reducer[timelineItem.contentId] = timelineItem;
      return reducer;
    }, {});

  return Object.values(combinedItems)
    .sort(
      (itemId1: TimelineItem<any>, itemId2: TimelineItem<any>) =>
        itemId2.timestamp - itemId1.timestamp
    )
    .slice(0, messageLimit);
};

export default compose(
  withTweekKeys('support/session/messaging/fallback/cache/is_enabled', {
    propName: 'isCacheEnabled',
  }),
  withTweekKeys(
    'support/session/messaging/fallback/cache/count/messages_per_session',
    {
      propName: 'messageLimit',
      initialValue: 30,
    }
  ),
  branch(
    ({ isCacheEnabled }) => isCacheEnabled,
    lifecycle({
      componentDidUpdate({ timelineItems: prevTimelineItems }) {
        const { timelineItems, messageLimit } = this.props as any;
        if (prevTimelineItems !== timelineItems) {
          const messagesToCache = getMessagesToCache(
            getMessagesFromCache(),
            timelineItems,
            messageLimit
          );
          setStoreItem(TimelineStoreValues.timelineCache, messagesToCache);
        }
      },
    })
  ),
  mapProps(({ isCacheEnabled, messageLimit, ...other }) => other)
);
