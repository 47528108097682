import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import * as React from 'react';
import { compose, withState } from 'recompose';
import styled from 'styled-components';

type EnhancedExpertAvatarProps = {
  senderThumbUrl: string;
  senderName: string;
  senderType: string;
  theme: Theme;
  teamAltText: string;
};

type ExpertAvatarProps = EnhancedExpertAvatarProps & {
  expertName: string;
};

type EnhancedFailedProps = {
  isImageFailed: boolean;
  setIsImageFailed: (isImageFailed: boolean) => void;
};

const NameAvatar = styled.div`
  border: 1px solid rgb(228, 228, 228);
  border-radius: 50%;

  background-color: rgb(188, 188, 188);
  font-size: 1em;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 11.5px;
  height: 40px;
  width: 40px;
  min-width: 40px;
`;

const Image = styled.img`
  border: 1px solid rgb(228, 228, 228);
  border-radius: 50%;
  margin-right: 11.5px;
  height: 40px;
  width: 40px;
  min-width: 40px;
`;

const AvatarContainer = styled.div<{ expertName: string }>`
  margin-top: ${(props) => (props.expertName ? `16px` : `0`)};
`;

const ExpertAvatarImage = ({
  senderThumbUrl,
  senderName,
  senderType,
  theme,
  isImageFailed,
  setIsImageFailed,
  teamAltText,
}) => {
  if (senderThumbUrl === 'teamIcon' || senderType === 'Team') {
    return <Image src={theme.images.team} alt={teamAltText} />;
  }
  if (!senderThumbUrl || isImageFailed) {
    return (
      <NameAvatar>
        {typeof senderName === 'string' && senderName.length > 0
          ? senderName.charAt(0)
          : '!'}
      </NameAvatar>
    );
  }

  return (
    <Image src={senderThumbUrl} onError={() => setIsImageFailed(true)} alt="" />
  );
};

const ExpertAvatarImageWithFallback = compose<
  EnhancedFailedProps,
  EnhancedExpertAvatarProps
>(
  withState('isImageFailed', 'setIsImageFailed', false),
  withTweekKeys('soluto_home_web/theme/texts/avatar_image_alt_text', {
    propName: 'teamAltText',
  })
)(ExpertAvatarImage);

const ExpertAvatar: React.SFC<ExpertAvatarProps> = ({
  senderThumbUrl,
  senderName,
  senderType,
  theme,
  expertName,
  teamAltText,
}) => {
  return (
    <AvatarContainer expertName={expertName}>
      <ExpertAvatarImageWithFallback
        senderThumbUrl={senderThumbUrl}
        senderName={senderName}
        senderType={senderType}
        theme={theme}
        teamAltText={teamAltText}
      />
    </AvatarContainer>
  );
};
export default ExpertAvatar;
