import { queryParamsProvider } from '@soluto-home-web/platform-utils';
import { lifecycle } from 'recompose';
import { setStoreItem, TimelineStoreValues } from '../core/store';

export default lifecycle({
  componentDidMount() {
    const flow = queryParamsProvider.queryParams.flowId;

    if (flow) {
      setStoreItem(TimelineStoreValues.flowId, flow.value!);
    }
  },
});
