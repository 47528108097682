import { sendItem, updateItem } from './timelineApi';

export const postTimelineVideoItem = (timelineId: string, uploadingItem: any) =>
  sendItem(timelineId, uploadingItem);

export const cancelVideoItem = async (
  timelineId: string,
  contentId: string
) => {
  updateItem(timelineId, 'DynamicComponent', contentId, {
    isCanceled: true,
  });
};
