import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { queryString } from '@soluto-home-web/platform-utils';
import { Text } from '@soluto-home-web/text-item/TextItem';
import { TimelineItem } from '@soluto-home-web/timeline-core';
import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { compose } from 'recompose';

const deletedIcon = require('./assets/deleted.svg');

const DeletedText = styled(Text)`
  font-style: italic;
  background-color: ${(props: { fromExpert: boolean }) =>
    props.fromExpert ? `#f4f4f4` : `#dedede`};
`;

type MessageProps = {
  fromExpert: boolean;
  deletedText: string;
  customStyleRoute: string;
};

type Props = TimelineItem<any> & MessageProps;

const DeletedTextItem: React.StatelessComponent<Props> = ({
  deletedText,
  fromExpert,
  customStyleRoute,
}: Props) => {
  const customStyle = queryString.get().style;
  const isCustomLargeFontStyle =
    customStyleRoute && customStyle === customStyleRoute;

  return (
    <DeletedText
      fromExpert={fromExpert}
      className={isCustomLargeFontStyle ? 'customLargeFont' : ''}
    >
      <img style={{ marginRight: 5 }} src={deletedIcon} alt="" />
      {deletedText}
    </DeletedText>
  );
};

const enhance = compose(
  withTweekKeys(
    'support/session/messaging/rich_messaging/deletion/deleted_text',
    {
      propName: 'deletedText',
    }
  ),
  withTweekKeys('soluto_home_web/messaging/custom_style_route', {
    propName: 'customStyleRoute',
  })
);

export default enhance(DeletedTextItem);
