import { BreakPoints, styled } from '@soluto-home-web/web-theme';
import * as React from 'react';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: #ffffff;
  flex-direction: column;
  overflow: hidden;
  font-size: 1rem;
  align-items: center;
  margin: 0 auto;
  height: 50%;
  @media ${BreakPoints.tablet} {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    width: 30em;
    border-radius: 0.5em;
    height: 19em;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const WindowContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  max-height: 13em;
  @media ${BreakPoints.tablet} {
    max-height: none;
  }
`;

const PreviewMessages = styled.div`
  width: 18em;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-top: 1.5em;
`;

const UserMessage = styled.p`
  font-size: 0.9em;
  letter-spacing: 0.02em;
  color: #454545;
  margin: 0;
`;

const MessagingDisabledAlert = () => (
  <Container>
    <WindowContainer>
      <PreviewMessages>
        <UserMessage>
          Oops, your message may not have been sent.
          <br />
          Please call ProTech at 888-477-6832 for assistance.
        </UserMessage>
      </PreviewMessages>
    </WindowContainer>
  </Container>
);

export default MessagingDisabledAlert;
