import { styled } from '@soluto-home-web/web-theme';

export type SizeProps = {
  height: number;
  width: number;
};

export const MediaOverlay = styled.div<{ messageSelected: boolean }>`
  border-radius: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.messageSelected ? `rgba(0,0,0,0.2)` : `rgba(0,0,0,0)`};
  z-index: 1;
  transition: background 200ms linear;
  pointer-events: none;
`;

export const LinkMediaContainer = styled.a<SizeProps>`
  margin: 5px 0;
  position: relative;
  box-shadow: 0 0 4px 0 #9b9b9b;
  border-radius: 5px;
  display: inline-block;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;
