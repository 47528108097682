import SocketClient from '@npmsoluto/soluto-messaging-client';
import PropTypes from 'prop-types';

export type MessageSender = (message: string) => Promise<void>;

export type MessagingClientProps = {
  messagingClient: SocketClient;
};

export const messagingContextTypes = {
  messagingClient: PropTypes.object,
};

export type MessageSenderProps = {
  sendMessage: MessageSender;
};

export enum MessageType {
  Text = 'text_message',
  Image = 'image_item',
  Video = 'video',
}
