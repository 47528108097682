import { BreakPoints, commonCss, styled } from '@soluto-home-web/web-theme';
import React from 'react';
import { withTheme } from 'styled-components';

const ButtonContainer = styled.button`
  outline: none;
  height: 40px;
  border-radius: 20px;
  background-color: ${(props) => props.theme.colors.primary};
  border: none;
  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.2;
  }

  ${commonCss.withOutsideHoverEffect};
  &::after {
    border-radius: 1.4em;
  }

  &:active {
    background-color: ${(props) => props.theme.colors.primary_active};
  }

  font-size: 0.9em;
  @media ${BreakPoints.tablet} {
    font-size: 0.8em;
  }
`;

const ButtonView = styled.div`
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 18px;
`;

const ButtonText = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.foreground};
  margin: 0;
  padding-left: 0.2em;
`;

type PrimaryButtonProps = {
  title: string;
  onPress: () => void;
  theme: Theme;
};

const PrimaryButton: React.FunctionComponent<PrimaryButtonProps> = (props) => (
  <ButtonContainer theme={props.theme} onClick={props.onPress}>
    <ButtonView>
      <ButtonText theme={props.theme}>{props.title}</ButtonText>
    </ButtonView>
  </ButtonContainer>
);

export default withTheme(PrimaryButton);
