import Markdown from '@npmsoluto/soluto-markdown';
import { logger } from '@soluto-home-web/platform-logger';
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { queryString, userAgentParser } from '@soluto-home-web/platform-utils';
import { TimelineItem } from '@soluto-home-web/timeline-core';
import { BreakPoints } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { compose, pure } from 'recompose';
import styled from 'styled-components';
const isBoldSpacingFixNeeded =
  userAgentParser.getOs() === 'iOS' ||
  userAgentParser.getBrowserName() === 'Safari';

//the min-width is for IE11 compatibility
export const Text = styled.span`
  padding: 1em;
  border-radius: 4px;
  color: #333333;
  display: inline-block;
  min-width: 10px;
  max-width: 100%;
  word-break: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-size: 0.8em;

  strong {
    ${isBoldSpacingFixNeeded
      ? `font-weight: 500; -webkit-text-stroke: 0.2px;`
      : ''}
  }

  @media ${BreakPoints.tablet} {
    font-size: 0.9em;
  }

  @media ${BreakPoints.desktop} {
    font-size: 0.9em;
  }

  &.customLargeFont {
    font-size: 2em;
  }
`;

const ExpertText = styled(Text)`
  background-color: #f4f4f4;
`;

const UserText = styled(Text)`
  background-color: #dedede;
  transition: background-color 200ms linear;
`;

type MessageProps = {
  fromExpert: boolean;
  messageSelected: boolean;
  deleteable: boolean;
};

type OuterProps = TimelineItem<any> & MessageProps;

type InnerProps = { customStyleRoute: string } & OuterProps;

const TextItem: React.StatelessComponent<InnerProps> = ({
  content,
  fromExpert,
  messageSelected,
  deleteable,
  customStyleRoute,
}: InnerProps) => {
  const customStyle = queryString.get().style;
  const isCustomLargeFontStyle =
    customStyleRoute && customStyle === customStyleRoute;
  const TextComponent = fromExpert ? ExpertText : UserText;
  const deleteHover = messageSelected && deleteable;
  const classes = isCustomLargeFontStyle ? ['customLargeFont'] : [];
  if (deleteHover) {
    classes.push('deleteHover');
  }

  if (typeof content !== 'string') {
    logger.warn('TextItem received a non text value');
    return null;
  }

  return (
    <TextComponent data-pii={true} dir="auto" className={classes.join(' ')}>
      <Markdown content={content} linkify={true} />
    </TextComponent>
  );
};

export default compose<InnerProps, OuterProps>(
  withTweekKeys('soluto_home_web/messaging/custom_style_route', {
    propName: 'customStyleRoute',
  }),
  pure
)(TextItem);
