import { BreakPoints, styled } from '@soluto-home-web/web-theme';
import * as React from 'react';

type ItemContainerProps = {
  fromExpert: boolean;
};

const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  flex-direction: ${(props) => (props.fromExpert ? 'row' : 'row-reverse')};
  max-width: 95%;
  margin-left: ${(props) => (props.fromExpert ? '0' : 'auto')};

  @media ${BreakPoints.desktop} {
    max-width: 72%;
  }
`;

export default ({ fromExpert, children }) => {
  return <ItemContainer fromExpert={fromExpert}>{children}</ItemContainer>;
};
