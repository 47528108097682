import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  text-align: center;
`;

const Title = styled.div`
  font-size: 1.33em !important;
  font-weight: 700 !important;
  color: #343434;
  margin-bottom: 2vh;
`;

type Props = {
  message: string;
};

const EndOfSession: React.FunctionComponent<Props> = ({ message }) => (
  <Container>
    <Title>{message}</Title>
  </Container>
);

export default EndOfSession;
