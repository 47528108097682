import { TimelineItem } from '@soluto-home-web/timeline-core';
import * as React from 'react';
import { branch, compose, lifecycle, renderNothing } from 'recompose';
import { TwoStepResolutionContent } from '../TwoStepResolutionContentType';
import TeamMessage from './TeamMessage';
import { TwoStepResolutionState } from './TwoStepResolutionTimelineItem';
import { withDelay } from './withDelay';

const problemSolvedMsg =
  'Great to hear! Please take a moment to share your feedback.';
const problemNotSolvedMsg =
  'Okay, remember that we’re always here for you when you need us.';
const problemNotSolvedMsg2 =
  'Until then, please take a moment to share your feedback.';
const assistanceStillNeededMsg = (expertBrandingName: string) =>
  `Great, we’ll let you know as soon as you’re matched with a new ${expertBrandingName}.`;

export type FlowEndedMessageProps = TimelineItem<TwoStepResolutionContent> &
  TwoStepResolutionState & {
    expertBrandingName: string;
    setFlowEndedMessagesToShow: (flowEndedMessagesToShow: number) => void;
  };

const getEndingSurveyMsgs = ({
  resolutionAnswer,
  assistanceNeededAnswer,
  expertBrandingName,
}: FlowEndedMessageProps): Array<string> => {
  if (resolutionAnswer === 'Yes') {
    return [problemSolvedMsg];
  }

  if (assistanceNeededAnswer === 'Yes') {
    return [assistanceStillNeededMsg(expertBrandingName)];
  }

  return [problemNotSolvedMsg, problemNotSolvedMsg2];
};

const FlowEndedMessage = (props: FlowEndedMessageProps) => {
  const messages = getEndingSurveyMsgs(props);

  return (
    <div>
      {props.flowEndedMessagesToShow > 0 && (
        <TeamMessage {...props} isGrouped={true} message={messages[0]} />
      )}
      {messages.length > 1 && props.flowEndedMessagesToShow === 2 && (
        <TeamMessage {...props} isGrouped={true} message={messages[1]} />
      )}
    </div>
  );
};

function setMessagesTimer({
  flowEndedMessagesToShow,
  setFlowEndedMessagesToShow,
}: FlowEndedMessageProps) {
  if (flowEndedMessagesToShow < 2) {
    return setTimeout(() => {
      setFlowEndedMessagesToShow(flowEndedMessagesToShow + 1);
    }, 500);
  }
  return undefined;
}

const enhance = compose<FlowEndedMessageProps, FlowEndedMessageProps>(
  lifecycle<
    FlowEndedMessageProps,
    {},
    { _messagesTimerRef: number | undefined }
  >({
    _messagesTimerRef: undefined,
    componentDidUpdate() {
      this._messagesTimerRef = setMessagesTimer(this.props);
    },
    componentDidMount() {
      this._messagesTimerRef = setMessagesTimer(this.props);
    },
    componentWillUnmount() {
      clearTimeout(this._messagesTimerRef);
    },
  }),
  withDelay<FlowEndedMessageProps>()
);

const withVisibility = branch<FlowEndedMessageProps>(
  ({ resolutionAnswer, assistanceNeededAnswer }) =>
    !resolutionAnswer || (resolutionAnswer === 'No' && !assistanceNeededAnswer),
  renderNothing,
  enhance
);

export default withVisibility(FlowEndedMessage);
