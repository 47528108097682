import { mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { from } from 'rxjs/observable/from';

const rxjsconfig = {
  fromESObservable: from,
  toESObservable: (stream: any) => stream,
};

export type FromObservable<T> = Observable<T> | (() => Observable<T>);
export type ToProp = string;
type WithPropFromObservableParams<T> = {
  toProp: ToProp;
  fromObservable$: FromObservable<T>;
};

const getObservable = <T>(
  fromObservable: Observable<T> | (() => Observable<T>)
): Observable<T> =>
  typeof fromObservable === 'function' ? fromObservable() : fromObservable;

/**
 * Add a prop whos value is the last value emitted by an observable
 * @memberof utils
 * @param {object} options
 * @param {Observable} options.fromObservable$ the source observable
 * @param {string} options.toProp the name of the created prop
 * @example
 * import myNamesObservable$ from './observables';
 * import {withPropFromObservable} from '@anywhere-expert/utils'
 *
 * const MyComp = ({myNewTextPropFromObservable}) => (
 *     <h1>{"Hello " + myNewTextPropFromObservable}</h1>
 * )
 *
 * export default withPropFromObservable({fromObservable$: myNamesObservable$, toProp: myNewTextPropFromObservable})(MyComp)
 */
export const withPropFromObservable = <
  TInner extends TOutter,
  TOutter extends {} = {},
  TProp = any
>({
  fromObservable$,
  toProp,
}: WithPropFromObservableParams<TProp>) =>
  mapPropsStreamWithConfig(rxjsconfig)<TInner, TOutter>((props$) =>
    combineLatest(props$, getObservable(fromObservable$), (props, val) =>
      Object.assign({}, props, { [toProp]: val })
    )
  );
