import { TimelineItem } from '@npmsoluto/messaging-contracts';

const valueOrNull = (value) => value || null;

export const itemTypes = {
  TextMessage: 'TextMessage',
  Timestamp: 'Timestamp',
  UserTyping: 'UserTyping',
  AddAnExpert: 'AddAnExpert',
  ContentCard: 'ContentCard',
  ImageItem: 'ImageItem',
  Command: 'Command',
  CommandResult: 'CommandResult',
  AppLink: 'AppLink',
  DynamicComponent: 'DynamicComponent',
  HomeVisitDetails: 'HomeVisitDetails',
  ListPicker: 'ListPicker',
};

export const dynamicComponentTypes = {
  ExpertProfileItem: 'ExpertProfileItem',
  SessionConclusion: 'SessionConclusion',
  VideoItem: 'VideoItem',
};

export default TimelineItem;
