import { monitorClient } from '@soluto-home-web/platform-logger';
import axios from 'axios';

type GeoLocation = {
  CountryId: string;
  CountryName: string;
  CityName: string;
  Region: string;
};

const GEO_LOCATION_SERVICE_URL = 'https://ip2location.mysoluto.com/api/query';

const GEO_LOCATION_REQUEST_TIMEOUT_IN_MS = 5000;

const instance = axios.create({
  baseURL: GEO_LOCATION_SERVICE_URL,
  timeout: GEO_LOCATION_REQUEST_TIMEOUT_IN_MS,
});

const UNKNOWN_GEOLOCATION_PROPERTY: string = 'Unknown';
const UNKNOWN_GEO_LOCATION: GeoLocation = {
  CountryId: UNKNOWN_GEOLOCATION_PROPERTY,
  CountryName: UNKNOWN_GEOLOCATION_PROPERTY,
  CityName: UNKNOWN_GEOLOCATION_PROPERTY,
  Region: UNKNOWN_GEOLOCATION_PROPERTY,
};

let geoLocation: GeoLocation = UNKNOWN_GEO_LOCATION;

async function monitorGeoLocationValue(value: string): Promise<void> {
  await monitorClient.increment(`Geolocation.${value.replace(' ', '-')}`);
}

export async function initialize(): Promise<GeoLocation> {
  try {
    const newGeoLocation = await instance.post('');
    geoLocation = {
      CountryId: newGeoLocation.data.CountryId,
      CountryName: newGeoLocation.data.CountryName,
      CityName: newGeoLocation.data.CityName,
      Region: newGeoLocation.data.Region,
    };
  } catch (exp) {
    geoLocation = UNKNOWN_GEO_LOCATION;
  }

  try {
    monitorGeoLocationValue(`${geoLocation.CountryId}.${geoLocation.CityName}`);
    monitorGeoLocationValue(
      `${geoLocation.CountryId}.${geoLocation.CityName}.${geoLocation.Region}`
    );
    monitorGeoLocationValue(
      `${geoLocation.CountryName}.${geoLocation.CityName}`
    );
    monitorGeoLocationValue(
      `${geoLocation.CountryName}.${geoLocation.CityName}.${geoLocation.Region}`
    );
    monitorGeoLocationValue(`CountryId.${geoLocation.CountryId}`);
    monitorGeoLocationValue(`CountryName.${geoLocation.CountryName}`);
    monitorGeoLocationValue(`CityName.${geoLocation.CityName}`);
    monitorGeoLocationValue(`Region.${geoLocation.Region}`);
  } catch {}

  return geoLocation;
}

export default function getGeoLocation(): GeoLocation {
  return geoLocation;
}
