export type IMonitorWriter = {
  updateValue: (metricName: string, value: number) => Promise<void>;
  increment: (metricName: string) => Promise<void>;
  addTime: (metricName: string, value: number) => Promise<void>;
};

class MonitorWriter implements IMonitorWriter {
  private writers: IMonitorWriter[] = [];

  updateValue = async (metricName: string, value: number): Promise<void> => {
    this.writers.forEach(
      async (writer) => await writer.updateValue(metricName, value)
    );
  }

  increment = async (metricName: string): Promise<void> => {
    this.writers.forEach(async (writer) => await writer.increment(metricName));
  }

  setWriters = (newWriters: IMonitorWriter[]): void => {
    this.writers = newWriters;
  }

  addTime = async (metricName: string, value: number): Promise<void> => {
    this.writers.forEach(
      async (writer) => await writer.addTime(metricName, value)
    );
  }
}

export default new MonitorWriter();
