import FallbackClient from '@npmsoluto/messaging-fallback-client';
import { logger } from '@soluto-home-web/platform-logger';
import * as React from 'react';
import config from './config';
import { messagingFallbackContextTypes } from './types';

interface MessagingFallbackClientProviderProps {
  senderId: string;
  getBearerToken: () => Promise<string>;
  retries?: number;
  pollingInterval: number;
}

export type MessagingFallbackConfigProvider<TInputProps> = (
  props: TInputProps
) => MessagingFallbackClientProviderProps;

class MessagingClientProvider extends React.Component<
  MessagingFallbackClientProviderProps,
  { client: FallbackClient | null }
> {
  static childContextTypes = messagingFallbackContextTypes;

  state = {
    client: null,
  };

  async componentDidMount() {
    const client = new FallbackClient({
      clientId: config.apiKey,
      messagingFallbackUrl: config.baseUrl,
      getBearerToken: this.props.getBearerToken,
      pollingInterval: this.props.pollingInterval,
      withErrorLogging: (
        err,
        message = 'Error in messaging fallback',
        extraData
      ) => {
        logger.warn(message, {
          err,
          extra: extraData && {
            ...extraData,
          },
        });
      },
    });
    this.setState({ client });
  }

  getChildContext() {
    return { messagingFallbackClient: this.state.client };
  }

  render() {
    return this.state.client ? this.props.children : null;
  }
}

export const provideMessagingFallbackClient = <
  TOutter extends MessagingFallbackClientProviderProps & { timelineId: string }
>(
  getConfig: MessagingFallbackConfigProvider<TOutter>
) => (BaseComponent: React.ComponentType<any>) => (props: TOutter) => (
  <MessagingClientProvider {...getConfig(props)}>
    <BaseComponent {...props} />
  </MessagingClientProvider>
);
