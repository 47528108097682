import { queryParamsToAnalyticsEnrichment } from '@soluto-home-web/platform-utils';
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { enrichAnalytics } from 'react-shisell';
import { compose } from 'recompose';
import SwitchWithPerformance from './components/SwitchWithPerformance';
import RenderApp from './RenderApp';
import { AppMainRoutes } from './routes/AppMainRoutes';

const Loading = () => <>Loading..</>;

class App extends React.Component {
  render() {
    performance.mark('Main web app rendered');

    return (
      <Router>
        <Suspense fallback={Loading}>
          <SwitchWithPerformance>{...AppMainRoutes()}</SwitchWithPerformance>
        </Suspense>
      </Router>
    );
  }
}

const CompositeApp = compose(
  enrichAnalytics((dispatcher) => dispatcher.createScoped('ServiceWebsite')),
  queryParamsToAnalyticsEnrichment.withQueryParamsAnalyticsEnrichment
)(App);

RenderApp(CompositeApp);
