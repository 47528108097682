import { styled } from '@soluto-home-web/web-theme';
import dayjs from 'dayjs';
import * as React from 'react';

const Text = styled.span<{ visible: boolean }>`
  font-size: 0.7em !important;
  font-style: italic !important;
  color: #595959;
  margin: 0 10px;
  align-self: flex-end;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 300ms ease-in;
`;

interface TimestampOrSpinnerProps {
  isCurrentlyInFlight: boolean;
  timestamp: number;
}

interface TimestampOrSpinnerState {
  isVisible: boolean;
  showing: 'sending' | 'timestamp';
}

class TimestampOrSendingIndicator extends React.PureComponent<
  TimestampOrSpinnerProps,
  TimestampOrSpinnerState
> {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: true,
      showing: props.isCurrentlyInFlight ? 'sending' : 'timestamp',
    };
  }

  componentWillReceiveProps(nextProps: TimestampOrSpinnerProps) {
    if (this.state.showing === 'timestamp' && !nextProps.isCurrentlyInFlight) {
      return;
    }

    if (!this.props.isCurrentlyInFlight && nextProps.isCurrentlyInFlight) {
      this.setState({ showing: 'sending' });
    }

    if (this.props.isCurrentlyInFlight && !nextProps.isCurrentlyInFlight) {
      this.setState(
        {
          isVisible: false,
        },
        () =>
          setTimeout(
            () => this.setState({ isVisible: true, showing: 'timestamp' }),
            300
          )
      );
    }
  }

  render() {
    const { timestamp } = this.props;
    const { showing, isVisible } = this.state;
    const timestampValue = dayjs(timestamp).format('h:mm A');

    return (
      <>
        <Text visible={isVisible} aria-label={timestampValue}>
          {showing === 'timestamp' ? timestampValue : 'Sending'}
        </Text>
      </>
    );
  }
}

export default TimestampOrSendingIndicator;
