import { logger, monitorClient } from '@soluto-home-web/platform-logger';
import { userAgentParser } from '@soluto-home-web/platform-utils';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import keys from 'ramda.keys';
import mergeAll from 'ramda.mergeall';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import { Context, createTweekClient, ITweekClient } from 'tweek-client';
import { FlatKeys, TweekRepository } from 'tweek-local-cache';
import exponentRefreshErrorPolicy from './exponentRefreshErrorPolicy';
import TweekClientWithFallback from './TweekClientWithFallback';

let TWEEK_URL = 'https://tweek.mysoluto.com';
let TWEEK_FALLBACK_URL = 'https://tweek-west.mysoluto.com';

let _client: ITweekClient;
let _tweekRepository: TweekRepository;
let _currentContext;

type TweekParams = {
  store?: any;
  origin: string;
  expertDetails: { email: string; roles: Array<string>; queues: Array<string> };
  appVersion: string;
};

const createTweekClientWithMonitor = (tweekUrl: string) => {
  const client = createTweekClient({
    baseServiceUrl: tweekUrl,
    useLegacyEndpoint: true,
  });
  client.config.maxChunkSize = 80;
  if (userAgentParser.isIEBrowser()) {
    client.config.maxChunkSize = 10;
  }

  const tweekFetch = client.config.fetch;
  const monitoredFetch = async (
    request: RequestInfo,
    init?: RequestInit
  ): Promise<Response> => {
    const measuringFunction = monitorClient.startMeasureTime();
    const response = await tweekFetch(request, init);
    try {
      measuringFunction.monitor(
        `get-tweek-keys.${tweekUrl.replace('https://', '').split('.')[0]}`
      );
    } catch {}

    return response;
  };
  client.config.fetch = monitoredFetch;

  return client;
};

function initTweekRepo(context: Context, tweekDefaults: FlatKeys) {
  const refreshErrorPolicy = exponentRefreshErrorPolicy(300, 5);
  const fallbackClients = [TWEEK_URL, TWEEK_FALLBACK_URL].map((url) =>
    createTweekClientWithMonitor(url)
  );

  _currentContext = context;

  _client = new TweekClientWithFallback(fallbackClients);
  _tweekRepository = new TweekRepository({
    client: _client,
    refreshErrorPolicy,
    context,
  });

  _tweekRepository.addKeys(tweekDefaults);

  keysToPrepareSubject$.subscribe((key) => _tweekRepository.prepare(key));

  _tweekRepository.expire();
  (window as any).tweekRepo = _tweekRepository;

  return _tweekRepository;
}

const prepareScanKeys = (keys: FlatKeys) =>
  Object.keys(keys)
    .filter((k) => k.slice(-1) === '_')
    .forEach((k) => _tweekRepository.prepare(k));

const getTweekRepository = () => _tweekRepository;

const getFromTweek = async (key: any) => {
  if (!_tweekRepository) {
    throw 'tweek need to be initialized';
  }
  try {
    return await _tweekRepository.getValue(key);
  } catch (e) {
    logger.error(`failed get value from tweek`, e, key);
    throw 'failed get value from tweek';
  }
};

const keysToPrepareSubject$ = new ReplaySubject<string>();

const prepareTweekKey = (key: string) => keysToPrepareSubject$.next(key);

const getMultipleFromTweek = async (paths: {
  [variableName: string]: string;
}) => {
  const promises = keys(paths).map((path) =>
    getFromTweek(paths[path]).then((value) => ({ [path]: value }))
  );

  const values = await Promise.all(promises);

  return mergeAll(values);
};

const appendAndRefreshTweekContext = (context) => {
  let newContext = merge(_currentContext, ...context);

  if (!isEqual(newContext, _currentContext)) {
    _currentContext = newContext;
    _tweekRepository.updateContext(() => _currentContext);
  }
};

export {
  appendAndRefreshTweekContext,
  getMultipleFromTweek,
  prepareTweekKey,
  getFromTweek,
  getTweekRepository,
  initTweekRepo,
  TweekParams,
};
