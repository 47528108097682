import equals from 'ramda.equals';
import pickBy from 'ramda.pickby';
import { mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { empty } from 'rxjs/observable/empty';
import { isInSession$ } from '../firebase/chatSessionApi';
import provider from '../firebase/firebaseProvider';
import rxjsconfig from '../utils/rxjsconfig';

const withoutFuncs = (obj) => pickBy((v, k) => typeof v !== 'function', obj);
export default mapPropsStreamWithConfig(rxjsconfig)(
  (props$: Observable<{ timelineId: string; showHistory: boolean }>) => {
    const sessionStatus$ = props$
      .distinctUntilChanged((a, b) => equals(withoutFuncs(a), withoutFuncs(b)))
      .switchMap((props) => isInSession$(props.timelineId))
      .distinctUntilChanged()
      .startWith(undefined)
      .onErrorResumeNext(empty());

    const providerSignedIn$ = provider.signedIn$.take(1).startWith(undefined);

    return props$.combineLatest(
      sessionStatus$,
      providerSignedIn$,
      (props, isInSession: boolean | undefined, timelineId: string) => ({
        ...props,
        isSignedInAndNotInSession:
          isInSession !== undefined && !isInSession && timelineId,
      })
    );
  }
);
