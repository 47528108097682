import { authenticationApiClient } from '@soluto-home-web/platform-auth';
import { identity } from '@soluto-home-web/platform-identity';
import * as decodeJwt from 'jwt-decode';

const isExpired = (expirationDate: number) => {
  return expirationDate && Date.now() > expirationDate * 1000;
};

const verifyToken = (token: string): boolean => {
  if (!token) {
    return false;
  }

  const payload = decodeJwt(token);

  if (isExpired(payload.exp)) {
    return false;
  }
  if (!payload.uid) {
    return false;
  }

  return true;
};

export const getMessagingApiToken = async (): Promise<string | null> => {
  let jwt = identity.getToken('messaging-token');
  if (!identity.isLoggedIn()) {
    throw 'user not logged in';
  }
  if (!verifyToken(jwt)) {
    const homeUserAccessToken = identity.getHomeUserAccessToken()!; //token must exist if user is logged in
    jwt = await authenticationApiClient.messagingTokenFromHomeUserAccessToken(
      homeUserAccessToken
    );
    identity.setToken('messaging-token', jwt);
  }

  return jwt;
};
