import TextItem from '@soluto-home-web/text-item/TextItem';
import { itemTypes, TimelineItem } from '@soluto-home-web/timeline-core';
import asTimelineItem from '@soluto-home-web/timeline/hoc/asTimelineItem';
import React from 'react';
import styled from 'styled-components';

const GroupedContainer = styled.div`
  margin: 0.37vh 0vh;
`;

export type TeamMessageProps = {
  message: string;
  isGrouped: boolean;
};

const TimelineText = asTimelineItem(TextItem);

const TeamMessage = <T extends {}>(
  props: TeamMessageProps & TimelineItem<T>
) => (
  <GroupedContainer>
    <TimelineText
      {...props}
      senderType="Team"
      type={itemTypes.TextMessage}
      content={props.message}
      contentType={itemTypes.TextMessage}
    />
  </GroupedContainer>
);

export default TeamMessage;
