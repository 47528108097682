import { RefreshErrorPolicy } from 'tweek-local-cache';

const exponentRefreshErrorPolicy = (
  baseExponent: number,
  maxRetries: number
): RefreshErrorPolicy => {
  return (next: () => void, retryCount, _) =>
    maxRetries > retryCount &&
    setTimeout(next, baseExponent * 2 ** (retryCount - 1));
};
export default exponentRefreshErrorPolicy;
