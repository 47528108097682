import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { AnalyticsContext, withAnalytics } from 'react-shisell';
import { compose, withState } from 'recompose';
import Selection from './Selection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const Header = styled.span`
  font-weight: bold;
  color: #343434;
  margin-bottom: 0.5vh;
  margin-top: 1.5vh;
  font-size: 16px;
`;

type OuterProps = {
  primaryColor?: string;
  options: { Text: string; IsSingle: boolean }[];
  headerText?: string;
  onChange?: (selectedOptions: string[]) => void;
  shouldSendAnalyticsOnClick?: boolean;
};

type InnerProps = OuterProps &
  AnalyticsContext & {
    selectedOptions: boolean[];
    setSelectedOptions: (selectedOptions: boolean[]) => void;
  };

type selectionClickedType = {
  i: number;
  options: { Text: string; IsSingle: boolean }[];
  onChange?: (selectedOptions: string[]) => void;
  shouldSendAnalyticsOnClick?: boolean;
} & InnerProps &
  AnalyticsContext;

const keyPressed = (props, i) => (event) => {
  if (event && event.key === 'Enter') {
    event.stopPropagation();
    selectionClicked({ ...props, i })();
  }
};

const selectionClicked = ({
  selectedOptions,
  setSelectedOptions,
  i,
  options,
  onChange,
  analytics,
  shouldSendAnalyticsOnClick = false,
}: selectionClickedType) => () => {
  const clickedOption = options[i];
  let newSelection;
  if (clickedOption.IsSingle) {
    shouldSendAnalyticsOnClick &&
      selectedOptions.forEach((option, index) => {
        option &&
          analytics.dispatcher
            .withExtras({ option: options[index].Text, isEnabled: false })
            .dispatch('Click');
      });

    newSelection = options.map(() => false);
  } else {
    newSelection = options.map((option, index) => {
      if (selectedOptions[index] && option.IsSingle) {
        shouldSendAnalyticsOnClick &&
          analytics.dispatcher
            .withExtras({ option: option.Text, isEnabled: false })
            .dispatch('Click');
        return false;
      }
      return selectedOptions[index];
    });
  }

  newSelection[i] = !selectedOptions[i];
  shouldSendAnalyticsOnClick &&
    analytics.dispatcher
      .withExtras({ option: options[i].Text, isEnabled: newSelection[i] })
      .dispatch('Click');

  setSelectedOptions(newSelection);
  onChange &&
    onChange(newSelection
      .map((option, index) => (option ? options[index].Text : option))
      .filter((x) => x) as string[]);
};

class MultipleSelection extends React.Component<InnerProps, {}> {
  componentDidUpdate(prevProps) {
    if (this.props.options !== prevProps.options) {
      this.props.setSelectedOptions([]);
      this.props.onChange && this.props.onChange([]);
    }
  }

  render() {
    const { selectedOptions, primaryColor, options, headerText } = this.props;

    return (
      <Container>
        {headerText && <Header>{headerText}</Header>}
        <OptionsContainer>
          {options.map((_o, i) => (
            <Selection
              key={i}
              tabIndex={0}
              role="checkbox"
              aria-checked={selectedOptions[i]}
              checked={selectedOptions[i]}
              label={options[i].Text}
              primaryColor={primaryColor}
              onKeyUp={keyPressed(this.props, i)}
              onClick={selectionClicked({ ...this.props, i })}
            />
          ))}
        </OptionsContainer>
      </Container>
    );
  }
}

const enhance = compose<InnerProps, OuterProps>(
  withAnalytics,
  withState('selectedOptions', 'setSelectedOptions', ({ options }) =>
    options.map(() => false)
  )
);
export default enhance(MultipleSelection);
