import Breadcrumb from 'bugsnag-js/types/breadcrumb';
import Report from 'bugsnag-js/types/report';
import forEachObjIndexed from 'ramda.foreachobjindexed';
import keys from 'ramda.keys';
import { IValueRedactor } from '../redactors/IValueRedactor';
import {
  redactArrayValues,
  redactObjectValues,
} from '../redactors/RedactorsHelper';

const redactSimpleValue = (report: Report, redactor: IValueRedactor) => {
  keys(report)
    .filter((key) => !key.startsWith('_') && typeof report[key] === 'string')
    .forEach((key) => {
      if (redactor.shuoldUseRedactor(key, report[key])) {
        report[key] = redactor.redact(key, report[key]);
      }
    });
};

const redactMedatada = (report: Report, redactor: IValueRedactor) => {
  forEachObjIndexed(
    (value, key) =>
      report.updateMetaData(key, redactObjectValues(value, redactor)),
    report.metaData
  );
};

export default (report: Report, redactor: IValueRedactor): boolean | void => {
  redactSimpleValue(report, redactor);

  if (report.metaData) {
    redactMedatada(report, redactor);
  }

  if (report.breadcrumbs) {
    report.breadcrumbs = redactArrayValues<Breadcrumb>(
      report.breadcrumbs,
      redactor
    );
  }
};
