import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { get, update, updated$ } from './crud';
import HomeUser from './HomeUser';
import {
  getHomeUserAccessToken,
  isLoggedIn,
  isLoggedIn$,
  loggedOut$,
  logIn,
  logOut,
} from './loginSession';
import { deleteToken, getToken, setToken } from './tokenManager';

type Identity = {
  updated$: Subject<HomeUser>;
  get: () => HomeUser;
  update: (HomeUser) => void;
  isLoggedIn: () => boolean;
  logOut: () => void;
  getToken: (name: string) => string;
  setToken: (name: string, token: string) => void;
  deleteToken: (name: string) => void;
  loggedOut$: Subject<HomeUser>;
  logIn: (token: string, mdn?: string) => Promise<void>;
  getHomeUserAccessToken: () => string | undefined;
  isLoggedIn$: Observable<boolean>;
};

const identity: Identity = {
  updated$,
  get,
  update,
  isLoggedIn,
  isLoggedIn$,
  logOut,
  setToken,
  getToken,
  deleteToken,
  loggedOut$,
  logIn,
  getHomeUserAccessToken,
};

export default identity;
export { default as HomeUser } from './HomeUser';
export { default as enrichAnalyticsWithUser } from './enrichAnalyticsWithUser';
