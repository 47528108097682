import {
  HomeUser,
  identity,
  UserBrowser,
} from '@soluto-home-web/platform-identity';
import {
  appendAndRefreshTweekContext,
  initTweekRepo,
} from '@soluto-home-web/platform-tweek';
import {
  queryParamsToTweekContext,
  userAgentParser,
} from '@soluto-home-web/platform-utils';
import merge from 'lodash.merge';

const tweekDefaults = require('../tweek-defaults.json');

function updateTweekContextForHomeUser(user: HomeUser, context) {
  if (user) {
    if (user.id) {
      context.home_user['id'] = user.id;
    }
    context.user_browser['IsIdentified'] = true;
    if (user.flowId) {
      context.home_user['MessagingLinkFlow'] = user.flowId;
    }
  }
}

export function initTweek() {
  const queryParamsContext = queryParamsToTweekContext.getContext();
  const staticContext = {
    home_user: {
      Partner: globals.REACT_APP_PARTNER,
      IsTest: globals.REACT_APP_IS_TEST,
      FormFactor: userAgentParser.getFormFactor(),
    },
    user_browser: {
      id: UserBrowser.Id,
      Partner: globals.REACT_APP_PARTNER,
      IsTest: globals.REACT_APP_IS_TEST,
      BrowserType: userAgentParser.getBrowserName(),
      BrowserVersion: userAgentParser.getBrowserVersion(),
      FormFactor: userAgentParser.getFormFactor(),
      OsType: userAgentParser.getOs(),
      Hostname: window && window.location && window.location.hostname,
    },
  };

  const defaultTweekContext = merge(staticContext, queryParamsContext);
  updateTweekContextForHomeUser(identity.get(), defaultTweekContext);
  initTweekRepo(defaultTweekContext, tweekDefaults);

  identity.updated$.subscribe((user) => {
    const context = { home_user: {}, user_browser: {} };
    updateTweekContextForHomeUser(user, context);
    appendAndRefreshTweekContext(context);
  });
}
