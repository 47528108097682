import { ReplaySubject } from 'rxjs/ReplaySubject';
import Store from '../Store';
import HomeUser from './HomeUser';

const store = new Store('userManagement');
export const CURRENT_USER_KEY = 'current-user';
export const IS_LOGIN_KEY = 'is-logged-in';

export const updated$ = new ReplaySubject<HomeUser>(1);

export function update(user: HomeUser) {
  set(user);
  updated$.next(user);
}

export function get(): HomeUser {
  return HomeUser.deserialize(store.get(CURRENT_USER_KEY) || '');
}

function set(user: HomeUser) {
  store.set(CURRENT_USER_KEY, user.serialize());
  store.set(IS_LOGIN_KEY, true);
}

export function remove(): void {
  store.remove(CURRENT_USER_KEY);
  store.set(IS_LOGIN_KEY, false);
}
