import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';

type InnerProps = { fill: string; opacity: string };

const SvgWrapper: any = styled.svg.attrs<InnerProps>({
  fill: (props) =>
    props.selected || props.hovered ? props.theme.colors.primary : '#949494',
  opacity: (props) => (props.hovered ? '0.5' : '1.0'),
})`
  .brandColor {
    fill: ${(props) => props.fill};
  }
  &:hover {
    cursor: pointer;
  }
`;
type Props = {
  selected: boolean;
  hovered: boolean;
};

const BrandedStar: React.FunctionComponent<Props> = ({ selected, hovered }) => (
  <SvgWrapper
    xmlns="http://www.w3.org/2000/svg"
    width="51"
    height="29"
    viewBox="0 0 27 26"
    selected={selected}
    hovered={hovered}
  >
    <path
      className="brandColor"
      fillRule="evenodd"
      d="M25.457 11.637c.217-.327.543-.655.543-1.091 0-.764-.76-1.31-1.304-1.31L18.5 8.365c-.217 0-.869-.436-.978-.654l-2.826-5.673c-.109-.436-.543-.873-1.196-.873-.652 0-1.086.437-1.304.873L9.479 7.71c-.109.218-.761.654-.979.654l-6.195.982C1.761 9.346 1 9.782 1 10.655c0 .437.218.873.544 1.09L6 16.11c.218.109.435.654.435.982v.109l-1.087 6.218v.327c0 .327.109.655.326.982.109.218.544.436.87.436.326 0 .543-.11.761-.218L12.848 22c.109-.11.435-.11.652-.11.218 0 .544 0 .653.11l5.543 2.945c.218.109.544.218.761.218.326 0 .761-.11.978-.436.218-.327.326-.655.326-.873v-.327l-1.087-6.218v-.11c0-.327.218-.872.435-.981l4.348-4.582z"
    />
  </SvgWrapper>
);

export default BrandedStar;
