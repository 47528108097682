import SessionConclusion from '@npmsoluto/session-conclusion-web-component';
import { TimelineItem } from '@soluto-home-web/timeline-core';
import { BreakPoints, styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { AnalyticsContext, withAnalytics } from 'react-shisell';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';

type SessionConclusionTimelineProps = {
  preMessage: string;
  summary: string;
  postMessage: string;
  endGreeting: string;
  expertDisplayName: string;
  expertImageUrl: string;
  expertRating: number;
  senderThumbUrl: string;
  partnerIcon: string;
};

type EnhancedProps = AnalyticsContext & {
  theme: object;
};

type Props = TimelineItem<SessionConclusionTimelineProps> & {
  theme: any;
} & EnhancedProps;

const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${BreakPoints.mobile} {
    transform: scale(0.87);
  }
  @media ${BreakPoints.desktop} {
    transform: scale(1);
  }
`;

class SessionConclusionContainer extends React.PureComponent<Props> {
  componentDidMount() {
    const { analytics, content } = this.props;
    if (analytics) {
      analytics.dispatcher
        .createScoped('Timeline')
        .createScoped('ConclusionItem')
        .withExtras({
          SummaryText: content.summary,
        })
        .dispatch('View');
    }
  }

  render() {
    const {
      preMessage,
      summary,
      postMessage,
      endGreeting,
      expertDisplayName,
      expertRating,
      expertImageUrl,
      partnerIcon,
    } = this.props.content;

    const { theme, contentId } = this.props;

    const colors = {
      starColor: theme.colors.primary,
      borderColor: '#E7E7E7',
      fontColor: 'black',
    };

    return (
      <div tabIndex={0} role="text">
        <Container>
          <SessionConclusion
            contentId={contentId}
            largestFontSize={15}
            partnerIcon={partnerIcon}
            preMessage={preMessage}
            summary={summary}
            postMessage={postMessage}
            endGreeting={endGreeting}
            expertImageUrl={expertImageUrl}
            expertDisplayName={expertDisplayName}
            expertRating={expertRating}
            colors={colors}
          />
        </Container>
      </div>
    );
  }
}

export default compose<Props, EnhancedProps>(
  withAnalytics,
  withTheme
)(SessionConclusionContainer);
