import {
  sendExpertItem,
  sendItem,
  TimelineItem,
} from '@soluto-home-web/timeline-core';

type contentType = {
  message: string;
  subMessage: string;
  sessionId: string;
};

export const triggerDynamicFlow = (
  messageId: string,
  content,
  timelineId: string
) => {
  return sendItem(timelineId, {
    contentId: `StarSurveyTimelineItem${content.sessionId}`,
    senderThumbUrl: 'teamIcon',
    contentType: 'DynamicComponent',
    senderName: 'Team',
    senderType: 'Team',
    url: 'StarSurveyTimelineItem',
    content,
  });
};

export const triggerSystemMessageFlow = (
  messageId: string,
  content: contentType,
  timelineId: string
) => {
  const timelineItem = {
    contentId: `${messageId}${content.sessionId}`,
    contentType: 'SystemMessageForExpert',
    content,
  };

  sendExpertItem(timelineId, timelineItem);
};
