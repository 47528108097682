import ExpertProfile from '@npmsoluto/expert-profile-web-component';
import { TimelineItem } from '@soluto-home-web/timeline-core';
import * as React from 'react';
import { AnalyticsContext, withAnalytics } from 'react-shisell';
import { compose } from 'recompose';
import { withTheme } from 'styled-components';

type ExpertProfileTimelineProps = {
  techName: string;
  expertRating: {
    value: number;
    threshold: number;
  };
  resolution: {
    value: number;
    threshold: number;
  };
  deviceModel: string;
  senderThumbUrl: string;
};

type EnhancedProps = AnalyticsContext & {
  theme: Theme;
};

type Props = TimelineItem<ExpertProfileTimelineProps> & EnhancedProps;

type State = {
  isAnimated: boolean;
};

class ExpertProfileContainer extends React.PureComponent<Props, State> {
  state = {
    isAnimated: false,
  };

  componentDidMount() {
    const { metadata, analytics, content } = this.props;

    if (metadata && !metadata.isViewed) {
      const { resolution, expertRating, deviceModel } = content;

      this.setState({ isAnimated: true });
      analytics.dispatcher
        .withExtra('Section', 'ExpertProfile')
        .withExtra('StarRating', expertRating.value)
        .withExtra('SessionSolvedNum', resolution.value)
        .withExtra('Expertise', 'tech')
        .dispatch('SectionView');
    }
  }
  render() {
    const {
      content: {
        techName,
        expertRating,
        resolution,
        deviceModel,
        senderThumbUrl,
      },
      theme,
    } = this.props;
    const { isAnimated } = this.state;

    return (
      <div tabIndex={0} role="text">
        <ExpertProfile
          techName={techName}
          rating={expertRating}
          resolution={resolution}
          deviceModel={deviceModel}
          senderThumbUrl={senderThumbUrl}
          isAnimated={isAnimated}
          colors={{
            primary: theme.colors.primary,
            // Special case, if its Sprint then use black icons.
            icon:
              theme.colors.primary.toLowerCase() === '#ffcf0a'
                ? '#000'
                : '#FFF',
          }}
        />
      </div>
    );
  }
}

export default compose<Props, EnhancedProps>(
  withAnalytics,
  withTheme
)(ExpertProfileContainer);
