import * as React from 'react';
import styled from 'styled-components';
const handIcon = require('./Hand.svg');

const HandStyledIcon = styled.img`
  width: 12px;
  height: 12px;
  color: #4f4f4f;
  padding-left: 0.2em;
`;

const Hand = () => <HandStyledIcon src={handIcon} alt="" />;
export default Hand;
