import FallbackClient from '@npmsoluto/messaging-fallback-client';
import SocketClient from '@npmsoluto/soluto-messaging-client';
import PropTypes from 'prop-types';

export type MessageSender = (message: string) => Promise<void>;

export type MessagingFallbackProps = {
  messagingFallbackClient: FallbackClient;
};

export const messagingFallbackContextTypes = {
  messagingFallbackClient: PropTypes.object,
};

export type MessageSenderProps = {
  sendMessage: MessageSender;
};
