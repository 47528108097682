import uuid from 'uuid';
import firebaseProvider from './firebaseProvider';

const currentUploads = {};

const createCancelError = () => {
  const err = new Error('Upload canceled');
  (err as any).code = 'storage/canceled';

  return err;
};

const UploadStatus = {
  canceled: 'upload_canceled',
  start: 'upload_started',
  completed: 'upload_completed',
};

const getUploadStatus = (key) =>
  currentUploads[key] && currentUploads[key].status;

const addUploadTask = (key, uploadTask) => {
  if (!currentUploads[key]) {
    currentUploads[key] = {
      status: UploadStatus.start,
      tasks: [],
    };
  }

  currentUploads[key].tasks.push(uploadTask);
};

const uploadToTimelineStorage = async (
  bucketUrl: string,
  timelineId: string,
  contentId: string,
  imageData: File | Blob,
  imageType: string,
  maxCacheAgeInSeconds: number
) => {
  if (getUploadStatus(contentId) === UploadStatus.canceled) {
    throw createCancelError();
  }

  const mime = `image/${imageType}`;
  const imageName = `${contentId}_${uuid()}.${imageType}`;
  const storage = firebaseProvider.app!.storage(bucketUrl);
  const imageRef = storage
    .ref('image')
    .child(timelineId)
    .child(imageName);
  const uploadTask = imageRef.put(imageData, {
    contentType: mime,
    cacheControl: `max-age=${maxCacheAgeInSeconds}`,
  });

  addUploadTask(contentId, uploadTask);
  await uploadTask;

  return await imageRef.getDownloadURL();
};

const cancelUpload = (key) => {
  if (!currentUploads[key]) {
    currentUploads[key] = { status: UploadStatus.canceled };
  } else {
    currentUploads[key].status = UploadStatus.canceled;
    const uploadTasks = currentUploads[key].tasks;

    if (uploadTasks) {
      for (var uploadTask of uploadTasks) {
        uploadTask.cancel();
      }
    }
  }
};

export { UploadStatus, getUploadStatus, cancelUpload, uploadToTimelineStorage };
