import { PartnerButton } from '@soluto-home-web/platform-common-ui';
import TimelineTheme from '@soluto-home-web/timeline-theme/TimelineTheme';
import React from 'react';
import styled, { withTheme } from 'styled-components';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  margin-top: 1em;
`;

const CancelButton = styled(PartnerButton)`
  background: transparent;
  color: ${(props) => props.theme.colors.primary_active};
  border: 1px solid ${(props) => props.theme.colors.primary_active};
  margin-right: 0.5em;
`;

const AllowButton = styled(PartnerButton)`
  margin-left: 0.5em;
`;

export type PermissionRequestProps = {
  onResult: (allowed: boolean) => void;
};

const PermissionRequest = ({
  onResult,
  theme,
}: PermissionRequestProps & { theme: TimelineTheme }) => (
  <div>
    <div tabIndex={0}>
      Your {theme.texts.partner_expert_name} would like to run a battery max
      capacity test
    </div>
    <ButtonsContainer>
      <CancelButton
        onClick={() => onResult(false)}
        analyticsExtras={{ actionId: 'dontAllowPermission' }}
      >
        Don't allow
      </CancelButton>
      <AllowButton
        onClick={() => onResult(true)}
        analyticsExtras={{ actionId: 'allowPermission' }}
      >
        Allow
      </AllowButton>
    </ButtonsContainer>
  </div>
);

export default withTheme(PermissionRequest);
