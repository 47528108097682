class AuthenticationApiClient {
  private baseUrl = 'https://homeuserauth.mysoluto.com';

  private async fetchAccessToken(params: object) {
    const body = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join('&');

    const res = await fetch(`${this.baseUrl}/connect/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body,
    });

    const json = await res.json();
    if (json.error || res.status !== 200 || !json.access_token) {
      const err = new Error(`Failed to fetch token - ${json.error}`);
      err['status'] = res.status;
      err['errorMessage'] = json.error;
      throw err;
    }

    return json.access_token;
  }

  async homeUserMessagingTokenFromTemporaryCodeGrant(
    code: string
  ): Promise<string> {
    const params = {
      grant_type: 'home-user-messaging-token',
      scope: 'home-user-messaging-resource home-user-identity',
      client_id: 'home-user-messaging',
      client_secret: '1144b683-613d-4b56-8133-2604a5a78057',
      code,
    };

    return this.fetchAccessToken(params);
  }

  async homeUserAccessTokenFromTemporaryCodeGrant(
    code: string
  ): Promise<string> {
    const params = {
      grant_type: 'home-user-access-token-from-temporary-code',
      scope: 'home-user-resource',
      client_id: 'home-user-token',
      client_secret: 'ab944171-b2ab-4526-b94b-cfad5f1e45a1',
      code,
    };

    return this.fetchAccessToken(params);
  }

  async homeUserMessagingTokenFromOneTimeAuthCodeGrant(
    partnerBrandId: string,
    oneTimeCode: string,
    countryCode: string,
    mdn: string
  ): Promise<string> {
    const params = {
      grant_type: 'home-user-messaging-token-from-one-time-auth-code',
      scope: 'home-user-messaging-resource home-user-identity',
      client_id: 'home-user-messaging',
      client_secret: '1144b683-613d-4b56-8133-2604a5a78057',
      oneTimeCode,
      countryCode,
      mdn,
      partnerBrandId,
      isTest: globals.REACT_APP_IS_TEST,
    };

    return this.fetchAccessToken(params);
  }

  async homeUserAccessTokenFromMessagingGrant(token: string): Promise<string> {
    const params = {
      grant_type: 'home-user-token-from-messaging-token',
      scope: 'home-user-resource',
      client_id: 'home-user-token-from-messaging-token',
      client_secret: '1c8ae121-c11e-43c7-a19b-2a1a0f6a51d9',
      token,
    };

    return this.fetchAccessToken(params);
  }

  async homeUserAccessTokenFromOneTimeAuthCodeGrant(
    partnerBrandId: string,
    oneTimeCode: string,
    countryCode: string,
    mdn: string
  ): Promise<string> {
    const params = {
      grant_type: 'home-user-access-token-from-one-time-auth-code',
      scope: 'home-user-resource',
      client_id: 'home-user-token',
      client_secret: 'ab944171-b2ab-4526-b94b-cfad5f1e45a1',
      oneTimeCode,
      countryCode,
      mdn,
      partnerBrandId,
      isTest: globals.REACT_APP_IS_TEST,
    };

    return this.fetchAccessToken(params);
  }

  async messagingTokenFromHomeUserAccessToken(token: string): Promise<string> {
    const params = {
      grant_type: 'messaging-token-from-home-user-token',
      scope: 'home-user-messaging-resource home-user-identity',
      client_id: 'home-user-messaging',
      client_secret: '1144b683-613d-4b56-8133-2604a5a78057',
      token,
    };

    return this.fetchAccessToken(params);
  }
}

export default new AuthenticationApiClient();
