import firebase from 'firebase';
import { Observable } from 'rxjs/Observable';
import { fromEventPattern } from 'rxjs/observable/fromEventPattern';

type SnapshotHandler = (a: firebase.database.DataSnapshot, b?: string) => any;

export default (
  ref: firebase.database.Reference,
  eventType
): Observable<firebase.database.DataSnapshot> =>
  fromEventPattern(
    (handler: SnapshotHandler) => ref.on(eventType, handler),
    (handler: SnapshotHandler) => ref.off(eventType, handler)
  );
