import React from 'react';
import { compose, withHandlers } from 'recompose';
import { withTheme } from 'styled-components';
import { DecisionButtons } from '../DecisionButtons';
import CheckMark from './CheckMark';
import CloseMark from './CloseMark';

type ResolutionButtonsProps = {
  handleQuestionSelection: (selection: String) => void;
};

type HandlersType = {
  onYesClick: () => void;
  onNoClick: () => void;
};

type InnerProps = ResolutionButtonsProps &
  HandlersType & {
    theme: Theme;
  };

const ResolutionButtons = (props: InnerProps) => (
  <DecisionButtons
    {...props}
    yesButtonTitle={'Yes'}
    noButtonTitle={'No'}
    yesButtonChild={<CheckMark color={props.theme.colors.foreground} />}
    noButtonChild={<CloseMark color={props.theme.colors.foreground} />}
  />
);

const enhance = withHandlers<
  { handleQuestionSelection: (selection: String) => void },
  HandlersType
>({
  onYesClick: ({ handleQuestionSelection }) => () =>
    handleQuestionSelection('Yes'),
  onNoClick: ({ handleQuestionSelection }) => () =>
    handleQuestionSelection('No'),
});

export default compose<InnerProps, ResolutionButtonsProps>(
  enhance,
  withTheme
)(ResolutionButtons);
