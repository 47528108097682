import { itemTypes } from '@soluto-home-web/timeline-core';
import dayjs from 'dayjs';
import { mapProps } from 'recompose';

const getTimestampText = (timestamp, nowTimestamp?: any) => {
  let time = dayjs(timestamp)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);
  let today = dayjs(nowTimestamp)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0);

  let daysAgo = today.diff(time, 'day');

  if (daysAgo === 0) {
    return 'Today';
  } else if (daysAgo === 1) {
    return 'Yesterday';
  }

  return time.format('MMMM DD');
};

const shouldRenderTimestamp = (currentTimestamp, prevTimestamp) => {
  if (
    prevTimestamp === undefined ||
    currentTimestamp === undefined ||
    prevTimestamp > currentTimestamp
  ) {
    return false;
  }

  let currentDate = dayjs(currentTimestamp)
    .hour(0)
    .minute(0)
    .second(0);
  let prevDate = dayjs(prevTimestamp)
    .hour(0)
    .minute(0)
    .second(0);

  return !currentDate.isSame(prevDate, 'day');
};

const createTimestampItemModel = (timestamp) => ({
  timestamp,
  text: getTimestampText(timestamp),
  contentId: timestamp,
  contentType: itemTypes.Timestamp,
});

const withTimestampTimelineItems = mapProps(({ timelineItems, ...other }) => {
  const result: any[] = [];
  for (let i = 0; i < timelineItems.length; i++) {
    const currentTimestamp = timelineItems[i].timestamp;
    const prevTimestamp = i !== 0 ? timelineItems[i - 1].timestamp : undefined;

    if (shouldRenderTimestamp(currentTimestamp, prevTimestamp)) {
      result.push(createTimestampItemModel(currentTimestamp));
    }

    result.push(timelineItems[i]);
  }

  return { timelineItems: result, ...other };
});

export default withTimestampTimelineItems;
