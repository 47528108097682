import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import { Routes } from './BaseRoutes';

function mergeAndOverrideRoutes(
  routes: React.ReactElement<RouteProps>[],
  routesToMerge: React.ReactElement<RouteProps>[]
): React.ReactElement<RouteProps>[] {
  return routes.concat(routesToMerge).reduce((
    pre: React.ReactElement<RouteProps>[],
    curr
  ) => {
    pre = pre.filter((route) => route.props.path !== curr.props.path);
    pre.push(curr);

    return pre;
  }, []);
}

const attachIndexToRoutes = (array: React.ReactElement<any>[]) =>
  array.map((item, index) =>
    React.cloneElement(item, { key: index.toString() })
  );

export const AppMainRoutes = () => {
  const AsyncPartnerRoutes = require(`./per-partner/${
    globals.REACT_APP_PARTNER
  }Routes`);
  const merged = mergeAndOverrideRoutes(
    Routes,
    AsyncPartnerRoutes.PartnerRoutes
  );

  return attachIndexToRoutes(merged);
};
