export type MarkName = {
  startMark: string;
  endMark: string;
};

export function generateMarkName(pathname: string): MarkName {
  const cleanPathName = pathname.split(/[\s\/-]+/).join('_');
  const endMarkPrefix = `${cleanPathName}-end`;
  const callsCounter = window.performance
    .getEntriesByType('mark')
    .filter((mark) => mark.name.indexOf(endMarkPrefix) > -1).length;

  return {
    startMark: `${cleanPathName}-${callsCounter}`,
    endMark: `${cleanPathName}-end-${callsCounter}`,
  };
}
