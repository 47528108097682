import { UserBrowser } from '@soluto-home-web/platform-identity';
import { userAgentParser } from '@soluto-home-web/platform-utils';
import { sendExpertItem, updateItem } from '@soluto-home-web/timeline-core';
import * as React from 'react';
import * as uuid from 'uuid';
import { usePersistentState } from '../../shared/behaviors/usePersistentState';
import { SecondScreenTimelineProps } from '../index';
import { DecisionButtons } from './DecisionButtons';

const SecondScreenQuestion = (props: SecondScreenTimelineProps) => {
  const [
    sentNewDeviceNotification,
    setSentNewDeviceNotification,
  ] = usePersistentState<boolean>(
    'sentNewDeviceNotification',
    props,
    props.timelineId
  );
  const [addDeviceAnswer, setAddDeviceAnswer] = usePersistentState<string>(
    'addDeviceAnswer',
    props,
    props.timelineId
  );

  const {
    timelineId,
    content,
    sessionId,
    contentType,
    contentId,
    analytics,
    sendMessage,
  } = props;

  const sendResponseMessage = async (message: string) => {
    setAddDeviceAnswer(message);
    analytics.dispatcher.withExtra('userResponse', message).dispatch('Reply');
    await sendMessage(message);
  };
  const browserId = UserBrowser.Id;

  //this checks if we are connecting for the 1st time and logs the browser id in that case.
  if (!content || !content.initialBrowserId) {
    updateItem(timelineId, contentType, contentId, {
      content: {
        initialBrowserId: browserId,
      },
    });
  } else if (
    content.initialBrowserId !== browserId &&
    !sentNewDeviceNotification
  ) {
    analytics.dispatcher.dispatch('SwitchedDevice');
    //in case we connected from 2nd device we notify the expert
    const formFactorToDeviceName = {
      Desktop: 'computer',
      Mobile: 'smartphone',
      Tablet: 'tablet',
    };

    const newDevice = formFactorToDeviceName[userAgentParser.getFormFactor()];
    const content = {
      message: `The customer successfully connected with a ${newDevice}`,
      sessionId: sessionId,
    };
    const timelineItem = {
      contentId: uuid.v4(),
      contentType: 'SystemMessageForExpert',
      content,
    };
    sendExpertItem(timelineId, timelineItem);
    setSentNewDeviceNotification(true);
  }

  if (!addDeviceAnswer) {
    return (
      <DecisionButtons
        buttonsTitle={props.userResponses}
        buttonsOnClick={sendResponseMessage}
      />
    );
  }
  return null;
};

export default SecondScreenQuestion;
