import { logger } from '@soluto-home-web/platform-logger';
import { getStoreItem, TimelineStoreValues } from '../core/store';
import { default as TimelineItem } from '../models/TimelineItem';

export default (): TimelineItem<any>[] => {
  let cachedItems = [] as TimelineItem<any>[];

  try {
    cachedItems = getStoreItem(TimelineStoreValues.timelineCache) || [];
  } catch (err) {
    logger.error('failed to load timeline cache from storage', err);
  }

  return cachedItems;
};
