import pickBy from 'ramda.pickby';
import { branch, compose, mapProps, mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { empty } from 'rxjs/observable/empty';
import rxjsconfig from '../utils/rxjsconfig';

import { logger } from '@soluto-home-web/platform-logger';

import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import provider from '../firebase/firebaseProvider';
import { forTimeline, getTimeline } from '../firebase/timelineApi';
import { withMessagesAsTimelineItems } from '../messaging-api/with-messages-as-timeline-items';
import { withMessagingRestClient } from '../messaging-api/with-messaging-rest-client-provider';
import { withFallbackMessagesAsTimelineItems } from '../messaging-fallback/with-fallback-messages-as-timeline-items';
import MessagingProviderType from '../utils/MessagingProviderType';

const withoutFuncs = (obj) => pickBy((v, k) => typeof v !== 'function', obj);

const fromFirebase = compose(
  withMessagingRestClient,
  mapPropsStreamWithConfig(rxjsconfig)(
    (
      props$: Observable<{
        timelineId: string;
        showHistory: boolean;
        messagingRestClient: { markMessageAsSeen: (roomId, messageId) => void };
      }>
    ) => {
      const timelineItems$ = props$
        .distinctUntilKeyChanged('timelineId')
        .switchMap((props) =>
          provider.signedIn$
            .do((id) => logger.warn('SignedIn', { timelineId: id }))
            .filter((id) => id === props.timelineId)
            .distinctUntilChanged()
            .switchMap(() =>
              getTimeline(forTimeline(props.timelineId)!, props.showHistory)
            )
        )
        .startWith([])
        .catch((err) => {
          logger.warn('Got into the onErrorResumeNext', {
            message: err.message,
          });

          return empty();
        });

      return props$.combineLatest(
        timelineItems$,
        (props, timelineItems: any[]) => {
          return {
            ...props,
            timelineItems: [...timelineItems],
          };
        }
      );
    }
  )
);

export default compose(
  withTweekKeys('support/session/messaging/provider', {
    propName: 'messagingProvider',
  }),
  withTweekKeys(
    'support/session/messaging/messaging_api/supported_content_types',
    {
      propName: 'messagingSupportedTypes',
      initialValue: [],
    }
  ),
  branch<{ messagingProvider: string }>(
    ({ messagingProvider }) =>
      messagingProvider === MessagingProviderType.MessagingApi,
    withMessagesAsTimelineItems,
    branch<{ messagingProvider: string }>(
      ({ messagingProvider }) =>
        messagingProvider === MessagingProviderType.Fallback,
      withFallbackMessagesAsTimelineItems,
      fromFirebase
    )
  ),
  mapProps(({ messagingSupportedTypes, ...other }) => other)
);
