import { camelize } from 'humps';
import equals from 'ramda.equals';
import { withTweekValues } from 'react-tweek';
import { ComponentEnhancer, compose, lifecycle, mapProps } from 'recompose';

export type LegacyWithTweekKeysOptions = {
  propName?: string;
  once?: boolean;
  mergeProps?: boolean;
  initialValue?: any;
};

const pure = lifecycle({
  shouldComponentUpdate(prevProps) {
    return !equals(prevProps, this.props);
  },
});

const spreadTweekValues = mapProps(({ _tweekValues, ...props }) => ({
  ...props,
  ..._tweekValues,
}));

export const withTweekKeys = <TInner, TOutter>(
  keyPath: string,
  { propName, initialValue }: LegacyWithTweekKeysOptions = {}
): ComponentEnhancer<TInner, TOutter> => {
  const configName = keyPath.split('/').pop();
  const isScanKey = configName === '_';
  const spreadProps = isScanKey && !propName;
  if (!propName) {
    propName = isScanKey ? '_tweekValues' : (camelize(configName) as string);
  }

  const mapping = { [propName]: keyPath };
  const options: any = {};
  if (initialValue !== undefined) {
    options.defaultValues = { [propName]: initialValue };
  }

  const enhance = withTweekValues(mapping, options);

  const hocs: ((
    component: React.ComponentType<any>
  ) => React.ComponentType<any>)[] = [pure, enhance];

  if (spreadProps) {
    hocs.push(spreadTweekValues);
  }

  return compose<TInner, TOutter>(...hocs);
};
