import urlNormalizer from './urlNormalizer';

export const ORIGIN_LOCAL_STORAGE_KEY_NAME = 'CUSTOMER_ORIGIN';
export const UNKNOWN_ORIGIN = 'Unknown';

export interface OriginData {
  current: string;
  inital: string;
}

export default class OriginIdentifier {
  private static _current: string;
  private static _inital: string;

  public static initialize(referrer?: string): void {
    OriginIdentifier._current =
      referrer && urlNormalizer(referrer)
        ? urlNormalizer(referrer)
        : UNKNOWN_ORIGIN;
    OriginIdentifier._inital = this.getInital();
  }

  public static get origin(): OriginData {
    return {
      current: OriginIdentifier._current,
      inital: OriginIdentifier._inital,
    };
  }

  private static getInital(): string {
    let inital: string | null;
    try {
      inital = localStorage.getItem(ORIGIN_LOCAL_STORAGE_KEY_NAME);
      if (!inital) {
        inital = OriginIdentifier._current;
        localStorage.setItem(ORIGIN_LOCAL_STORAGE_KEY_NAME, inital);
      }
    } catch {
      inital = OriginIdentifier._current;
    }

    return inital;
  }
}
