import { identity } from '@soluto-home-web/platform-identity';
import { enrichAnalytics } from 'react-shisell';
import { AnalyticsDispatcher } from 'shisell';
import { queryParams } from './query-params-provider';

export function enrich(
  analyticsDispatcher: AnalyticsDispatcher
): AnalyticsDispatcher {
  const identitiesEnrichment: object = {
    MessageId: queryParams.messageId.value,
  };

  const currentLoggedInHomeUser = identity.get();
  if (!currentLoggedInHomeUser || !currentLoggedInHomeUser.id) {
    identitiesEnrichment['HomeUserId'] = queryParams.homeUserId.value;
  }

  return analyticsDispatcher.withIdentities(identitiesEnrichment).withExtras({
    FlowId: queryParams.flowId.value,
    Intent: queryParams.intent.value,
  });
}

export const withQueryParamsAnalyticsEnrichment = enrichAnalytics(
  (analyticsDispatcher: AnalyticsDispatcher) => {
    return enrich(analyticsDispatcher);
  }
);
