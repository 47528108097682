import { updateItem } from '@soluto-home-web/timeline-core';
import { useEffect, useState } from 'react';
import { Dispatch } from 'react';
import { SetStateAction } from 'react';

export function usePersistentState<S>(
  fieldName,
  item,
  timelineId
): [S, Dispatch<SetStateAction<S>>] {
  const [persistentState, setPersistentState] = useState<S>(item[fieldName]);

  useEffect(() => {
    if (persistentState) {
      updateItem(timelineId, item.contentType, item.contentId, {
        [fieldName]: persistentState,
      });
    }
  }, [persistentState]);

  return [persistentState, setPersistentState];
}
