import getComponent from '@soluto-home-web/dynamic-component';
import { logger } from '@soluto-home-web/platform-logger';
import { TimelineItem, withSessionId } from '@soluto-home-web/timeline-core';
import React, { Component, ErrorInfo } from 'react';

type State = {
  error: boolean;
};

class DynamicComponentItem extends Component<TimelineItem<{}>, State> {
  state = {
    error: false,
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error: true });
    let extraDetails: any = { url: this.props.url };

    // TODO: Preact doesn't send ErrorInfo right now, maybe in the future.
    if (errorInfo) {
      extraDetails.componentStack = errorInfo.componentStack;
    }

    logger.error('failed rendering dynamic component', error, extraDetails);
  }

  render() {
    if (this.state.error || !this.props.url) {
      return null;
    }

    const Component = getComponent(this.props.url);

    if (!Component) {
      return null;
    }

    return <Component {...this.props} />;
  }
}

export default withSessionId(DynamicComponentItem);
