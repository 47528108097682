import {
  enrichAnalyticsWithUser,
  identity,
  UserBrowser,
} from '@soluto-home-web/platform-identity';
import { logger } from '@soluto-home-web/platform-logger';
import { userAgentParser } from '@soluto-home-web/platform-utils';
import { analytics } from 'react-shisell';
import { AnalyticsEventModel } from 'shisell';
import * as analyticsWritersObject from './analyticsWriters';
import { IAnalyticsWriter } from './analyticsWriters/IAnalyticsWriter';
import { initialize as initializeGeoLocation } from './geo-location-api';
import OriginIdentifier from './originIdentifier';
const meta = require('../../build-tools/public/meta.json');

let _writers: IAnalyticsWriter[] | null = null;

const isWriterEnabled = (writer: IAnalyticsWriter): Promise<boolean> => {
  try {
    return writer.isEnabled();
  } catch {
    return Promise.resolve(false);
  }
};

const getEnabledWriters = async (): Promise<IAnalyticsWriter[]> => {
  const analyticWriters: IAnalyticsWriter[] = Object.keys(
    analyticsWritersObject
  )
    .map((key) => analyticsWritersObject[key])
    .filter((writer) => writer.write && writer.isEnabled);

  const enabledWritersPromise: Promise<boolean>[] = analyticWriters.map(
    isWriterEnabled
  );

  const enabledWriters = await Promise.all(enabledWritersPromise);

  return analyticWriters.filter((_, index) => enabledWriters[index]);
};

let _writersPromise: Promise<IAnalyticsWriter[]> | undefined = undefined;
const writer = async (eventModel: AnalyticsEventModel) => {
  if (_writers == null) {
    _writersPromise = _writersPromise || getEnabledWriters();
    _writers = await _writersPromise;
  }

  await Promise.all(_writers.map((writer) => writer.write(eventModel)));
};

export const initAnalytics = () => {
  analytics.setWriter(writer);
  const userBrowserId = UserBrowser.Id;

  analytics.transformDispatcher((dispatcher) => {
    dispatcher = dispatcher
      .withFilter((dispatcher) => {
        (dispatcher as any).ExtraData.Url = window.location.href.split('?')[0];

        return dispatcher;
      })
      .withIdentity('UserBrowserId', userBrowserId)
      .withMeta('IsTest', globals.REACT_APP_IS_TEST)
      .withExtra('IsTest', globals.REACT_APP_IS_TEST)
      .withExtra('AppName', 'SolutoHomeWeb')
      .withExtra('OsType', userAgentParser.getOs())
      .withExtra('OsVersion', userAgentParser.getOsVersion())
      .withExtra('BrowserType', userAgentParser.getBrowserName())
      .withExtra('BrowserVersion', userAgentParser.getBrowserVersion())
      .withExtra('ScreenWidth', window.screen.width)
      .withExtra('ScreenHeight', window.screen.height)
      .withExtra('CurrentOrigin', OriginIdentifier.origin.current)
      .withExtra('InitialOrigin', OriginIdentifier.origin.inital)
      .withExtra('EnvironmentPartner', globals.REACT_APP_PARTNER)
      .withExtra('CarrierName', globals.REACT_APP_PARTNER)
      .withExtra(
        'MessagingPartner',
        globals.REACT_APP_MESSAGING_PARTNER || globals.REACT_APP_PARTNER
      )
      .withExtra('IsIdentified', identity.isLoggedIn())
      .withExtra('DeviceFormFactor', userAgentParser.getFormFactor())
      .withExtra('WebsiteVersion', meta.version);

    dispatcher = enrichAnalyticsWithUser(dispatcher, identity.get());

    return dispatcher;
  });

  identity.updated$.subscribe((user) => {
    analytics.transformDispatcher((d) => enrichAnalyticsWithUser(d, user));
  });

  initializeGeoLocation()
    .then((geoLocation) => {
      analytics.transformDispatcher((dispatcher) =>
        dispatcher
          .withExtra('CountryId', geoLocation.CountryId)
          .withExtra('CityName', geoLocation.CityName)
          .withExtra('CountryName', geoLocation.CountryName)
          .withExtra('Region', geoLocation.Region)
      );
    })
    .catch((err) => {
      logger.warn('failed to init geolocation', err);
    });
};
