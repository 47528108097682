import {
  initializeBugsnag,
  initLogger,
  logger,
  setMonitorWriters,
  SolutoMonitorWriter,
} from '@soluto-home-web/platform-logger';
import {
  queryParamsProvider,
  userAgentParser,
} from '@soluto-home-web/platform-utils';
initializeBugsnag();

import { identity, UserBrowser } from '@soluto-home-web/platform-identity';
import { setObservableConfig } from 'recompose';
import { from } from 'rxjs/observable/from';
import { initAnalytics } from '../services/analyticsInitializer';
import OriginIdentifier from '../services/originIdentifier';
import { initTweek } from '../services/tweekInitializer';
import {
  bindToIdentityChanges,
  enhanceStaticData,
} from './enhance-bugsnag-errors';

const rxjsconfig = {
  fromESObservable: from,
  toESObservable: (stream: any) => stream,
};

enhanceStaticData();
bindToIdentityChanges();

initLogger({
  Partner: globals.REACT_APP_PARTNER,
  IsTest: globals.REACT_APP_IS_TEST,
  IsLocalNumber: globals.REACT_APP_IS_LOCAL_NUMBER,
  HostName: location.hostname,
  Environment: globals.NODE_ENV,
  UserBrowserId: UserBrowser.Id,
  Browser: userAgentParser.getBrowserName(),
  OS: userAgentParser.getOs(),
  OSVersion: userAgentParser.getOsVersion(),
});

identity.updated$.subscribe((user) => {
  !!user.id &&
    logger.updateContext((currentContext) => ({
      ...currentContext,
      HomeUserId: user.id,
    }));
});

identity.loggedOut$.subscribe(() => {
  logger.updateContext((currentContext) => {
    delete currentContext['HomeUserId'];

    return currentContext;
  });
});

setObservableConfig(rxjsconfig);
OriginIdentifier.initialize(window.document.referrer);

queryParamsProvider.initialize();
initTweek();
initAnalytics();

setMonitorWriters([
  new SolutoMonitorWriter('https://logging-api-new.mysoluto.com'),
]);
