import { logger } from '@soluto-home-web/platform-logger';
import {
  DetailedTweekResult,
  GetValuesConfig,
  ITweekClient,
  TweekClient,
} from 'tweek-client';

export default class TweekClientWithFallback implements ITweekClient {
  private _clients: TweekClient[];

  constructor(clients: TweekClient[]) {
    this._clients = clients;
  }

  private async _fetchWithFallbacks<T>(
    path: string,
    config?: GetValuesConfig
  ): Promise<T> {
    for (let client of this._clients) {
      try {
        return await client.getValues<T>(path, config);
      } catch (error) {
        logger.warning('Caught exception during fetch from Tweek: ', {
          error,
          baseUrl: client.config.baseServiceUrl,
          path,
          config,
        });
      }
    }

    logger.warning('Failed to fetch from all Tweek fallbacks', {
      path,
      config,
    });

    throw 'Failed to fetch from all Tweek fallbacks';
  }

  private async _getValuesWithDetailsWithFallbacks<T>(
    path: string,
    config?: GetValuesConfig
  ): Promise<DetailedTweekResult<T>> {
    for (let client of this._clients) {
      try {
        return await client.getValuesWithDetails<T>(path, config);
      } catch (error) {
        logger.warning('Caught exception during fetch from Tweek: ', {
          error,
          baseUrl: client.config.baseServiceUrl,
          path,
          config,
        });
      }
    }

    logger.warning('Failed to fetch from all Tweek fallbacks', {
      path,
      config,
    });

    throw 'Failed to fetch from all Tweek fallbacks';
  }

  async fetch<T>(path: string, config?: GetValuesConfig): Promise<T> {
    if (globals.REACT_APP_IS_E2E) {
      throw 'e2e';
    }

    return await this._fetchWithFallbacks<T>(path, config);
  }

  async getValues<T>(path: string, config?: GetValuesConfig): Promise<T> {
    if (globals.REACT_APP_IS_E2E) {
      throw 'e2e';
    }

    return await this._fetchWithFallbacks<T>(path, config);
  }

  async getValuesWithDetails<T>(
    path: string,
    config?: GetValuesConfig
  ): Promise<DetailedTweekResult<T>> {
    if (globals.REACT_APP_IS_E2E) {
      throw 'e2e';
    }

    return await this._getValuesWithDetailsWithFallbacks<T>(path, config);
  }
}
