import * as React from 'react';
import styled, { css } from 'styled-components';

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
`;

const FontCss = css`
  font-size: 23px;
  font-style: italic;
  line-height: 1.13;
  text-align: center;
  color: #696969;
`;

const Title = styled.span`
  ${FontCss}
  font-weight: bold;
  margin-bottom: 15px;
`;

const Title2 = styled.span`
  ${FontCss}
  margin-bottom: 7vh;
`;

export interface ErrorDescriptionProps {
  title: string;
  description: string;
}

export class ErrorDescription extends React.Component<
  ErrorDescriptionProps,
  any
> {
  render() {
    const { title, description, children } = this.props;

    return (
      <TextContainer>
        <Title>{title}</Title>
        <Title2>{description}</Title2>
        {children}
      </TextContainer>
    );
  }
}
