import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { withAnalyticOnEvent } from 'react-shisell';
import { compose, pure, withProps, withState } from 'recompose';
import calculateTargetDimensions from '../SharedMediaFiles/calculate-target-dimensions';
import {
  LinkMediaContainer,
  MediaOverlay,
  SizeProps,
} from '../SharedMediaFiles/sharedTypes';

type baseProps = {
  alt: string;
  borderColor: string;
};

export type ImageItemProps = {
  imageUrl: string;
  fromExpert: string;
  senderType: string;
  thumbnailUrl: string;
  imageDimensions: SizeProps;
  shouldImageActAsAnchor: boolean;
  messageSelected: boolean;
};

type FullSizeImageProps = baseProps & {
  loaded: boolean;
  onLoad: any;
};

type InternalEnhanceProps = {
  loaded: boolean;
  onImageLoaded: () => void;
  setLoaded: (value: boolean) => void;
  theme: Theme;
};

const FullSizeImage = styled.img<FullSizeImageProps>`
  border-radius: 5px;
  border: solid 8px ${(props) => props.borderColor};
  box-sizing: border-box;
  position: absolute;
  transition: opacity 400ms linear;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  opacity: ${(props) => (props.loaded ? 1 : 0)};
`;

const FullSizeImageWithClickAnalytic = withAnalyticOnEvent<
  FullSizeImageProps & React.ImgHTMLAttributes<HTMLImageElement>
>({
  eventName: 'onClick',
  analyticName: 'ImageItem',
  extras: { ActionId: 'Click' },
})(FullSizeImage);

const ThumbnailImage = styled.img<baseProps & SizeProps>`
  border-radius: 5px;
  background-color: #e6e6e6;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  border: solid 8px ${(props) => props.borderColor};
  box-sizing: border-box;
`;

class ImageItem extends React.Component<ImageItemProps & InternalEnhanceProps> {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  }

  updateDimensions() {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  render() {
    const {
      imageUrl,
      fromExpert,
      senderType,
      thumbnailUrl,
      imageDimensions,
      loaded,
      onImageLoaded,
      shouldImageActAsAnchor,
      messageSelected,
    } = this.props;

    const { width, height } = imageDimensions;
    const targetSize = calculateTargetDimensions(
      width,
      height,
      this.state['windowWidth'],
      this.state['windowHeight']
    );
    const alt = fromExpert ? 'Image sent by Expert' : 'Image sent by Customer';
    const borderColor = fromExpert ? '#f4f4f4' : '#dedede';

    let target = '';
    let href = '';
    if (shouldImageActAsAnchor) {
      target = '_blank';
      href = imageUrl;
    }

    const Thumbnail = (
      <ThumbnailImage
        alt={loaded ? '' : alt}
        src={thumbnailUrl}
        width={targetSize.width}
        height={targetSize.height}
        borderColor={borderColor}
      />
    );

    const FullImage = (
      <FullSizeImageWithClickAnalytic
        src={imageUrl}
        onLoad={onImageLoaded}
        borderColor={borderColor}
        alt={loaded ? alt : ''}
        width={targetSize.width}
        height={targetSize.height}
        loaded={loaded}
        analyticsExtras={{ SenderType: senderType, ActionId: 'Click' }}
      />
    );

    return (
      <LinkMediaContainer
        href={href}
        target={target}
        data-test-id={'ImageItem'}
        width={targetSize.width}
        height={targetSize.height}
      >
        <MediaOverlay messageSelected={messageSelected} />
        {FullImage}
        {Thumbnail}
      </LinkMediaContainer>
    );
  }
}

const enhance = compose<any, ImageItemProps>(
  withState('loaded', 'setLoaded', false),
  withProps(({ setLoaded, contentId }) => ({
    onImageLoaded: () => {
      setLoaded(true);
      setTimeout(() => localStorage.removeItem(contentId), 500);
    },
  })),
  pure
);

export default enhance(ImageItem);
