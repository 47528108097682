import { styled } from '@soluto-home-web/web-theme';
import React from 'react';
import PrimaryButton from './PrimaryButton';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin: 24px 0px;
`;

const Separator = styled.div`
  margin-right: 10px;
`;

type ButtonsContainerProps = {
  buttonsTitle: Array<string>;
  buttonsOnClick: (title: string) => void;
};

export const DecisionButtons: React.FunctionComponent<
  ButtonsContainerProps
> = ({ buttonsTitle, buttonsOnClick }: ButtonsContainerProps) => {
  const buttons = buttonsTitle.map((title, index) => (
    <div key={index}>
      <PrimaryButton title={title} onPress={() => buttonsOnClick(title)} />
      <Separator />
    </div>
  ));

  return (
    <div>
      <ButtonsWrapper>{buttons}</ButtonsWrapper>
    </div>
  );
};
