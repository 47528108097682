export default (
  expertise?: string,
  languageExpertise?: string
): string[] | undefined => {
  let arrayOfExpertise: string[] = [];
  if (expertise) {
    arrayOfExpertise.push(expertise);
  }
  if (languageExpertise) {
    arrayOfExpertise.push(languageExpertise);
  }

  return arrayOfExpertise.length === 0 ? undefined : arrayOfExpertise;
};
