import SocketClient from '@npmsoluto/soluto-messaging-client';
import { branch, compose, getContext, withHandlers } from 'recompose';
import {
  buildOutgoingImageItem,
  buildOutgoingTextMessage,
  ImagePayload,
} from '../utils';
import { MessageType, messagingContextTypes } from './types';

interface SendMessageProps {
  message: string | ImagePayload;
  messagingClient: SocketClient;
  timelineId: string;
  messagingPartner: string;
  requiredExpertise?;
  requiredLanguageExpertise?;
  customExpertise?;
  messageType?: string;
}

export const buildMessage = ({
  message,
  timelineId,
  messagingPartner,
  requiredExpertise,
  requiredLanguageExpertise,
  customExpertise,
  messageType = MessageType.Text,
}: any) => {
  if (typeof message === 'string' && messageType === MessageType.Text) {
    return buildOutgoingTextMessage(
      message,
      timelineId,
      messagingPartner,
      requiredExpertise,
      requiredLanguageExpertise,
      customExpertise
    );
  } else if (messageType === MessageType.Image) {
    return buildOutgoingImageItem(
      message as ImagePayload,
      timelineId,
      messagingPartner
    );
  } else {
    throw new Error(
      `Tried to build unsupported messageType of type ${messageType}`
    );
  }
};

const withSendMessage = compose(
  getContext(messagingContextTypes),
  branch(
    ({ messagingClient }) => messagingClient,
    withHandlers<any, any>({
      sendMessage: ({
        messagingClient,
        messageType = MessageType.Text,
        ...props
      }: SendMessageProps) => async (message, customExpertise) => {
        const outgoingMessage = buildMessage({
          ...props,
          message,
          customExpertise,
        });
        await messagingClient.send(outgoingMessage);
      },
    })
  )
);

export default withSendMessage;
