import { TimelineItem } from '@soluto-home-web/timeline-core';
import React from 'react';
import {
  branch,
  compose,
  lifecycle,
  renderNothing,
  withHandlers,
} from 'recompose';
import { AnalyticsDispatcher } from 'shisell';
import { TwoStepResolutionContent } from '../../TwoStepResolutionContentType';
import TeamMessage from '../TeamMessage';
import ResolutionAnswer from './ResolutionAnswer';
import ResolutionButtons from './ResolutionButtons';

type ResolutionQuestionProps = {
  handleQuestionSelection: (selection: string) => void;
  expertBrandingName: string;
  resolutionAnswer: string;
  analytics: AnalyticsDispatcher;
};

const ResolutionQuestion = (
  props: TimelineItem<TwoStepResolutionContent> & ResolutionQuestionProps
) => {
  const {
    handleQuestionSelection,
    expertBrandingName,
    content: { expertName },
    resolutionAnswer,
  } = props;
  const expertDisplayName: string = expertName || `the ${expertBrandingName}`;
  const message: string = `Did ${expertDisplayName} solve your issue?`;

  return (
    <div>
      <TeamMessage {...props} isGrouped={false} message={message} />

      {resolutionAnswer ? (
        <ResolutionAnswer {...props} resolutionAnswer={resolutionAnswer} />
      ) : (
        <ResolutionButtons handleQuestionSelection={handleQuestionSelection} />
      )}
    </div>
  );
};

const enhance = compose<ResolutionQuestionProps, TwoStepResolutionContent>(
  withHandlers({
    handleQuestionSelection: ({
      setResolutionAnswer,
      triggerStarSurvey,
      updateCustomerResolution,
      setIssueNotResolvedMessagesToShow,
      analytics,
    }) => async (questionSelection: string) => {
      setIssueNotResolvedMessagesToShow(0);
      setResolutionAnswer(questionSelection);

      const selection = questionSelection === 'Yes';

      analytics.withExtras({ selection }).dispatch('Resolved_Question_Click');

      if (selection) {
        try {
          analytics.dispatch('Send_Star_Rating_From_Two_Step_Res_Yes');
          setTimeout(async () => await triggerStarSurvey(), 1100);
        } catch (error) {
          console.warn('failed sending star survey', error);
          analytics
            .withExtras({ error })
            .dispatch('Error_Send_Star_Rating_From_Two_Step_Res_Yes');
        }
      }

      try {
        await updateCustomerResolution(selection);
      } catch (error) {
        console.warn('failed updating customer resolution', error);
        analytics
          .withExtras({ error, selection })
          .dispatch('Error_Updating_Customer_Selection');
      }
    },
  }),
  lifecycle<ResolutionQuestionProps, {}>({
    componentDidMount() {
      this.props.analytics.createScoped('ResolutionQuestion').dispatch('View');
    },
  })
);

const withExpiration = branch(
  ({ isExpired, resolutionAnswer }) => isExpired && !resolutionAnswer,
  renderNothing,
  enhance
);

export default withExpiration(ResolutionQuestion);
