import * as React from 'react';

type props = {
  color: string;
};

const CloseMark = ({ color }: props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
  >
    <path
      fill={color}
      fillRule="nonzero"
      d="M6 6.99L3.496 9.496c-.66.66-1.65-.33-.99-.99L5.011 6 2.505 3.495c-.66-.66.33-1.65.99-.99l2.506 2.506L8.51 2.505c.66-.66 1.65.33.99.99L6.99 6.001l2.504 2.504c.66.66-.33 1.65-.99.99L6.001 6.991z"
    />
  </svg>
);
export default CloseMark;
