import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { withRouter } from '@soluto-home-web/platform-utils';
import { branch, compose, mapProps } from 'recompose';
import { provideMessagingFallbackClient } from '../messaging-fallback/messaging-fallback-provider';
import MessagingProviderType from '../utils/MessagingProviderType';
import withGetBearerTokenHandler from './withGetBearerTokenHandler';

export default compose(
  withTweekKeys('support/session/messaging/provider', {
    propName: 'messagingProvider',
  }),
  branch(
    ({ messagingProvider }) =>
      messagingProvider === MessagingProviderType.Fallback,
    branch(
      ({ timelineId }) => timelineId,
      compose(
        withTweekKeys('support/session/messaging/fallback/polling_interval', {
          propName: 'pollingInterval',
          initialValue: 1000,
        }),
        withRouter,
        withGetBearerTokenHandler,
        provideMessagingFallbackClient(
          ({ getBearerToken, timelineId, pollingInterval }) => ({
            senderId: timelineId,
            getBearerToken,
            pollingInterval,
          })
        ),
        mapProps(({ getBearerToken, ...other }) => other)
      )
    )
  ),
  mapProps(({ messagingProvider, ...other }) => other)
);
