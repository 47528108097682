import { mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { empty } from 'rxjs/observable/empty';
import { forChatSession$ } from '../firebase/chatSessionApi';
import rxjsconfig from '../utils/rxjsconfig';

export default mapPropsStreamWithConfig(rxjsconfig)(
  (props$: Observable<{ timelineId: string }>) => {
    const sessionCreated$ = props$
      .distinctUntilKeyChanged('timelineId')
      .switchMap(({ timelineId }) => forChatSession$(timelineId))
      .startWith('')
      .onErrorResumeNext(empty());

    return props$.combineLatest(
      sessionCreated$,
      (props, session: { sessionId: string }) => {
        return {
          ...props,
          sessionId: session.sessionId,
        };
      }
    );
  }
);
