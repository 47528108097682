import equals from 'ramda.equals';
import pickBy from 'ramda.pickby';
import { mapPropsStreamWithConfig } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { empty } from 'rxjs/observable/empty';
import { isInSession$ } from '../firebase/chatSessionApi';
import rxjsconfig from '../utils/rxjsconfig';

const withoutFuncs = (obj) => pickBy((v, k) => typeof v !== 'function', obj);
export default mapPropsStreamWithConfig(rxjsconfig)(
  (props$: Observable<{ timelineId: string; showHistory: boolean }>) => {
    const sessionStatus$ = props$
      .distinctUntilChanged((a, b) => equals(withoutFuncs(a), withoutFuncs(b)))
      .switchMap((props) => isInSession$(props.timelineId))
      .distinctUntilChanged()
      .startWith(false)
      .onErrorResumeNext(empty());

    return props$.combineLatest(
      sessionStatus$,
      (props, isInSession: boolean) => ({
        ...props,
        isInSession,
      })
    );
  }
);
