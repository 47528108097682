import { Query, Reference } from '@firebase/database-types';
import { logger } from '@soluto-home-web/platform-logger';
import firebase from 'firebase/app';
import 'firebase/database';
import 'rxjs/add/operator/concatMap';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/merge';
import 'rxjs/add/operator/scan';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import firebaseProvider from '../firebase/firebaseProvider';
import { default as TimelineItem, itemTypes } from '../models/TimelineItem';
import getTimelineItemKey from '../utils/getTimelineItemKey';
import observableFromRef from '../utils/observableFromRef';

interface IObservableQuery<T> extends Query {
  observe: (event: string) => Observable<T>;
}

export enum TimelineType {
  Customer = 'timelines',
  Expert = 'expertTimelines',
}

export const firebaseIncomingMessages$: ReplaySubject<
  TimelineItem<any>
> = new ReplaySubject<TimelineItem<any>>();

const _setFirebaseChild = (timelineNode, timelineId, timelineItemKey, item) =>
  firebaseProvider
    .app!.database()
    .ref(timelineNode)
    .child(timelineId)
    .child(timelineItemKey)
    .setWithPriority(item, firebase.database.ServerValue.TIMESTAMP.toString());

let serverTimeOffset = 0;
firebaseProvider.signedIn$
  .distinctUntilChanged()
  .switchMap(() =>
    observableFromRef(
      firebaseProvider.app!.database().ref('.info/serverTimeOffset'),
      'value'
    )
  )
  .map((snapshot) => snapshot.val() || 0)
  .subscribe((value) => (serverTimeOffset = +value));

const _pushTask = (timelineId, timelineItemId) => {
  return firebaseProvider
    .app!.database()
    .ref('publish-topic-tasks/tasks')
    .push()
    .set({
      topic: 'new_timeline_message',
      body: {
        timelineId,
        timelineItemId,
      },
    });
};

const _sendItem = (timelineNode: TimelineType, timelineId: string, item) => {
  const model = {
    ...item,
    timelineItemKey: getTimelineItemKey(item),
    sendTimestamp: item.sendTimestamp || Date.now() + serverTimeOffset,
    timestamp: item.timestamp || firebase.database.ServerValue.TIMESTAMP,
  };
  return _setFirebaseChild(
    timelineNode,
    timelineId,
    getTimelineItemKey(model),
    model
  );
};

export const updateItem = async (
  timelineId,
  contentType,
  contentId,
  updates = {}
) => {
  const timelineItemId = getTimelineItemKey({ contentType, contentId });

  return (
    firebaseProvider.app &&
    (await firebaseProvider.app.database!()
      .ref(TimelineType.Customer)
      .child(timelineId)
      .child(timelineItemId)
      .update({
        ...updates,
        updatedAt: Date.now(),
      }))
  );
};

export const sendItem = async (timelineId: string, item) => {
  try {
    await _sendItem(TimelineType.Customer, timelineId, item);
    await _pushTask(timelineId, getTimelineItemKey(item));

    return { success: true };
  } catch (error) {
    logger.error('Failed to send timeline item', error, { timelineId });

    return { success: false, error };
  }
};

export const sendExpertItem = async (timelineId, item) => {
  try {
    await _sendItem(TimelineType.Expert, timelineId, item);

    return { success: true };
  } catch (error) {
    logger.error('Failed to send timeline item', error, { timelineId });

    return { success: false, error };
  }
};

export const forTimeline = (timelineId) =>
  firebaseProvider.app &&
  firebaseProvider.app.database!()
    .ref(TimelineType.Customer)
    .child(timelineId);

export const getTimeline = (
  timeline$: Reference,
  showHistory: boolean
): Observable<TimelineItem<{}>[]> => {
  let isFirstLoad = true;
  let onceItemsCount = 0;
  let addedItemsCount = 0;
  let startTimestamp = showHistory ? 0 : Date.now();

  timeline$.once('value', function (snapshot) {
    isFirstLoad = false;
    const items = snapshot.val();
    onceItemsCount = items ? Object.keys(items).length : 0;
  });

  const orderedTimeline$ = timeline$
    .orderByChild('timestamp')
    .startAt(startTimestamp) as IObservableQuery<any>;

  let addedItems$ = orderedTimeline$
    .observe('child_added')
    .map((x) => x as TimelineItem<any>)
    .do((item) => {
      addedItemsCount++;

      if (!isFromDevice(item)) {
        firebaseIncomingMessages$.next(item);
      }

      if (isOnlineMessage(isFirstLoad, onceItemsCount, addedItemsCount)) {
        item.receivedTimestamp = Date.now() + serverTimeOffset;
        item.isOnlineMessage = true;
      } else if (!isFromDevice(item)) {
        item.receivedTimestamp = item.timestamp;
      }
    });

  let updatedItems$ = orderedTimeline$
    .observe('child_changed')
    .map((x) => x as TimelineItem<any>);

  let deletedItems$ = orderedTimeline$
    .observe('child_removed')
    .map((x) => x as TimelineItem<any>)
    .map((i) => ({ ...i, removed: true }));

  return addedItems$
    .merge(updatedItems$)
    .merge(deletedItems$)
    .filter(
      (x) =>
        x.contentType === itemTypes.TextMessage ||
        x.contentType === itemTypes.ImageItem ||
        x.contentType === itemTypes.ListPicker ||
        x.contentType === itemTypes.DynamicComponent
    )
    .scan((items, item: TimelineItem<any> & { removed: boolean }) => {
      const oldItemIndex = items.findIndex(
        (i) => i.contentId === item.contentId
      );

      if (oldItemIndex === -1) {
        return [...items, item];
      } else if (item.removed) {
        return [
          ...items.slice(0, oldItemIndex),
          ...items.slice(oldItemIndex + 1),
        ];
      } else if ((items[oldItemIndex].updatedAt || 0) < item.updatedAt!) {
        return [
          ...items.slice(0, oldItemIndex),
          item,
          ...items.slice(oldItemIndex + 1),
        ];
      } else {
        return items;
      }
    }, []);
};

const isOnlineMessage = (isFirstLoad, onceItemsCount, addedItemsCount) => {
  return !isFirstLoad && onceItemsCount < addedItemsCount;
};

const isFromDevice = (timelineItem: TimelineItem<any>) =>
  timelineItem.senderType === 'Device';
