import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/pairwise';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/takeUntil';
import firebaseProvider from '../firebase/firebaseProvider';
import observableFromRef from '../utils/observableFromRef';

export const forChatSession$ = (timelineId) => {
  return firebaseProvider.signedIn$
    .distinctUntilChanged()
    .switchMap((userId) => {
      const id = timelineId || userId;
      const ref = firebaseProvider.app!.database!()
        .ref('chatSession')
        .child(id);

      return observableFromRef(ref, 'value')
        .map((snapshot) => snapshot.val() || {})
        .takeUntil(firebaseProvider.signedOut$.filter((i) => id === i));
    });
};

export const chatSessionResolved$ = (timelineId) =>
  forChatSession$(timelineId)
    .pairwise()
    .filter(
      (chatSessions) =>
        chatSessions[0].isInSession && !chatSessions[1].isInSession
    )
    .map((chatSessions) => chatSessions[0].sessionId);

export const chatSessionStarted$ = (timelineId) =>
  forChatSession$(timelineId)
    .pairwise()
    .filter((chatSessions) => {
      return (
        (!chatSessions[0].isInSession &&
          chatSessions[1].isInSession &&
          chatSessions[1].sessionId) || // session created
        (chatSessions[1].sessionId &&
          chatSessions[0].sessionId !== chatSessions[1].sessionId)
      ); //session id changed
    })
    .map((chatSessions) => chatSessions[1].sessionId);

export const isInSession = async (timelineId) =>
  new Promise((resolve, reject) => {
    firebaseProvider.app!.database!()
      .ref('chatSession')
      .child(timelineId)
      .once(
        'value',
        (snapshot) => {
          resolve(snapshot.val() && snapshot.val().isInSession);
        },
        (e) => reject(e)
      );
  });

export const isInSession$ = (timelineId) =>
  forChatSession$(timelineId)
    .map((chatSession) => !!chatSession.isInSession)
    .distinctUntilChanged()
    .share();

export const postUserIsTyping = (timelineId: string, isTyping: boolean) => {
  firebaseProvider.app!.database!()
    .ref('chatSession')
    .child(timelineId)
    .update({ userIsTyping: isTyping });

  if (isTyping) {
    firebaseProvider.app!.database!()
      .ref('chatSession')
      .child(timelineId)
      .onDisconnect()
      .update({ userIsTyping: false });
  }
};

export const getTypingTechnicians$ = (timelineId: string) =>
  forChatSession$(timelineId).map(
    (chatSession) => chatSession.technicianIsTyping
  );
