import { IPerformanceTimeline } from './IPerformanceTimeline';

export class PerformanceTimelineStub implements IPerformanceTimeline {
  startMeasurement(pathname: string): void {}

  isMeasurementStarted(pathname: string): boolean {
    return false;
  }

  endMeasurement(pathname: string, pageName): PerformanceEntry | null {
    return null;
  }

  getLastMeasurement(entryName: string): PerformanceEntry | null {
    return null;
  }
}
