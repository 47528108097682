import { identity, UserBrowser } from '@soluto-home-web/platform-identity';
import { logger } from '@soluto-home-web/platform-logger';
import { useEffect } from 'react';
import { analytics } from 'react-shisell';
import { useTweekValue } from 'react-tweek';
import { default as fullstory } from './fullstory';

export const SessionTracking = () => {
  const isEnabled = useTweekValue(
    'soluto_home_web/analytics/fullstory/is_enabled',
    false
  );

  const isTrackingEnabled = useTweekValue(
    'soluto_home_web/analytics/fullstory/is_tracking_enabled',
    false
  );

  useEffect(() => {
    if (!isEnabled || !isTrackingEnabled) {
      return () => {};
    }

    fullstory.initalize(UserBrowser.Id, {
      homeUserId_str: identity.get().id,
      isIdentified_bool: identity.isLoggedIn(),
      partner_str: globals.REACT_APP_PARTNER,
    });

    const identityUpdatedSubscription = identity.updated$.subscribe((user) => {
      !!user.id &&
        fullstory.setUserVars({
          homeUserId_str: user.id,
          isIdentified_bool: identity.isLoggedIn(),
        });
    });

    const loggedOutSubscription = identity.loggedOut$.subscribe(() => {
      fullstory.setUserVars({
        isIdentified_bool: identity.isLoggedIn(),
      });
    });

    let shouldEnrichAnalytics = true;
    analytics.transformDispatcher((rootDispatcher) => {
      return rootDispatcher.withFilter((eventModel) => {
        if (shouldEnrichAnalytics) {
          // @ts-ignore
          eventModel.ExtraData['SessionUrl'] = fullstory.getCurrentSessionURL();
          // @ts-ignore
          eventModel.ExtraData[
            'SessionUrlAtTime'
          ] = fullstory.getCurrentSessionAtTime();
        }

        return eventModel;
      });
    });

    let shouldEnrichLogger = true;
    logger.updateContext((currentContext) => {
      return shouldEnrichLogger
        ? {
            ...currentContext,
            SessionUrl: fullstory.getCurrentSessionURL(),
          }
        : {};
    });

    return () => {
      fullstory.dispose();
      identityUpdatedSubscription.unsubscribe();
      loggedOutSubscription.unsubscribe();
      shouldEnrichAnalytics = false;
      shouldEnrichLogger = false;
    };
  }, [isEnabled, isTrackingEnabled]);

  return null;
};
