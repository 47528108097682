import { BreakPoints, styled } from '@soluto-home-web/web-theme';
import * as React from 'react';

const IndicatorContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  z-index: 9999;
  opacity: 0.2;
  height: 30px;
  &:hover {
    opacity: 1;
  }

  .mobile {
    display: block;
  }
  @media ${BreakPoints.mobileBig} {
    .mobile-big {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

  @media ${BreakPoints.tablet} {
    .tablet {
      display: block;
    }
    .mobile-big,
    .mobile {
      display: none;
    }
  }

  @media ${BreakPoints.tabletBig} {
    .tablet-big {
      display: block;
    }
    .tablet,
    .mobile-big,
    .mobile {
      display: none;
    }
  }

  @media ${BreakPoints.desktop} {
    .desktop {
      display: block;
    }
    .mobile,
    .mobile-big,
    .tablet-big,
    .tablet {
      display: none;
    }
  }
`;

const Indicator = styled.label`
  display: none;
  width: auto;
  height: auto;
  padding: 3px;
  color: white;
`;

const Desktop = styled(Indicator)`
  background-color: blue;
`;

const TabletBig = styled(Indicator)`
  background-color: salmon;
`;

const Tablet = styled(Indicator)`
  background-color: lightblue;
`;

const Mobile = styled(Indicator)`
  background-color: purple;
`;

const MobileBig = styled(Indicator)`
  background-color: purple;
`;

export default () => {
  return (
    <IndicatorContainer>
      <Desktop className="desktop">desktop</Desktop>
      <TabletBig className="tablet-big">tablet-big</TabletBig>
      <Tablet className="tablet">tablet</Tablet>
      <MobileBig className="mobile-big">mobile big</MobileBig>
      <Mobile className="mobile">mobile</Mobile>
    </IndicatorContainer>
  );
};
