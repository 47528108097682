import {
  OneTimecodeValidationErrors,
  sendOneTimeAuthenticationCode,
  validateAuthenticationCode,
} from './identifyUserUsingOneTimeAuthCode';

export { default as authenticationApiClient } from './authenticationApiClient';

export const auth = {
  OneTimecodeValidationErrors,
  sendOneTimeAuthenticationCode,
  validateAuthenticationCode,
};
