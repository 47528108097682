import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { branch, compose, mapProps } from 'recompose';
import MessagingProviderType from '../utils/MessagingProviderType';
import withDeleteMessages, {
  withDeleteMessageMessagingApi,
  withDeleteMessageRestApi,
} from './withDeleteMessages';

export default compose(
  withDeleteMessages,
  withTweekKeys('support/session/messaging/provider', {
    propName: 'messagingProvider',
  }),
  branch<{ deleteable: boolean; messagingProvider: string }>(
    ({ deleteable, messagingProvider }) =>
      deleteable && messagingProvider === MessagingProviderType.MessagingApi,
    withDeleteMessageMessagingApi(({ deleteMessage }) => ({ deleteMessage })),
    withDeleteMessageRestApi(({ deleteMessage }) => ({ deleteMessage }))
  ),
  mapProps(({ messagingProvider, ...other }) => other)
);
