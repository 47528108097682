import { identity, UserBrowser } from '@soluto-home-web/platform-identity';
import { enhanceErrorReports } from '@soluto-home-web/platform-logger';
import { queryString } from '@soluto-home-web/platform-utils';

export function enhanceStaticData(): void {
  enhanceErrorReports({
    userData: {
      userBrowserId: UserBrowser.Id,
      id: UserBrowser.Id,
    },
    metaData: {
      custom: {
        queryParams: queryString.get(),
      },
    },
  });
}

export function bindToIdentityChanges(): void {
  identity.updated$.subscribe((user) => {
    !!user.id &&
      enhanceErrorReports({
        userData: {
          homeUserId: user.id,
        },
      });
  });

  identity.loggedOut$.subscribe(() => {
    enhanceErrorReports({
      userData: {
        homeUserId: undefined,
      },
    });
  });
}
