class OneTimeAuthCodeSender {
  private baseUrl = 'https://homeuserauth.mysoluto.com';

  public async send(
    countryCode: string,
    mdn: string,
    partner: string
  ): Promise<boolean> {
    const res = await fetch(`${this.baseUrl}/api/v1/oneTimeAuthCode/sendCode`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        CountryCode: countryCode,
        Mdn: mdn,
        Partner: partner,
      }),
    });

    const json = await res.text();
    if (!json || res.status !== 200) {
      const err = new Error(
        `Failed to send one time authentication code to user - ${json}`
      );
      err['status'] = res.status;
      throw err;
    }

    return true;
  }
}

export default new OneTimeAuthCodeSender();
