import TimelineTheme from '@soluto-home-web/timeline-theme/TimelineTheme';
import React, { useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import { withTheme } from 'styled-components';
import { PermissionAllowed } from './PermissionResultText';
import { CapacityState } from './types';

const stateToColor = (state: CapacityState) => {
  switch (state) {
    case CapacityState.optimal:
      return '#00BF79';
    case CapacityState.diminished:
      return '#FF0000';
    default:
      return '#E3A400';
  }
};

const stateToResultText = (state: CapacityState) => {
  switch (state) {
    case CapacityState.optimal:
      return 'Healthy battery';
    case CapacityState.diminished:
      return 'Replace battery';
    default:
      return 'Scan complete';
  }
};

const stateToResultExplanation = (state: CapacityState, expertName) => {
  switch (state) {
    case CapacityState.diminished:
      return "Let's take care of that!";
    default:
      return `Your ${expertName} has more information`;
  }
};

const appear = keyframes`
  0% {
   opacity: 0;
   transform: scale(0) translate(0, 20%);
  }
  
  50% {
    opacity: 0.5;
    transform: scale(0.5) translate(0, -60%);
  }
  
  100% {
    transform: scale(1) translate(0, 0);
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  min-height: 242px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResultsContainer = styled.div<{ viewed?: boolean }>`
  text-align: center;
  padding: 0 1em;
  animation: ${(props) => (props.viewed ? 'none' : appear)} 0.8s ease-out
    forwards;
  transform-origin: bottom center;
`;

const ResultText = styled.p<{ capacityState: CapacityState }>`
  color: ${(props) => stateToColor(props.capacityState)};
  font-weight: 500;
  font-size: 2em;
  line-height: 1;
  margin: 0.5em 0;
`;

export type TestResultsProps = {
  capacityState: CapacityState;
  viewed?: boolean;
  setViewed: () => void;
};

const TestResults = ({
  capacityState,
  viewed,
  setViewed,
  theme,
}: TestResultsProps & { theme: TimelineTheme }) => {
  const callbackRef = useRef(setViewed);
  callbackRef.current = setViewed;

  useEffect(() => {
    const ref = setTimeout(() => callbackRef.current(), 1000);
    return () => clearTimeout(ref);
  }, []);

  return (
    <>
      <PermissionAllowed />
      <Wrapper tabIndex={0}>
        <ResultsContainer viewed={viewed}>
          <p>Battery Max Capacity Results:</p>
          <ResultText capacityState={capacityState}>
            {stateToResultText(capacityState)}
          </ResultText>
          <p>
            {stateToResultExplanation(
              capacityState,
              theme.texts.partner_expert_name
            )}
          </p>
        </ResultsContainer>
      </Wrapper>
    </>
  );
};

export default withTheme(TestResults);
