import { logger } from '@soluto-home-web/platform-logger';
import { getFromTweek } from '@soluto-home-web/platform-tweek';
import ReactGA from 'react-ga';
import { AnalyticsEventModel } from 'shisell';
import { IAnalyticsWriter } from './IAnalyticsWriter';

class GoogleAnalyticsWriter implements IAnalyticsWriter {
  private _isEnabled: boolean | null = null;

  async isWriterEnabled(): Promise<boolean> {
    const isEnabledPromise = getFromTweek(
      'soluto_home_web/analytics/google/is_enabled'
    );
    const trackingCodePromise = getFromTweek(
      'soluto_home_web/analytics/google/tracking_code'
    );

    const isEnabled = await isEnabledPromise;
    const trackingCode = await trackingCodePromise;

    let isWriterEnabled = !!isEnabled === true;
    if (!isWriterEnabled) {
      return false;
    }

    if (!trackingCode) {
      logger.error('Could not get tracking code for partner', new Error());

      return false;
    }
    ReactGA.initialize(trackingCode);

    return true;
  }

  async isEnabled(): Promise<boolean> {
    if (this._isEnabled == null) {
      this._isEnabled = await this.isWriterEnabled();
    }

    return this._isEnabled || false;
  }

  isPageViewAnalytics(eventModel: AnalyticsEventModel): boolean {
    const extraDataKeys =
      eventModel.ExtraData && Object.keys(eventModel.ExtraData);

    return (
      eventModel.Name === 'PageView' &&
      extraDataKeys &&
      extraDataKeys.indexOf('Page') > -1
    );
  }

  getActionForEvent(eventModel: AnalyticsEventModel): string | false {
    if (!eventModel.ExtraData) {
      return false;
    }

    return (
      eventModel.ExtraData.Section ||
      eventModel.ExtraData.Page ||
      eventModel.ExtraData.ActionId ||
      false
    );
  }

  async write(eventModel: AnalyticsEventModel): Promise<void> {
    ReactGA.set(eventModel.ExtraData);

    if (this.isPageViewAnalytics(eventModel)) {
      ReactGA.pageview(
        window.location.pathname + window.location.search,
        undefined,
        eventModel.ExtraData.Page
      );

      return;
    }

    const actionId = this.getActionForEvent(eventModel);
    if (actionId) {
      const gaEventModel: ReactGA.EventArgs = {
        category: eventModel.Name,
        action: actionId,
      };

      ReactGA.event(gaEventModel);
    }
  }
}

export default new GoogleAnalyticsWriter();
