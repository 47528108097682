import { updateItem } from '@soluto-home-web/timeline-core';
import isEqual from 'lodash/isEqual';
import pick from 'ramda.pick';
import React, { Component } from 'react';
import {
  compose,
  lifecycle,
  pure,
  StateHandlerMap,
  withStateHandlers,
} from 'recompose';
import { AnalyticsDispatcher } from 'shisell';
import { TwoStepResolutionState } from '../components/TwoStepResolutionTimelineItem';

type withPersistencyProps = TwoStepResolutionState & {
  content: { sessionId: String };
  timestamp: number;
  flowExpirationInHours: number;
  state: string;
  analytics: AnalyticsDispatcher;
  timelineId: string;
};

type StateHandlersProps = StateHandlerMap<withPersistencyProps> & {
  setResolutionAnswer(resolutionAnswer: string): Partial<withPersistencyProps>;
  setAssistanceNeededAnswer(
    assistanceNeededAnswer: string
  ): Partial<withPersistencyProps>;
  setIsExpired(isExpired: boolean): Partial<withPersistencyProps>;
  setIsPending(isPending: boolean): Partial<withPersistencyProps>;
  setFlowEndedMessagesToShow(
    newFlowEndedMessagesToShow: number
  ): Partial<withPersistencyProps>;
  setIssueNotResolvedMessagesToShow(
    issueNotResolvedMessagesToShow: number
  ): Partial<withPersistencyProps>;
};

const buildStateFromProps = pick([
  'resolutionAnswer',
  'assistanceNeededAnswer',
  'isExpired',
  'isPending',
  'issueNotResolvedMessagesToShow',
  'flowEndedMessagesToShow',
]);

type OutterProps = TwoStepResolutionState &
  StateHandlersProps &
  withPersistencyProps;

export default compose<OutterProps, withPersistencyProps>(
  withStateHandlers<
    TwoStepResolutionState,
    StateHandlersProps,
    withPersistencyProps
  >(
    ({ state, flowExpirationInHours, timestamp, analytics }) => {
      let parsedState;
      try {
        parsedState = JSON.parse(state || '{}');
      } catch (err) {
        console.warn(
          'failed parsing TwoStepResolutionTimelineItem state',
          state,
          err
        );
      }

      if (!parsedState || typeof parsedState !== 'object') {
        parsedState = {};
      }

      let { isExpired } = parsedState;

      if (!isExpired) {
        const flowExpirationInMilliseconds =
          (flowExpirationInHours || 24) * 60 * 60 * 1000;
        const timeout = timestamp + flowExpirationInMilliseconds - Date.now();

        isExpired = timeout < 0;

        if (isExpired) {
          analytics.withExtras({ timestamp }).dispatch('Expired');
        }
      }

      return {
        resolutionAnswer: '',
        assistanceNeededAnswer: '',
        isPending: false,
        issueNotResolvedMessagesToShow: 2,
        flowEndedMessagesToShow: 2,
        ...parsedState,
        isExpired,
      };
    },
    {
      setResolutionAnswer: () => (resolutionAnswer) => ({ resolutionAnswer }),
      setAssistanceNeededAnswer: () => (assistanceNeededAnswer) => ({
        assistanceNeededAnswer,
      }),
      setIsExpired: () => (isExpired) => ({ isExpired }),
      setIsPending: () => (isPending) => ({ isPending }),
      setIssueNotResolvedMessagesToShow: () => (
        issueNotResolvedMessagesToShow
      ) => ({
        issueNotResolvedMessagesToShow,
      }),
      setFlowEndedMessagesToShow: () => (flowEndedMessagesToShow) => ({
        flowEndedMessagesToShow,
      }),
    }
  ),
  lifecycle<
    withPersistencyProps & StateHandlersProps,
    {},
    { _timerRef: number | undefined; _updateState(): Promise<void> }
  >({
    _timerRef: undefined,
    async componentDidMount() {
      const {
        timestamp,
        flowExpirationInHours,
        isExpired,
        setIsExpired,
        analytics,
      } = this.props;
      if (!isExpired) {
        const flowExpirationInMilliseconds =
          (flowExpirationInHours || 24) * 60 * 60 * 1000;
        const timeout = timestamp + flowExpirationInMilliseconds - Date.now();

        this._timerRef = setTimeout(() => {
          setIsExpired(true);
          analytics.withExtras({ timestamp }).dispatch('Expired');
        }, timeout);
      }

      await this._updateState();
    },
    shouldComponentUpdate(newProps) {
      const prevState = buildStateFromProps(this.props);
      const state = buildStateFromProps(newProps);
      return !isEqual(prevState, state);
    },
    async componentDidUpdate() {
      await this._updateState();
    },

    componentWillUnmount() {
      clearTimeout(this._timerRef);
    },
    async _updateState(this: Component<any>) {
      const { timelineId, contentType, contentId } = this.props;
      const state = buildStateFromProps(this.props);

      await updateItem(timelineId, contentType, contentId, {
        state: JSON.stringify(state),
      });
    },
  }),
  pure
);
