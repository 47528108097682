import * as React from 'react';
import MediaQuery from 'react-responsive';

export default (
  query,
  MatchedComponent: React.ComponentType<any>,
  UnmatchedComponent: React.ComponentType<any>
) => (props) => (
  <MediaQuery query={query}>
    {(isMatched) =>
      isMatched ? (
        <MatchedComponent {...props} />
      ) : (
        <UnmatchedComponent {...props} />
      )
    }
  </MediaQuery>
);
