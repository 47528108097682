import * as React from 'react';

export default ({ color = '#00AEEF' }) => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
    <g
      id="Expand-vertical"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-16.000000, -16.000000)"
    >
      <g id="ic_close">
        <rect id="Rectangle" x="0" y="0" width="48" height="48" />
        <path
          d="M25.5,22.5 L32,22.5 C32.8284271,22.5 33.5,23.1715729 33.5,24 C33.5,24.8284271 32.8284271,25.5 32,25.5 L25.5,25.5 L25.5,32 C25.5,32.8284271 24.8284271,33.5 24,33.5 C23.1715729,33.5 22.5,32.8284271 22.5,32 L22.5,25.5 L16,25.5 C15.1715729,25.5 14.5,24.8284271 14.5,24 C14.5,23.1715729 15.1715729,22.5 16,22.5 L22.5,22.5 L22.5,16 C22.5,15.1715729 23.1715729,14.5 24,14.5 C24.8284271,14.5 25.5,15.1715729 25.5,16 L25.5,22.5 Z"
          id="x"
          fill={color}
          fillRule="nonzero"
          transform="translate(24.000000, 24.000000) rotate(-45.000000) translate(-24.000000, -24.000000) "
        />
      </g>
    </g>
  </svg>
);
