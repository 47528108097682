import { branch, compose } from 'recompose';
import withDeleteMessages, {
  withDeleteMessageRestApi,
} from './withDeleteMessages';

export default compose(
  withDeleteMessages,
  branch<{ deleteable: boolean }>(
    ({ deleteable }) => deleteable,
    withDeleteMessageRestApi(({ deleteMessage }) => ({ deleteMessage }))
  )
);
