import {
  isTimelineItemNotSupportedOnMessagingApi,
  transformMessageToTimelineItem,
} from '@npmsoluto/messaging-contracts';
import FallbackClient, {
  MessageSendingStatus,
} from '@npmsoluto/messaging-fallback-client';
import { logger } from '@soluto-home-web/platform-logger';
import { Observable } from 'rxjs/Observable';
import { from } from 'rxjs/observable/from';
import { merge } from 'rxjs/observable/merge';
import { of } from 'rxjs/observable/of';
import { forTimeline, getTimeline } from '../firebase/timelineApi';
import { default as TimelineItem } from '../models/TimelineItem';
import { getMessagesFromCache } from '../timeline-cache';
import { mergeExistingTimelineItems } from '../utils/mergeExistingTimelineItems';

const getUnsupportedTimelineItems = (
  timelineId: string,
  showHistory: boolean,
  messagingSupportedTypes?: string[]
): Observable<TimelineItem<any>> =>
  getTimeline(forTimeline(timelineId)!, showHistory)
    .flatMap((m) => m)
    .filter((i) =>
      isTimelineItemNotSupportedOnMessagingApi(
        i as any,
        messagingSupportedTypes
      )
    );

const getSupportedTimelineItems = (client: FallbackClient, roomId: string) => {
  const cachedMessages = getMessagesFromCache();

  // convert to rx v5
  const messagesFromFallbackApi = from(client.getRoom(roomId))
    .map((x) => ({
      ...transformMessageToTimelineItem(x),
      inFlight: x.status === MessageSendingStatus.PendingSend,
      isFailedSending: x.status === MessageSendingStatus.Error,
    }))
    .catch((err) => {
      logger.error('Could not fetch customer timeline from fallback api', err, {
        provider: 'messaging-fallback',
      });
      return of<TimelineItem<any>>();
    });

  return merge(cachedMessages, messagesFromFallbackApi);
};

const getTimelineItems = (
  client: FallbackClient,
  timelineId: string,
  showHistory: boolean,
  messagingSupportedTypes?: string[]
) => {
  const timelineMessages = getSupportedTimelineItems(client, timelineId);
  const timelineItems = timelineMessages.merge(
    getUnsupportedTimelineItems(
      timelineId,
      showHistory,
      messagingSupportedTypes
    )
  );

  return mergeExistingTimelineItems(timelineItems);
};

export default (
  messagingClient,
  timelineId,
  showHistory,
  messagingSupportedTypes?
) => {
  return getTimelineItems(
    messagingClient,
    timelineId,
    showHistory,
    messagingSupportedTypes
  ).catch((err) => {
    logger.error('Could not fetch customer timeline', err, {
      provider: 'messaging-fallback',
    });

    return of([]);
  });
};
