import React, { useEffect, useRef } from 'react';
import styled, { css, keyframes } from 'styled-components';
import Battery from './Assets/Battery';
import Meter from './Assets/Meter';
import Needle from './Assets/Needle';
import { PermissionAllowed } from './PermissionResultText';
import { CapacityState } from './types';

const Container = styled.div`
  overflow: hidden;
  height: 242px;
  width: 330px;
  margin: auto;
`;

const appear = keyframes`
  from {
    opacity: 0;
    transform: scale(0);
  }
  
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const leave = keyframes`
  from {
    transform: translateY(0);
  }
  
  to {
    transform: translateY(-120%);
  }
`;

const Animation = styled.div`
  text-align: center;
  animation: ${appear} 0.5s ease-in forwards,
    ${leave} 0.3s ease-in-out 5.3s forwards;
`;

const Title = styled.h1`
  color: #616161;
  font-size: 1em;
  margin: 1.5em 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

const Text = styled.p`
  margin-bottom: 0;
`;

const MeterContainer = styled.div`
  position: relative;
`;

const needleAnimation = keyframes`
  0%, 100% {
    transform: translateX(-50%) rotate(0deg); 
  }
  
  25% {
    transform: translateX(-50%) rotate(75deg);
  }
  
  75% {
    transform: translateX(-50%) rotate(-75deg);
  }
`;

const successAnimation = keyframes`
  to {
      transform: translateX(-50%) rotate(75deg);  
  }
`;

const failAnimation = keyframes`
  25% {
    transform: translateX(-50%) rotate(75deg);
  }

  100% {
      transform: translateX(-50%) rotate(30deg);  
  }
`;

const BatteryNeedle = styled(Needle)<{ state: CapacityState }>`
  position: absolute;
  height: 58%;
  bottom: 6%;
  left: 50%;
  transform: translateX(-50%);
  transform-origin: center 85.5%;

  animation: ${(props) => {
    switch (props.state) {
      case CapacityState.optimal:
        return css`${needleAnimation} 1s linear 0.8s 3, ${successAnimation} 1s linear 3.8s forwards`;
      case CapacityState.diminished:
        return css`${needleAnimation} 1s linear 0.8s 3, ${failAnimation} 1s linear 3.8s forwards`;
      default:
        return css`
          ${needleAnimation} 1s linear 0.8s Infinite
        `;
    }
  }};
`;

const BatteryMeter = styled(Meter)`
  width: 100%;
  height: auto;
`;

const BatteryIcon = styled(Battery)`
  height: 1em;
  width: auto;
  margin-right: 0.5em;
`;

export type MaxCapacityTestProps = {
  onFinished: () => void;
  capacityState: CapacityState;
};

const MaxCapacityTest = ({
  onFinished,
  capacityState,
}: MaxCapacityTestProps) => {
  const callbackRef = useRef(onFinished);
  callbackRef.current = onFinished;

  useEffect(() => {
    const timerRef = setTimeout(() => callbackRef.current(), 5650);
    return () => clearTimeout(timerRef);
  }, []);

  return (
    <>
      <PermissionAllowed />
      <Container tabIndex={0}>
        <Animation>
          <Title>
            <BatteryIcon aria-hidden={true} />
            <span>Battery Max Capacity Test</span>
          </Title>
          <MeterContainer aria-hidden={true}>
            <BatteryMeter />
            <BatteryNeedle state={capacityState} />
          </MeterContainer>
          <Text>Scanning battery health...</Text>
        </Animation>
      </Container>
    </>
  );
};

export default MaxCapacityTest;
