import logComposite from './loggers/logComposite';

let defaultContext: object = {};

const printLoggerErrorInfo = (message: string, data: any, err: Error) =>
  console.log(
    `Error occurred while trying to log message [${message}] with data [${data}].\nError [${
      err.message
    }]`
  );

const doLog = (
  level: LogLevel,
  message: string,
  error: any,
  data?: any,
  options?: { [key: string]: any }
) =>
  (logComposite(
    level,
    message,
    error,
    {
      ...defaultContext,
      ...data,
    },
    options
  ) as any).catch((err: Error) => printLoggerErrorInfo(message, error, err));

type Write = (message: string, data?: object) => void;
type WriteError = (
  message: string,
  error: Error,
  data?: any,
  options?: { [key: string]: any }
) => void;

interface Logger {
  error: WriteError;
  warning: Write;
  warn: Write;
  debug: Write;
  info: Write;
  updateContext: (updater: (context: object) => object) => void;
}

const logger: Logger = {
  /** log an error message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  error(
    message: string,
    error: Error,
    data: any,
    options?: { [key: string]: any }
  ) {
    doLog('error', message, error, data, options);
  },
  /** log a warning message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  warning(message: string, data: any) {
    doLog('warning', message, data);
  },
  /** log a warning message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  warn(message: string, data: any) {
    doLog('warning', message, data);
  },
  /** log an info message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  info(message: string, data: any) {
    doLog('info', message, data);
  },
  /** log a debug message
   * @memberof logging.logger
   * @param {string} message the message to log
   * @param {object} data the metadata to add to the message
   */
  debug(message: string, data: any) {
    doLog('debug', message, data);
  },
  updateContext(updater: (context: object) => object) {
    defaultContext = updater(defaultContext);
  },
};

export type LogLevel = 'error' | 'warning' | 'info' | 'debug';
export default logger;
export function init(context: object) {
  defaultContext = context;
}
