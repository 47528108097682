import { authenticationApiClient } from '@soluto-home-web/platform-auth';
import { identity } from '@soluto-home-web/platform-identity';
import { logger } from '@soluto-home-web/platform-logger';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import { extend } from 'rx-firebase';
import 'rxjs/add/operator/take';
import { Observable } from 'rxjs/Observable';
import { ReplaySubject } from 'rxjs/ReplaySubject';
import config from './config';

class FirebaseProvider {
  private defaultAppName = 'default';
  private currentTimelineId?: string;
  public TOKEN_NAME = 'messaging-token';
  signedIn$ = new ReplaySubject<string>(1);
  signedOut$ = new ReplaySubject<string>(1);
  app?: firebase.app.App;

  private initFirebase(firebaseConfig: object = config.prod): void {
    if (this.app) {
      return;
    }
    extend(firebase, Observable);
    this.app = firebase.initializeApp(firebaseConfig, this.defaultAppName);
    this.app.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
  }

  signIn(token: string, timelineId: string): Promise<void> {
    if (!this.app) {
      this.initFirebase();
    }
    if (timelineId === this.currentTimelineId) {
      return Promise.resolve();
    }

    return this.app!.auth()
      .signInWithCustomToken(token)
      .then(async () => {
        this.currentTimelineId = timelineId;

        if (!identity.getHomeUserAccessToken() || !identity.isLoggedIn()) {
          try {
            const homeAccessToken = await authenticationApiClient.homeUserAccessTokenFromMessagingGrant(
              token
            );
            await identity.logIn(homeAccessToken);
          } catch (e) {
            logger.warn(
              'firebaseProvider.signIn: Error occurred while trying to use firebase token to login to the site as home user',
              e
            );
          }
        }

        identity.setToken(this.TOKEN_NAME, token);
        this.signedIn$.next(timelineId);

        identity.loggedOut$.subscribe(() => {
          this.signOut(this.currentTimelineId!);
        });
      });
  }

  signOut(timelineId: string): Promise<void> {
    if (this.app && this.currentTimelineId === timelineId) {
      return this.app!.auth()
        .signOut()
        .then(() => {
          this.signedOut$.next(timelineId);
        });
    }

    return Promise.resolve();
  }
}

export default new FirebaseProvider();
