import { HttpError } from '@soluto-home-web/platform-utils';
import HomeUser from './HomeUser';

class HomeUserApiClient {
  private baseUrl = 'https://home-user-api-user-facing.mysoluto.com';
  private fields = [
    'HomeUserId',
    'PartnerBrandId',
    'TimelineId',
    'FirstName',
    'LastName',
    'EnrolledDevices{Make, Model}',
  ];
  fetchByHomeUserId(token: string, id: string): Promise<HomeUser | null> {
    const body = `{"query": "{homeUser(filter: {HomeUserId: {EQ: \\"${id}\\"}}) {${this.fields.join(
      ','
    )}}}"}`;

    return fetch(`${this.baseUrl}/api/v1/query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body,
    })
      .then((res) => {
        HttpError.ensureSuccessStatusCode(res);

        return res.json();
      })
      .then((json) => {
        const userResponse = json.data.homeUser[0];
        if (!userResponse) {
          return null;
        }

        return this._buildUserFromResponse(userResponse);
      });
  }

  private _buildUserFromResponse(response: any): HomeUser {
    const user = new HomeUser();
    if (response.HomeUserId) {
      user.id = response.HomeUserId;
    }
    if (response.PartnerBrandId) {
      user.partner = response.PartnerBrandId;
    }
    if (response.TimelineId) {
      user.timelineId = response.TimelineId;
    }
    if (response.FirstName) {
      user.firstName = response.FirstName;
    }
    if (response.LastName) {
      user.lastName = response.LastName;
    }
    if (response.EnrolledDevices) {
      user.enrolledDevices = response.EnrolledDevices;
    }

    return user;
  }
}

export default new HomeUserApiClient();
