import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import {
  ButtonWithAnalyticProps,
  ButtonWithClickAnalytic,
} from './ButtonWithClickAnalytic';

const Button = styled.button`
  color: rgba(69, 69, 69, 0.6);
  font-size: 0.7em;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  letter-spacing: 0.005em;
  :focus {
    outline: none;
  }
`;

export const AnchorButton: React.FunctionComponent<
  ButtonWithAnalyticProps
> = ButtonWithClickAnalytic(Button);
