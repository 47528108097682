import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import {
  BreakPoints,
  renderByMediaQuery,
  styled,
} from '@soluto-home-web/web-theme';
import * as React from 'react';

const DESKTOP_SVG_KEY = 'inactive_timeline_svg';
const MOBILE_SVG_KEY = 'inactive_timeline_mobile_svg';

const CenteredImage = styled.img`
  margin: 0 auto;
`;

type Props = { svgUrl: string };

const TextBoxImage = ({ svgUrl }: Props) => <CenteredImage src={svgUrl} />;

const TextBoxCreator = (key: string) =>
  withTweekKeys<Props, {}>(`soluto_home_web/messaging/${key}`, {
    propName: 'svgUrl',
  })(TextBoxImage);

export default renderByMediaQuery(
  `${BreakPoints.desktop}`,
  TextBoxCreator(DESKTOP_SVG_KEY),
  TextBoxCreator(MOBILE_SVG_KEY)
);
