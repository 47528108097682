import { logger } from '@soluto-home-web/platform-logger';
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import * as React from 'react';
import { withAnalytics } from 'react-shisell';
import { branch, compose, getContext, mapProps, renameProp } from 'recompose';
import { Observable } from 'rxjs/Observable';
import { firebaseIncomingMessages$ } from '../firebase/timelineApi';
import { incomingMessages$ } from '../messaging-api/messages-with-timeline-items-provider';
import { messagingContextTypes } from '../messaging-api/types';
import { withMessagingRestClient } from '../messaging-api/with-messaging-rest-client-provider';
import MessagingProviderType from '../utils/MessagingProviderType';
type pendingSeen = { messageId: string; contentType: string };

const enhance = (WrappedComponent) => {
  class withMarkItemAsSeen extends React.Component<any, any> {
    pendingSeenItems: pendingSeen[];

    constructor(props) {
      super(props);

      this.pendingSeenItems = [];
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }

    focusHandler = () => {
      this.pendingSeenItems.forEach((pendingSeen) => {
        this.props.messagingClient.saw(
          this.props.timelineId,
          pendingSeen.messageId,
          pendingSeen.contentType
        );
        this.props.analytics.dispatcher
          .withExtra('MessageId', pendingSeen.messageId)
          .withExtra('ContentType', pendingSeen.contentType)
          .dispatch('MessageRead');
      });
      this.pendingSeenItems = [];
    }

    getMessagesObservable = (): Observable<any> => {
      let messageObservable = firebaseIncomingMessages$.map((item) => ({
        messageId: item.contentId,
        seenAt: item.seenAt,
        contentType: item.contentType,
        senderType: item.senderType ? item.senderType : undefined,
      }));
      if (this.props.messagingProvider === MessagingProviderType.MessagingApi) {
        messageObservable = messageObservable.merge(
          incomingMessages$.map((message) => ({
            messageId: message.messageId!,
            seenAt: message.seenAt,
            contentType: 'TextMessage',
            senderType: message.metadata.senderType as any,
          }))
        );
      }

      return messageObservable.distinct((message) => message.messageId);
    }

    componentWillMount() {
      if (!this.props.messageReadIsEnabled) {
        return;
      }

      this.getMessagesObservable()
        .filter(
          (message) =>
            (message.contentType === 'TextMessage' ||
              message.contentType === 'ImageItem') &&
            message.senderType === 'Technician'
        )
        .subscribe(({ messageId, seenAt, contentType }) => {
          if (!seenAt || (seenAt && !(this.props.timelineId in seenAt))) {
            if (document.hasFocus()) {
              try {
                this.props.messagingClient.saw(
                  this.props.timelineId,
                  messageId,
                  contentType
                );
                this.props.analytics.dispatcher
                  .withExtra('MessageId', messageId)
                  .withExtra('ContentType', contentType)
                  .dispatch('MessageRead');
              } catch (error) {
                logger.info(`Falied mark item as seen ${error.message}`);
              }
            } else {
              this.pendingSeenItems.push({ messageId, contentType });
            }
          }
        });

      window.addEventListener('focus', this.focusHandler);
    }

    componentWillUnmount() {
      window.removeEventListener('focus', this.focusHandler);
    }
  }

  return withMarkItemAsSeen;
};

export default compose(
  withAnalytics,
  withTweekKeys('support/session/messaging/provider', {
    propName: 'messagingProvider',
  }),
  withTweekKeys('support/session/during/message_read/is_enabled', {
    propName: 'messageReadIsEnabled',
  }),
  branch(
    ({ messagingProvider }) =>
      messagingProvider === MessagingProviderType.MessagingApi,
    compose(
      getContext(messagingContextTypes),
      branch(({ messagingClient }) => messagingClient, enhance)
    ),
    compose(
      withMessagingRestClient,
      renameProp('messagingRestClient', 'messagingClient'),
      enhance
    )
  ),
  mapProps(({ analytics, ...other }) => other)
);
