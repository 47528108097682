import * as React from 'react';
import { pure } from 'recompose';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  flex-shrink: 0;
`;

const Line = styled.div`
  background: repeating-linear-gradient(
    to right,
    transparent,
    transparent 4px,
    #d9d9d9 4px,
    #d9d9d9 8px
  );
  height: 1px;
  flex: 1;
`;

const Text = styled.span`
  margin: 0.75em;
  font-size: 12px;
  color: #4f4f4f;
`;

type Props = {
  text: String;
};

export const Timestamp = ({ text }: Props) => (
  <Container tabIndex={0}>
    <Line />
    <Text>{text}</Text>
    <Line />
  </Container>
);

export default pure(Timestamp);
