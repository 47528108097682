import VideoItem from '@npmsoluto/video-item';
import calculateTargetDimensions from '@soluto-home-web/timeline/components/items/SharedMediaFiles/calculate-target-dimensions';
import UploadMediaItem from '@soluto-home-web/timeline/components/items/SharedMediaFiles/UploadMediaItem';
import asTimelineItem from '@soluto-home-web/timeline/hoc/asTimelineItem';
import React, { useCallback, useEffect, useState } from 'react';
import { compose, withState } from 'recompose';

type SizeProps = {
  height: number;
  width: number;
};

export type VideoItemProps = {
  videoUrl: string;
  senderType: string;
  thumbnailUrl: string;
  messageSelected: boolean;
  imageDimensions: SizeProps;
  loaded: boolean;
  alt: string;
  state: string;
  children?: any;
};

export const VideoComponent = (props: VideoItemProps) => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [windowWidth, setWindowWidth] = useState(0);

  const updateDimensions = useCallback(() => {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  const {
    videoUrl,
    senderType,
    thumbnailUrl,
    messageSelected,
    imageDimensions,
    loaded,
    alt,
    state,
  } = props;

  const targetSize = calculateTargetDimensions(
    imageDimensions.width,
    imageDimensions.height,
    windowWidth | window.innerWidth,
    windowHeight | window.innerHeight
  );

  if (state === 'Uploading') {
    return <UploadMediaItem {...props} />;
  } else {
    return (
      <VideoItem
        videoUrl={videoUrl}
        senderType={senderType}
        thumbnailUrl={thumbnailUrl}
        messageSelected={messageSelected}
        videoDimensions={targetSize}
        loaded={loaded}
        alt={alt}
        removeDownload={true}
      />
    );
  }
};

const enhance = compose(
  withState('makeUnfocusable', 'setMakeUnfocusable', true),
  asTimelineItem
);

export default enhance(VideoComponent);
