import { IPerformanceTimeline } from './IPerformanceTimeline';
import { PerformanceTimeline } from './PerformanceTimeline';
import { PerformanceTimelineStub } from './PerformanceTimelineStub';

const isPerformanceSupported = (): boolean => {
  try {
    return (
      !!window.performance &&
      !!window.performance.mark &&
      !!window.performance.measure &&
      !!window.performance.getEntries &&
      !!window.performance.getEntriesByName &&
      !!window.performance.getEntriesByType
    );
  } catch {
    return false;
  }
};

export const performanceTimeline: IPerformanceTimeline = isPerformanceSupported()
  ? new PerformanceTimeline()
  : new PerformanceTimelineStub();
