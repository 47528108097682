import SocketClient, {
  StateLoader,
  StateSaver,
} from '@npmsoluto/soluto-messaging-client';
import { logger } from '@soluto-home-web/platform-logger';
import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import * as React from 'react';
import { compose } from 'recompose';
import config from './config';
import { messagingContextTypes } from './types';

interface MessagingClientProviderProps {
  senderId: string;
  getBearerToken: () => Promise<string>;
  saveState: StateSaver;
  loadState: StateLoader;
  retries?: number;
  syncServerTimestamp?: boolean;
}

export type MessagingConfigProvider<TInputProps> = (
  props: TInputProps
) => MessagingClientProviderProps;

class _MessagingClientProvider extends React.Component<
  MessagingClientProviderProps,
  { client: SocketClient | null }
> {
  static childContextTypes = messagingContextTypes;

  state = {
    client: null,
  };

  componentDidMount() {
    const client = new SocketClient({
      apiKey: config.apiKey,
      baseUrl: config.baseUrl,
      path: config.socketEndpoint,
      getBearerToken: this.props.getBearerToken,
      loadState: this.props.loadState,
      saveState: this.props.saveState,
      senderId: this.props.senderId,
      retries: this.props.retries || 5,
      syncServerTimestamp: this.props.syncServerTimestamp,
      handleError: (err, message) => {
        logger.warn('Error in messaging client', {
          err,
          extra: message && {
            sentTo: message.recipientId,
            messageId: message.messageId,
          },
        });
      },
      handleFatal: (err) => {
        logger.error('Fatal error in messaging client', err, {
          senderId: this.props.senderId,
        });
      },
    });
    client.init();
    this.setState({ client });
  }

  getChildContext() {
    return {
      messagingClient: this.state.client,
    };
  }

  render() {
    return this.state.client ? this.props.children : null;
  }
}

const enhance = compose(
  withTweekKeys('support/routing/messaging/messaging_api/client_retries', {
    propName: 'retries',
  }),
  withTweekKeys(
    'support/routing/messaging/messaging_api/sync_server_timestamp',
    { propName: 'syncServerTimestamp' }
  )
);

const MessagingClientProvider = enhance(_MessagingClientProvider);

export const provideMessagingClient = <
  TOutter extends MessagingClientProviderProps & { timelineId: string }
>(
  getConfig: MessagingConfigProvider<TOutter>
) => (BaseComponent: React.ComponentType<any>) => (props: TOutter) => {
  return (
    <MessagingClientProvider {...getConfig(props)}>
      <BaseComponent {...props} />
    </MessagingClientProvider>
  );
};
