import * as UAParser from 'ua-parser-js';

class UserAgentParser {
  public static UNKNOWN_VALUE = 'Unknown';

  private mUserAgent: IUAParser.IResult;

  constructor() {
    this.mUserAgent = new UAParser().getResult();
  }

  public getOs() {
    return this.mUserAgent.os.name || UserAgentParser.UNKNOWN_VALUE;
  }

  public getOsVersion() {
    return this.mUserAgent.os.version || UserAgentParser.UNKNOWN_VALUE;
  }

  public getVendor() {
    return this.mUserAgent.device.vendor || UserAgentParser.UNKNOWN_VALUE;
  }

  public getModel() {
    return this.mUserAgent.device.model || UserAgentParser.UNKNOWN_VALUE;
  }

  public getBrowserName() {
    return this.mUserAgent.browser.name || UserAgentParser.UNKNOWN_VALUE;
  }

  public getBrowserVersion() {
    return this.mUserAgent.browser.major || UserAgentParser.UNKNOWN_VALUE;
  }

  public isIEBrowser() {
    return this.mUserAgent.browser.name
      ? this.mUserAgent.browser.name.toLowerCase() === 'ie'
      : false;
  }

  public getFormFactor() {
    return (
      (this.mUserAgent.device.type === 'tablet' && 'Tablet') ||
      (this.mUserAgent.device.type === 'mobile' && 'Mobile') ||
      'Desktop'
    );
  }
}

export default new UserAgentParser();
