import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { withAnalyticOnEvent } from 'react-shisell';

const Anchor = styled.a`
  color: #454545;
  font-size: 1em;
`;

const NonStyledAnchorWithAnalytic = withAnalyticOnEvent<
  React.AnchorHTMLAttributes<HTMLAnchorElement>
>({
  eventName: 'onClick',
  analyticName: 'Click',
})(Anchor);

const AnchorWithAnalytics = styled(NonStyledAnchorWithAnalytic)``;

export default AnchorWithAnalytics;
