import BugsnagClient from '../bugsnag/bugsnag-client';
import { LogLevel } from '../logger';

export default async (
  logLevel: LogLevel,
  message: string,
  error: Object = {},
  extraData: { [key: string]: any },
  options: BugsnagLoggerOptions = {}
) => {
  if (logLevel !== 'error' || options.skipBugsnagReport) {
    return;
  }

  const data = { ...extraData, message };
  if (error instanceof Error || error instanceof String) {
    BugsnagClient.getInstance().notify(error, {
      metaData: { extraData: data },
    });
  } else {
    BugsnagClient.getInstance().notify(message, {
      metaData: { extraData: data },
    });
  }
};

export type BugsnagLoggerOptions = {
  skipBugsnagReport?: boolean;
};
