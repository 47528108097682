import { Observable } from 'rxjs/Observable';
import { default as TimelineItem } from '../models/TimelineItem';

export const mergeExistingTimelineItems = (
  timelineItems: Observable<TimelineItem<any>>
) => {
  return timelineItems.buffer(timelineItems.debounceTime(50)).scan((
    existingMessages,
    newMessages
  ) => {
    const mergedMessages = [...existingMessages];
    for (const message of newMessages) {
      const existingMessageIndex = mergedMessages.findIndex(
        (x) => x.contentId === message.contentId
      );
      if (existingMessageIndex === -1) {
        mergedMessages.push(message);
      } else if (
        mergedMessages[existingMessageIndex].lastState !== 'deleted' ||
        mergedMessages[existingMessageIndex].isCanceled
      ) {
        mergedMessages.splice(existingMessageIndex, 1);
        mergedMessages.push(message);
      }
    }
    return mergedMessages.sort(
      (a: TimelineItem<any>, b: TimelineItem<any>) =>
        a.timestamp! - b.timestamp!
    );
  }, []);
};
