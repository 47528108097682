import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import {
  performanceTimeline,
  withRouter,
} from '@soluto-home-web/platform-utils';
import { UnregisterCallback } from 'history';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { compose, lifecycle } from 'recompose';

type InnerProps = {
  reportPagePerformance: boolean;
} & RouteComponentProps<any>;

export default compose(
  withRouter,
  withTweekKeys('soluto_home_web/metrics/report_page_performance', {
    propName: 'reportPagePerformance',
  }),
  lifecycle<InnerProps, { historyListener: UnregisterCallback }>({
    componentDidMount() {
      if (!this.props.reportPagePerformance || !window.performance) {
        return;
      }

      const unregisterHistoryChanges: UnregisterCallback = this.props.history.listen(
        (location, action) => {
          performanceTimeline.startMeasurement(location.pathname);
        }
      );
      this.setState({ historyListener: unregisterHistoryChanges });
    },
    componentWillUnmount() {
      if (!this.state.historyListener) {
        return;
      }
      const unregisterHistoryChanges: UnregisterCallback = this.state
        .historyListener;
      if (unregisterHistoryChanges) {
        unregisterHistoryChanges();
      }
    },
  })
)(Switch);
