import * as React from 'react';
import { enrichAnalytics, withAnalyticOnView } from 'react-shisell';
import { compose } from 'recompose';
import { PageProps } from './Page';

export type SectionProps = {
  sectionName: string;
  className?: string;
  mapPropsToExtras?: (props: any) => object;
};

const Section: React.SFC<
  SectionProps & React.HTMLAttributes<HTMLDivElement>
> = ({ children, className, ...props }) => (
  <div className={className} onClick={props.onClick}>
    {children}
  </div>
);

export default compose<{}, SectionProps>(
  enrichAnalytics<SectionProps & PageProps>((dispatcher, { sectionName }) =>
    dispatcher.withExtras({ Section: sectionName })
  ),
  withAnalyticOnView<SectionProps>({
    analyticName: 'SectionView',
    mapPropsToExtras: ({ mapPropsToExtras, ...props }) =>
      mapPropsToExtras ? mapPropsToExtras(props) : {},
  })
)(Section);
