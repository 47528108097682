const Errio = require('errio');
import { LogLevel } from '../logger';

function normalizeUrl(url: string) {
  try {
    return url[url.length - 1] === '/' ? url : `${url}/`;
  } catch {
    return url;
  }
}

class Logger {
  url: string;
  headers: {};
  context: { OsType?: string; appVersion?: string } = {};

  constructor(options: {
    url: string;
    headers: {};
    context: { OsType?: string; appVersion?: string };
  }) {
    this.url = options.url;
    this.headers = options.headers;
    this.context = options.context || {};
  }

  async log(
    logLevel: LogLevel,
    message: string,
    error: Object = {},
    extraData: { [key: string]: any },
    options?: { [key: string]: any }
  ) {
    if (error instanceof Error) {
      error = Errio.toObject(error);
    }

    if (typeof message !== 'string') {
      throw new Error(`Cannot log message of type ${typeof message}`);
    }

    const headers = {
      'Content-Type': 'application/json',
      'X-Api-Client': this.context.OsType,
      'X-Api-Client-Version': this.context.appVersion,
    };

    const data = {
      currentUrlPath: window.location.pathname,
      error,
      ...this.context,
      ...extraData,
    };
    const body = JSON.stringify({
      Data: data,
      Timestamp: new Date().toISOString(),
      ApplicationName: 'SolutoHomeWeb',
      Content: message,
    });

    const url = normalizeUrl(this.url);
    try {
      let response = await fetch(url + logLevel, {
        method: 'POST',
        headers: headers,
        body: body,
      } as any);
      if (response.status < 200 || response.status > 300) {
        console.warn('LoggingApi Response indicates failure', response.status);
      }
    } catch (err) {
      console.warn('Error sending log to LoggingApi', err.message);
    }
  }

  updateContext(update: any) {
    this.context = { ...this.context, ...update };
  }
}

const logger = new Logger({
  url: 'https://logging-api-new.mysoluto.com/api/v1/log/',
  headers: {},
  context: {},
});

export const updateContext = logger.updateContext.bind(logger);
export default (
  level: LogLevel,
  message: any,
  error: any,
  data?: any,
  options?: { [key: string]: any }
) => logger.log(level, message, error, data, options);
