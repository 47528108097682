let storeApi = require('store');

export function setStoreApi(_storeApi) {
  storeApi = _storeApi;
}

class Store {
  constructor(private scope: string) {}

  static forScope(scope: string): Store {
    const store = new Store(scope);
    store.scope = scope;

    return store;
  }

  private buildKey(key: string): string {
    return `${this.scope}_${key}`;
  }

  get(key: string): any {
    return storeApi.get(this.buildKey(key));
  }

  set(key: string, value: any): any {
    return storeApi.set(this.buildKey(key), value);
  }

  remove(key: string): any {
    storeApi.remove(this.buildKey(key));
  }

  clear(): any {
    const keys: string[] = [];
    storeApi.each((_, key) => {
      if (key.startsWith(`${this.scope}_`)) {
        keys.push(key);
      }
    });
    keys.forEach((k) => storeApi.remove(k));
  }
}

export default Store;
