import { ScrollFooterProps } from '@soluto-home-web/platform-common-ui';
import { styled } from '@soluto-home-web/web-theme';
import * as bowser from 'bowser';
import * as React from 'react';
import { compose, lifecycle, withState } from 'recompose';

type ScrollFooterOptions = {
  footerHeight?: number;
};

type HocProps = {
  scrollFooterOptions: ScrollFooterOptions;
  setScrollFooterOptions: (value: ScrollFooterOptions) => void;
};

export type ScrollFooterProps = {
  scrollToFooter: (footerHeight?: number) => void;
};

const DEFAULT_FOOTER_HEIGHT_IN_PX: number = 300;

const Footer = styled.div<{ height: number }>`
  height: ${(props) => `${props.height}px`};
`;

const withScrollToFooter = (
  Comp:
    | React.ComponentClass<Partial<ScrollFooterProps>>
    | React.StatelessComponent<Partial<ScrollFooterProps>>
) => (props: ScrollFooterOptions & HocProps) => {
  return (
    <>
      <Comp
        {...props}
        scrollToFooter={(footerHeight?: number) => {
          if (!bowser.ipad && !bowser.mobile) {
            return;
          }
          props.setScrollFooterOptions({
            footerHeight: footerHeight || DEFAULT_FOOTER_HEIGHT_IN_PX,
          });
        }}
      />
      <Footer height={props.scrollFooterOptions.footerHeight!} />
    </>
  );
};

export default compose<ScrollFooterProps, any>(
  withState('scrollFooterOptions', 'setScrollFooterOptions', {}),
  withScrollToFooter,
  lifecycle<HocProps, {}, {}>({
    componentDidUpdate() {
      if (!bowser.ipad && !bowser.mobile) {
        return;
      }
      window.scrollTo(0, document.body.scrollHeight);
    },
  })
);
