import Store from '../Store';
const store = Store.forScope('tokenManager');

export function getToken(name: string): string {
  return store.get(name);
}
export function setToken(name: string, token: string): void {
  store.set(name, token);
}
export function deleteToken(name: string): void {
  store.remove(name);
}

export function clear(): void {
  store.clear();
}
