import { withTweekKeys } from '@soluto-home-web/platform-tweek';
import { ThemeProps } from '@soluto-home-web/web-theme';
import { compose, mapProps } from 'recompose';
import { withTheme } from 'styled-components';

const NON_THEME_FONTS = 'Roboto, helvetica, -apple-system, arial, sans-serif';

export default compose<{ fontFamily: string }, {}>(
  withTheme,
  withTweekKeys('soluto_home_web/timeline/use_theme_font', {
    propName: 'useThemeFont',
  }),
  mapProps((props: { useThemeFont: boolean } & ThemeProps) => {
    return {
      fontFamily: !!props.useThemeFont
        ? `${props.theme.fonts.primary.font_family}, ${NON_THEME_FONTS}`
        : NON_THEME_FONTS,
      ...props,
    };
  })
);
