import React, { lazy, Suspense } from 'react';
import { compose, withPropsOnChange } from 'recompose';
import styled, { withTheme } from 'styled-components';
import getBrandedSpinner from './get-branded-spinner';

const LottieAnimationViewer = lazy(() =>
  import(/* webpackChunkName: "lottie-viewer" */ '@soluto-home-web/lottie-animation-viewer')
);

const getAnimationOptions = (brandColor: string) => ({
  animationData: getBrandedSpinner(brandColor),
  renderer: 'svg',
  loop: true,
  autoplay: true,
  rendererSettings: {
    scaleMode: 'noScale',
  },
});

const animationStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const AnimationOverlay = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
`;

const Uploading = () => <>Uploading...</>;

const UploadSpinnerAnimation = ({ animationOptions }) => (
  <AnimationOverlay>
    <Suspense fallback={Uploading}>
      <LottieAnimationViewer
        animationOptions={animationOptions}
        style={animationStyle}
        onAnimationLoaded={() => {}}
      />
    </Suspense>
  </AnimationOverlay>
);

const enhance = compose<any, any>(
  withTheme,
  withPropsOnChange(['theme'], ({ theme }) => ({
    animationOptions: getAnimationOptions(theme.colors.primary),
  }))
);

export default enhance(UploadSpinnerAnimation);
