import { withSessionId } from '@soluto-home-web/timeline-core';
import TimelineTheme from '@soluto-home-web/timeline-theme/TimelineTheme';
import { styled } from '@soluto-home-web/web-theme';
import * as React from 'react';
import { compose, pure } from 'recompose';
import { withTheme } from 'styled-components';
import withMediaUploadCancel from '../../../media-upload/withMediaUploadCancel';
import calculateTargetDimensions from './calculate-target-dimensions';
import UploadSpinnerAnimation from './Spinner';
import StopUploadIcon from './StopUploadIcon';

interface UploadImageItemOuterProps {
  contentType: string;
  imageUrl: string;
  isSelf: boolean;
  senderType: string;
  thumbnailUrl: string;
  imageDimensions: { width: number; height: number };
  sessionId: string;
  contentId: string;
  state: string;
  messageSelected: boolean;
  timelineId: string;
}

interface UploadImageItemEnhancedProps {
  cancelMediaUpload: () => void;
  onKeyUp: () => void;
  theme: TimelineTheme;
  fromExpert: boolean;
}

type UploadImageItemProps = UploadImageItemEnhancedProps &
  UploadImageItemOuterProps;

const Container = styled.div`
  margin: 5px 0;
  position: relative;
  box-shadow: 0 0 4px 0 #9b9b9b;
  border-radius: 5px;
`;

const MediaUploadOverlay = styled.div`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

const StopUploadContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ImageUploadProps = {
  borderColor: string;
  height: number;
  width: number;
};

const MediaUpload = styled.img<ImageUploadProps>`
  border-radius: 5px;
  border: solid 8px ${(props) => props.borderColor};
  box-sizing: border-box;
  margin-bottom: -3px;
  align-self: flex-end;
  background-color: #e6e6e6;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

class Media extends React.Component<UploadImageItemProps, any> {
  constructor(props) {
    super(props);
    this.state = {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  }

  updateDimensions() {
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }
  render() {
    const {
      imageDimensions: { width, height },
      contentId,
      cancelMediaUpload,
      theme,
      fromExpert,
      onKeyUp,
    } = this.props;
    const targetSize = calculateTargetDimensions(
      width,
      height,
      this.state.windowWidth,
      this.state.windowHeight
    );

    const data = localStorage.getItem(contentId);
    const borderColor = fromExpert ? '#f4f4f4' : '#dedede';

    return (
      <Container style={{ width: targetSize.width, height: targetSize.height }}>
        <MediaUploadOverlay>
          <UploadSpinnerAnimation />
          <StopUploadContainer>
            <div
              aria-label="Click to cancel upload"
              tabIndex={0}
              onClick={cancelMediaUpload}
              onKeyUp={onKeyUp}
              style={{ cursor: 'pointer', padding: '15px' }}
            >
              <StopUploadIcon color={theme.colors!.primary} />
            </div>
          </StopUploadContainer>
        </MediaUploadOverlay>
        {data && (
          <MediaUpload
            alt="Uploading media"
            src={data}
            width={targetSize.width}
            height={targetSize.height}
            borderColor={borderColor}
          />
        )}
      </Container>
    );
  }
}

const enhance = compose(
  withSessionId,
  withTheme,
  withMediaUploadCancel,
  pure
);

export default enhance(Media);
