import * as React from 'react';
import { withAnalyticOnEvent, WithAnalyticOnEventProps } from 'react-shisell';

export type ButtonWithAnalyticProps = React.ButtonHTMLAttributes<
  HTMLButtonElement
> &
  WithAnalyticOnEventProps<React.SyntheticEvent<any>>;

const withClickAnalyticOnButton = withAnalyticOnEvent<
  React.ButtonHTMLAttributes<HTMLButtonElement>
>({
  eventName: 'onClick',
  analyticName: 'Click',
});

export const ButtonWithClickAnalytic = (
  Button: React.ReactType<React.ButtonHTMLAttributes<HTMLButtonElement>>
): React.FunctionComponent<ButtonWithAnalyticProps> => {
  const ButtonWithClickAnalytic = withClickAnalyticOnButton(Button);

  return ({ children, className, ...other }) => (
    <ButtonWithClickAnalytic {...other} className={className}>
      {children}
    </ButtonWithClickAnalytic>
  );
};
