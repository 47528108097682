import BugsnagClient from './bugsnag-client';
import SensitiveDataRedactor from './SensitiveDataRedactor';

export type EnhancingData = { userData?: any; metaData?: any };
export type EnhanceErrorReports = (data: EnhancingData) => void;
export type InitializeBugsnag = () => void;

export function enhanceErrorReports(data: {
  userData?: any;
  metaData?: any;
}): void {
  const client = BugsnagClient.getInstance();
  Object.assign(client.user, data.userData);
  Object.assign(client.metaData, data.metaData);
}

export function initializeBugsnag(): void {
  const client = BugsnagClient.getInstance();
  client.config.beforeSend.push(SensitiveDataRedactor);
}
